import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import { schoolsIdx, privatesIdx } from '../content/tours/tours'
import { blurpsEn, blurpsIt, blurpsEs, blurpsDe, blurpsFr } from '../content/various/blurps'
import { buttonsEn, buttonsIt, buttonsEs, buttonsDe, buttonsFr } from '../content/various/buttons'
import { servicesEn, servicesIt, servicesEs, servicesDe, servicesFr } from '../content/services/services'
import { tourPageEn, tourPageIt, tourPageEs, tourPageDe, tourPageFr } from '../content/tour/tourPage'

import scolars from '../assets/angelaTour.png'
import turists from '../assets/CarmenUffizi.jpeg'

import schoolTour from '../assets/FOTO per il sito/Carmen con ragazzi/ragazzi.jpg'
import peopleTour from '../assets/group.jpg'

import kids from '../assets/kids.png'
import certifiedIcon from '../assets/certified-icon.png'
import idea from '../assets/idea.png'
import eye from '../assets/eye-icon.png'
import globe from '../assets/globe2.png'

import globeIcon from '../assets/globe.png'
import watchIcon from '../assets/watch.png'


const StyledServices = styled.section`
    background-color: #fffdf7;
    h2 {
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        padding-bottom: 30px;
        @media screen and (max-width: 650px) {
            font-size: 2.4rem;
        }
    }
    h3{
        text-align: center;
        font-size: 2.8rem;
    }
    p{
        font-size: 1.8rem;
        line-height: 1.6;
    }
    .contact-us{
        color: blue;
        text-decoration: underline;
        display: block;
        text-align: left;
        font-size: 1.8rem;
        padding-top: 5px;
    }
    .header{
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        padding: 40px 0;
        border-bottom: 1px solid #846A6A70;
        @media screen and (max-width: 650px) {
            text-align: left;
        }
        p{
            text-align: justify
        }
        .container{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .card{
                height: 300px;
                border: 1px solid;
                margin-top: 10px;
                width: 45%;
                font-size: 2.5rem;
                font-weight: bold;
                padding-top: 235px;
                box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.2);
                transition: .6s;
                :hover{
                    transform: scale(1.005);
                }
                @media screen and (max-width: 650px) {
                    width: 100%;
                    margin-bottom: 15px;
                }
                .button{
                    display: inline-block;
                    font-size: 2rem;
                    padding: 10px 35px 8px;
                    border: 1px solid;
                    border-radius: 8px;
                    background-color: white;
                    box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.6);
                    transition: .3s;
                    @media screen and (max-width: 1000px) {
                        font-size: 1.8rem;
                    }
                    @media screen and (max-width: 650px) {
                        font-size: 1.6rem;
                    }
                    :hover{
                        background-color: rgba(254, 254, 254, 0.4)
                    }
                }
            }
            .schools{
                    background-image: url(${scolars});
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
            }
            .privates{
                background-image: url(${turists});
                background-repeat: no-repeat;
                background-size: cover;
                background-position: bottom;
            }
        }
    }
    .category{
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
        padding: 40px 0;
        border-bottom: 1px solid #846A6A70;
        .container{
            display: flex;
            margin-bottom: 20px;
            @media screen and (max-width: 1100px) {
                flex-direction: column;
            }
            img{
                max-height: 400px;
                @media screen and (max-width: 1100px) {
                    margin-bottom: 20px;
                    order: 1;
                }
            }
            .content{
                p{
                    margin: 20px auto;
                    width: 80%;
                    @media screen and (max-width: 1100px) {
                        width: 100%;
                    }
                }
            }
            
        }
        .container-bottom{
            border-bottom: 1px solid #846A6A70;
            padding-bottom: 20px;
            h2{
                padding: 20px 0;
            }
            ul{
                padding: 20px 0;
                list-style-type: circle;
                list-style-position: inside;
                li{
                    font-size: 1.8rem;
                    padding: 5px;
                }
            }
            .schools-bottom{
                h3{
                    padding: 20px 0;
                }
            }
            .button{
                display: inline-block;
                left: 10%;
                text-align: center;
                width: 80%;
                margin: 20px 10%;
                padding: 15px;
                background-color: #FF9505;
                border: 1px solid;
                border-radius: 8px;
                font-size: 1.8rem;
                font-weight: bold;
                box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
                transition: .3s;
                :hover{
                    background-color: #FF9505A6
                }
            }
        }
        .blurps-top{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 40px 0;
            .blurp {
                display: flex;
                padding-right: 5px;
                width: 24%;
                @media screen and (max-width: 1000px) {
                    width: 48%;  
                    padding-bottom: 30px;
                }
                @media screen and (max-width: 650px) {
                    width: 100%;
                    padding-bottom: 30px;
                }
                img {
                    height: 50px;
                    padding-right: 8px;
                }
                div{
                    h3{
                        text-align: left;
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding-bottom: 5px;
                    }
                    p{
                        font-size: 1.2rem;
                        line-height: 1.5;
                    }
                }
            }
        }
        .tours{
            max-width: 1400px;
            margin: 20px auto 0;
            padding: 40px 0;
            .container{
                margin-top: 20px;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .card{
                    position: relative;
                    height: 800px;
                    width: 40%;
                    border: 1px solid #846A6A70;
                    margin: 10px 0;
                    background-color: white;
                    box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.2);
                    @media screen and (max-width: 1200px) {
                        margin: 10px auto;
                        width: 60%;
                    }
                    @media screen and (max-width: 650px) {
                        width: 100%;
                    }
                    h3{
                        text-align: center;
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding-top: 20px;
                    }
                    img{
                        height: 420px;
                        width: 100%;
                        transition: .6s;
                        :hover{
                            transform: scale(1.005);
                        }
                        /* @media screen and (max-width: 1000px) {
                            
                        } */
                        @media screen and (max-width: 650px) {
                            height: 220px;
                        }
                    }
                    p{
                        font-size: 1.4rem;
                        line-height: 1.4;
                        padding: 20px 20px 10px; 
                    }
                    .info{
                        position: absolute;
                        bottom: 75px;
                        div{
                            display: flex;
                            align-items: center;
                            padding-left: 20px;
                            img{
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                    .button{
                        position: absolute;
                        bottom: 0;
                        margin: 20px 35%;
                        text-align: center;
                        font-size: 1.4rem;
                        border: 1px solid;
                        border-radius: 8px;
                        background-color: #FF9505;
                        padding: 10px 20px;
                        box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
                        transition: .3s;
                        :hover{
                            background-color: #FF9505A6
                        }
                        /* @media screen and (max-width: 1000px) {
                            
                        } */
                        @media screen and (max-width: 650px) {
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
`

const Services = props => {
    const { match, tours } = props;
    const [blurps, setBlurps] = useState({})
    const [buttons, setButtons] = useState({})
    const [services, setServices] = useState({})
    const [tourContent, setTourContent] = useState({})
    useEffect(() => {
        switch (match.params.language) {
            case 'it':
                setBlurps(blurpsIt)
                setButtons(buttonsIt)
                setServices(servicesIt)
                setTourContent(tourPageIt)
                break
            case 'es':
                setBlurps(blurpsEs)
                setButtons(buttonsEs)
                setServices(servicesEs)
                setTourContent(tourPageEs)
                break
            case 'de':
                setBlurps(blurpsDe)
                setButtons(buttonsDe)
                setServices(servicesDe)
                setTourContent(tourPageDe)
                break
            case 'fr':
                setBlurps(blurpsFr)
                setButtons(buttonsFr)
                setServices(servicesFr)
                setTourContent(tourPageFr)
                break
            default:
                setBlurps(blurpsEn)
                setButtons(buttonsEn)
                setServices(servicesEn)
                setTourContent(tourPageEn)
                break
        }
    }, [match.params.language])

    return (
        <StyledServices>
            <Helmet>
                <title>{`AGMF - ${services.main_title}`}</title>
                <meta name="description" content="Abbiamo molta esperienza con gli studenti, giovani di tutte le età, le famiglie e con chiunque sia interessato a conoscere le bellezze di Firenze e dintorni in un modo interessante, piacevole e attraente, in compagnia di una guida madrelingua. Tutti nostri tour possono essere personalizzati e organizzati su misura, anche con altre destinazioni." />
            </Helmet>
            <div className='header'>
                <h2>{services.main_title}</h2>
                <p dangerouslySetInnerHTML={{ __html: services.main_description }} /><p />
                <Link className='contact-us' to={`/${match.params.language}/contacts`}>{services.main_contact_us}</Link>
                <div className='container'>
                    <HashLink smooth to={`/${match.params.language}/services#schools`} className='card schools'>
                        <div>
                            <div className='button'>{services.for_schools_button}</div>

                        </div>
                    </HashLink>
                    <HashLink smooth to={`/${match.params.language}/services#privates`} className='card privates'>
                        <div>
                            <div className='button'>{services.for_privates_button}</div>
                        </div>
                    </HashLink>
                </div>
            </div>

            <div className='category' id='schools'>
                <h2>{services.schools_title} </h2>
                <div className='container'>
                    <img src={schoolTour} alt="" />
                    <div className='content'>
                        <h3>{services.schools_subtitle_1}</h3>
                        <p dangerouslySetInnerHTML={{ __html: services.schools_text_1 }} /><p />
                    </div>
                </div>
                <div className='container-bottom'>
                    <p dangerouslySetInnerHTML={{ __html: services.schools_text_2 }} /><p />
                    <ul>
                        <li>{services.schools_list_1}</li>
                        <li>{services.schools_list_2}</li>
                        <li>{services.schools_list_3}</li>
                        <li>{services.schools_list_4}</li>
                        <li>{services.schools_list_5}</li>
                    </ul>
                    <p>{services.schools_text_3} <br /><br />
                        <Link className='contact-us' to={`/${match.params.language}/contacts`}>{services.schools_contact_us}</Link></p>
                    <div className='schools-bottom'>
                        <h3>{services.schools_subtitle_2}</h3>
                        <p>{services.schools_text_4}</p>
                        <ul>
                            <li>{services.schools_list_6}</li>
                            <li>{services.schools_list_7}</li>
                        </ul>
                        <p>{services.schools_text_5}</p>
                        <p>{services.schools_text_6}</p>
                    </div>
                    <div className='blurps-top'>
                        <div className='blurp'>
                            <img src={certifiedIcon} alt="" />
                            <div>
                                <h3>{blurps.certified_title}</h3>
                                <p>{blurps.certified_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={kids} alt="" />
                            <div>
                                <h3>{blurps.for_kids_title}</h3>
                                <p>{blurps.for_kids_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={idea} alt="" />
                            <div>
                                <h3>{blurps.intriguing_title}</h3>
                                <p>{blurps.intriguing_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={eye} alt="" />
                            <div>
                                <h3>{blurps.visit_florence_title}</h3>
                                <p>{blurps.visit_florence_description}</p>
                            </div>
                        </div>
                    </div>

                    <Link to={`/${match.params.language}/contacts`} className='button'>{buttons.book_now}</Link>
                </div>
                <div className='tours'>
                    <h3>{services.schools_tours_title}</h3>
                    <div className='container'>
                        {tours.length > 0 && schoolsIdx.map(idx => (
                            <Link to={`/${match.params.language}/${tours[idx].slug}`} className='card'>
                                <img src={tours[idx].cardImg} alt="" />
                                <h3>{tours[idx].name}</h3>
                                <div dangerouslySetInnerHTML={{ __html: tours[idx].card }} />
                                <div className='info'>
                                    <div>
                                        <img src={globeIcon} alt="" />
                                        <p>{tourContent.languages} {tours[idx].languages}</p>
                                    </div>
                                    <div>
                                        <img src={watchIcon} alt="" />
                                        <p>{tourContent.duration} {tours[idx].duration}</p>
                                    </div>
                                </div>
                                <div className='button'>{buttons.discover_more}</div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>

            <div className='category' id='privates'>
                <h2>{services.privates_title}</h2>
                <div className='container'>
                    <img src={peopleTour} alt="" />
                    <div className='content'>
                        <h3>{services.privates_subtitle}</h3>
                        <p dangerouslySetInnerHTML={{ __html: services.privates_text_1 }} />
                    </div>
                </div>
                <div className='container-bottom'>
                    <p dangerouslySetInnerHTML={{ __html: services.privates_text_2 }} />
                    <Link className='contact-us' to={`/${match.params.language}/contacts`}>{services.privates_contact_us}</Link>
                    <ul>
                        <li>{services.privates_list_1}</li>
                        <li>{services.privates_list_2}</li>
                        <li>{services.privates_list_3}</li>
                        <li>{services.privates_list_4}</li>
                    </ul>
                    <p dangerouslySetInnerHTML={{ __html: services.privates_text_3 }} />

                    <div className='blurps-top'>
                        <div className='blurp'>
                            <img src={certifiedIcon} alt="" />
                            <div>
                                <h3>{blurps.certified_title}</h3>
                                <p>{blurps.certified_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={globe} alt="" />
                            <div>
                                <h3>{blurps.for_you_title}</h3>
                                <p>{blurps.for_you_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={idea} alt="" />
                            <div>
                                <h3>{blurps.intriguing_title}</h3>
                                <p>{blurps.intriguing_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={eye} alt="" />
                            <div>
                                <h3>{blurps.visit_florence_title}</h3>
                                <p>{blurps.visit_florence_description}</p>
                            </div>
                        </div>
                    </div>

                    <Link to={`/${match.params.language}/contacts`} className='button'>{buttons.book_now}</Link>
                </div>
                <div className='tours'>
                    <h3>{services.privates_tours_title}</h3>
                    <div className='container'>
                        {tours.length > 0 && privatesIdx.map(idx => (
                            <Link to={`/${match.params.language}/${tours[idx].slug}`} className='card'>
                                <img src={tours[idx].cardImg} alt="" />
                                <h3>{tours[idx].name}</h3>
                                <div dangerouslySetInnerHTML={{ __html: tours[idx].card }} />
                                <div className='info'>
                                    <div>
                                        <img src={globeIcon} alt="" />
                                        <p>{tourContent.languages} {tours[idx].languages}</p>
                                    </div>
                                    <div>
                                        <img src={watchIcon} alt="" />
                                        <p>{tourContent.duration} {tours[idx].duration}</p>
                                    </div>
                                </div>
                                <div className='button'>{buttons.discover_more}</div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </StyledServices>
    )
}

export default Services;