export const buttonsEn = {
    discover_more: 'Discover More',
    book_now: 'BOOK NOW'
}

export const buttonsIt = {
    discover_more: 'Scopri di più',
    book_now: 'PRENOTA ORA'
}

export const buttonsEs = {
    discover_more: 'Saber mas',
    book_now: 'RESERVA AHORA'
}

export const buttonsDe = {
    discover_more: 'Entdecken Sie mehr',
    book_now: 'BUCHEN SIE JETZT'
}

export const buttonsFr = {
    discover_more: 'Pour en savoir plus',
    book_now: 'POUR RĖSERVER'
}