import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import { homePageIdx } from '../content/tours/tours'
import { homepageEn, homepageIt, homepageEs, homepageDe, homepageFr } from '../content/homepage/homepage'
import { blurpsEn, blurpsIt, blurpsEs, blurpsDe, blurpsFr } from '../content/various/blurps'
import { buttonsEn, buttonsIt, buttonsEs, buttonsDe, buttonsFr } from '../content/various/buttons'
import { tourPageEn, tourPageIt, tourPageEs, tourPageDe, tourPageFr } from '../content/tour/tourPage'
import { servicesEn, servicesIt, servicesEs, servicesDe, servicesFr } from '../content/services/services'

import florence from '../assets/florence.jpg'

import certifiedIcon from '../assets/certified-icon.png'
import idea from '../assets/idea.png'
import eye from '../assets/eye-icon.png'
import globe from '../assets/globe2.png'

import scolars from '../assets/angelaTour.png'
import turists from '../assets/CarmenUffizi.jpeg'

import david from '../assets/david.jpg'
import perseus from '../assets/perseo.jpg'

import ponteVecchio from '../assets/ponte-vecchio.jpg'

import globeIcon from '../assets/globe.png'
import watchIcon from '../assets/watch.png'

const StyledHomePage = styled.section`
    background-color: #fffdf7;
    .header{
        background-image: url(${florence});
        height: 500px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;
        @media screen and (max-width: 1000px) {
           background-position: right;
        }
        @media screen and (max-width: 650px) {
            height: 400px;
        }
        h1{
            font-size: 4rem;
            font-weight: bold;
            color: black;
            padding: 120px 10px 0;
            @media screen and (max-width: 1000px) {
                font-size: 3rem;
            }
            @media screen and (max-width: 650px) {
                padding-top: 65px;
                font-size: 2.6rem;
            }
        }
        p{
            font-size: 2rem;
            margin-top: 40px;
            color: black;
            padding: 0 25px;
            @media screen and (max-width: 1000px) {
                font-size: 1.8rem;
            }
            @media screen and (max-width: 650px) {
                font-size: 1.6rem;
            }
        }
        a{
            display: inline-block;
            font-size: 2rem;
            padding: 10px 35px 8px;
            border: 1px solid;
            border-radius: 8px;
            background-color: white;
            margin-top: 60px;
            box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.8);
            transition: .3s;
            :hover{
                background-color: rgba(254, 254, 254, 0.22)
            }
            @media screen and (max-width: 1000px) {
                font-size: 1.8rem;
            }
            @media screen and (max-width: 650px) {
                font-size: 1.6rem;
            }
        }
    }
    .main{
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
        h2 {
            text-align: center;
            font-size: 3rem;
            padding-bottom: 30px;
            @media screen and (max-width: 650px) {
                font-size: 2.4rem;
            }
        }
        .blurps-top{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 40px 0;
            border-bottom: 1px solid #846A6A70;
            @media screen and (max-width: 650px) {
                padding-bottom: 10px;
            }
            .blurp {
                display: flex;
                padding-right: 5px;
                width: 24%;
                @media screen and (max-width: 1000px) {
                    width: 48%;  
                    padding-bottom: 30px;
                }
                @media screen and (max-width: 650px) {
                    width: 100%;
                }
                img {
                    height: 50px;
                    padding-right: 8px;
                }
                div{
                    h3{
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding-bottom: 5px;
                    }
                    p{
                        font-size: 1.2rem;
                        line-height: 1.5;
                    }
                }
            }
        }
        .services{
            text-align: center;
            padding: 40px 0;
            border-bottom: 1px solid #846A6A70;
            .container{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .card{
                    height: 300px;
                    border: 1px solid;
                    width: 45%;
                    font-size: 2.5rem;
                    font-weight: bold;
                    padding-top: 235px;
                    box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.2);
                    transition: .6s;
                    :hover{
                        transform: scale(1.005);
                    }
                    @media screen and (max-width: 650px) {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    .button{
                        display: inline-block;
                        font-size: 2rem;
                        padding: 10px 35px 8px;
                        border: 1px solid;
                        border-radius: 8px;
                        background-color: white;
                        box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.6);
                        transition: .3s;
                        @media screen and (max-width: 1000px) {
                            font-size: 1.8rem;
                        }
                        @media screen and (max-width: 650px) {
                            font-size: 1.6rem;
                        }
                        :hover{
                            background-color: rgba(254, 254, 254, 0.4)
                        }
                    }
                }
                .schools{
                        background-image: url(${scolars});
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                }
                .privates{
                    background-image: url(${turists});
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: bottom;
                }
            }
        }
        .content{
            display: flex;
            justify-content: space-between;
            padding: 40px 0;
            border-bottom: 1px solid #846A6A70;
            .left{
                width: 40%;
                @media screen and (max-width: 1240px) {
                    width: 60%;
                }
                @media screen and (max-width: 900px) {
                    width: 100%;
                }
                p{
                    font-size: 1.6rem;
                    line-height: 1.6;
                    padding-right: 10px;
                }
                .p-style{
                    padding-top: 20px;
                }
                div{
                    display: flex;
                    padding: 20px 0;
                    img{
                        height: 30px;
                        padding-right: 10px;
                    }
                }
            }
            .right{
                img{
                    height: 500px;
                    margin-left: 5px;
                    @media screen and (max-width: 900px) {
                        display: none;
                    }
                }
                .david{
                    @media screen and (max-width: 1240px) {
                        display: none;
                    }
                }
            }
        }
        .partners{
            padding: 40px 0;
            border-bottom: 1px solid #846A6A70;
            .flex-container{
                display: flex;
                justify-content: space-evenly;
                width: 80%;
                margin: 0 auto;
                flex-wrap: wrap;
                .img-container{
                    width: 25%;
                    padding: 30px 0;
                    @media screen and (max-width: 1000px) {
                        width: 48%;    
                    }
                    @media screen and (max-width: 650px) {
                        width: 100%;
                    }
                    img {
                        display: block;
                        margin: 0 auto;
                        height: 100px;
                    }
                }
            }
        }

        .contacts{
            display: flex;
            justify-content: space-between;
            padding: 40px 0;
            border-bottom: 1px solid #846A6A70;
            @media screen and (max-width: 1100px) {
                flex-direction: column;
            }
            .left{
                img{
                    height: 300px;
                    margin-bottom: 20px;
                    @media screen and (max-width: 1100px) {
                        margin-bottom: 30px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        width: 100%;
                        height: 350px;
                    }
                    @media screen and (max-width: 900px) {
                        height: 250px   
                        
                    }
                    @media screen and (max-width: 700px) {
                        height: 200px   
                        
                    }
                }
            }
            .right{
                width: 80%;
                position: relative;
                @media screen and (max-width: 1100px) {
                    width: 100%;
                }
                p{
                    font-size: 1.6rem;
                    line-height: 1.5;
                    padding: 20px 10%;
                    @media screen and (max-width: 1100px) {
                        text-align: center;
                        padding:  0 0 20px;
                    }
                }
                .submit{
                    position: absolute;
                    bottom: 0;
                    left: 10%;
                    text-align: center;
                    width: 80%;
                    margin: 20px auto;
                    padding: 15px;
                    background-color: #FF9505;
                    border: 1px solid;
                    border-radius: 8px;
                    font-size: 1.8rem;
                    font-weight: bold;
                    box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
                    transition: .3s;
                    :hover{
                        background-color: #FF9505A6
                    }
                    @media screen and (max-width: 1100px) {
                        position: relative;
                        display: block;
                        left: 0;
                    }
                }
               
            }
        }
        .tours{
            padding: 40px 0;
            border-bottom: 1px solid #846A6A70;
            .container{
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .card{
                    position: relative;
                    height: 800px;
                    width: 40%;
                    border: 1px solid #846A6A70;
                    margin: 10px 0;
                    background-color: white;
                    box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.2);
                    @media screen and (max-width: 1200px) {
                        margin: 10px auto;
                        width: 60%;
                    }
                    @media screen and (max-width: 650px) {
                        width: 100%;
                    }
                    h3{
                        text-align: center;
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding-top: 20px;
                    }
                    img{
                        height: 420px;
                        width: 100%;
                        transition: .6s;
                        :hover{
                            transform: scale(1.005);
                        }
                        /* @media screen and (max-width: 1000px) {
                            
                        } */
                        @media screen and (max-width: 650px) {
                            height: 220px;
                        }
                    }
                    p{
                        font-size: 1.4rem;
                        line-height: 1.4;
                        padding: 20px 20px 10px; 
                    }
                    .info{
                        position: absolute;
                        bottom: 75px;
                        div{
                            display: flex;
                            align-items: center;
                            padding-left: 20px;
                            img{
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                    .button{
                        position: absolute;
                        bottom: 0;
                        margin: 20px 35%;
                        text-align: center;
                        font-size: 1.4rem;
                        border: 1px solid;
                        border-radius: 8px;
                        background-color: #FF9505;
                        padding: 10px 20px;
                        box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
                        transition: .3s;
                        :hover{
                            background-color: #FF9505A6
                        }
                        /* @media screen and (max-width: 1000px) {
                            
                        } */
                        @media screen and (max-width: 650px) {
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
    .bot-button{
        display: inline-block;
        text-align: center;
        width: 100%;
        margin: 20px auto;
        padding: 15px;
        background-color: #FF9505;
        border: 1px solid;
        border-radius: 8px;
        font-size: 1.8rem;
        font-weight: bold;
        box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
        transition: .3s;
        :hover{
            background-color: #FF9505A6
        }
    }
`

const HomePage = props => {
    const { match, tours } = props;
    const [content, setContent] = useState({})
    const [blurps, setBlurps] = useState({})
    const [buttons, setButtons] = useState({})
    const [tourContent, setTourContent] = useState({})
    const [services, setServices] = useState({})

    useEffect(() => {
        switch (match.params.language) {
            case 'it':
                setContent(homepageIt)
                setBlurps(blurpsIt)
                setButtons(buttonsIt)
                setTourContent(tourPageIt)
                setServices(servicesIt)
                break
            case 'es':
                setContent(homepageEs)
                setBlurps(blurpsEs)
                setButtons(buttonsEs)
                setTourContent(tourPageEs)
                setServices(servicesEs)
                break
            case 'de':
                setContent(homepageDe)
                setBlurps(blurpsDe)
                setButtons(buttonsDe)
                setTourContent(tourPageDe)
                setServices(servicesDe)
                break
            case 'fr':
                setContent(homepageFr)
                setBlurps(blurpsFr)
                setButtons(buttonsFr)
                setTourContent(tourPageFr)
                setServices(servicesFr)
                break
            default:
                setContent(homepageEn)
                setBlurps(blurpsEn)
                setButtons(buttonsEn)
                setTourContent(tourPageEn)
                setServices(servicesEn)
                break
        }
    }, [match.params.language])

    return (
        <StyledHomePage>
            <Helmet>
                <title>{content.header_title}</title>
                <meta name="description" content={content.header_description} />
            </Helmet>
            <header>
                <div className='header'>
                    <h1>{content.header_title}</h1>
                    <p>{content.header_description}</p>

                    <Link to={`/${match.params.language}/services`}>{buttons.discover_more}</Link>
                </div>
            </header>
            <section className='main'>

                <div className='content'>
                    <div className='left'>
                        <h2>{content.content_title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: content.content_description }} />

                        <p className='p-style'><strong>{content.content_description2}</strong></p>
                    </div>
                    <div className='right'>
                        <img src={perseus} alt="" />
                        <img className='david' src={david} alt="" />
                    </div>
                </div>

                <div className='contacts'>
                    <div className='left'>
                        <img src={ponteVecchio} alt="" />
                    </div>
                    <div className='right'>
                        <h2>{content.contacts_title}</h2>
                        <p>{content.contacts_description}</p>
                        <Link to={`/${match.params.language}/contacts`} className='submit'>{buttons.book_now}</Link>
                    </div>
                </div>

                <div className='blurps-top'>
                    <div className='blurp'>
                        <img src={certifiedIcon} alt="" />
                        <div>
                            <h3>{blurps.certified_title}</h3>
                            <p>{blurps.certified_description}</p>
                        </div>
                    </div>
                    <div className='blurp'>
                        <img src={globe} alt="" />
                        <div>
                            <h3>{blurps.for_you_title}</h3>
                            <p>{blurps.for_you_description}</p>
                        </div>
                    </div>
                    <div className='blurp'>
                        <img src={idea} alt="" />
                        <div>
                            <h3>{blurps.intriguing_title}</h3>
                            <p>{blurps.intriguing_description}</p>
                        </div>
                    </div>
                    <div className='blurp'>
                        <img src={eye} alt="" />
                        <div>
                            <h3>{blurps.visit_florence_title}</h3>
                            <p>{blurps.visit_florence_description}</p>
                        </div>
                    </div>
                </div>

                <div className='services'>
                    <h2>{content.services_title}</h2>
                    <div className='container'>
                        <HashLink smooth to='/services#schools' className='card schools'>
                            <div className='child'></div>
                            <div>
                                <HashLink smooth to={`/${match.params.language}/services#schools`} className='button'>{services.for_schools_button}</HashLink>
                            </div>
                        </HashLink>
                        <HashLink smooth to='/services#privates' className='card privates'>
                            <div>
                                <HashLink smooth to={`/${match.params.language}/services#privates`} className='button'>{services.for_privates_button}</HashLink>
                            </div>
                        </HashLink>
                    </div>
                </div>



                <div className='tours'>
                    <h2>{content.tours_title}</h2>
                    <div className='container'>
                        {tours.length > 0 && homePageIdx.map(idx => (
                            <Link to={`/${match.params.language}/${tours[idx].slug}`} className='card'>
                                <img src={tours[idx].cardImg} alt="" />
                                <h3>{tours[idx].name}</h3>
                                <div dangerouslySetInnerHTML={{ __html: tours[idx].card }} />
                                <div className='info'>
                                    <div>
                                        <img src={globeIcon} alt="" />
                                        <p>{tourContent.languages} {tours[idx].languages}</p>
                                    </div>
                                    <div>
                                        <img src={watchIcon} alt="" />
                                        <p>{tourContent.duration} {tours[idx].duration}</p>
                                    </div>
                                </div>
                                <div className='button'>{buttons.discover_more}</div>
                            </Link>
                        ))}
                    </div>
                </div>

                <Link to={`/${match.params.language}/contacts`} className='bot-button'>{buttons.book_now}</Link>
            </section>
        </StyledHomePage>
    )
}

export default HomePage;