export const navbarEn = {
    services: 'SERVICES',
    all_services: 'ALL TOURS',
    for_schools: 'SCHOOL GROUP',
    for_privates: 'PRIVATE TOUR',
    tours: 'TOURS',
    guides: 'GUIDES',
}

export const navbarIt = {
    services: 'SERVIZI',
    all_services: 'TUTTI I SERVIZI',
    for_schools: 'PER SCUOLE',
    for_privates: 'PER PRIVATI',
    tours: 'TOURS',
    guides: 'GUIDE',
}

export const navbarEs = {
    services: 'SERVICIOS',
    all_services: 'TODOS LOS SERVICIOS',
    for_schools: 'COLEGIOS',
    for_privates: 'VISITAS PRIVADAS',
    tours: 'TOURS',
    guides: 'GUIAS',
}

export const navbarDe = {
    services: 'SERVICELEISTUNGEN',
    all_services: 'SERVICELEISTUNGEN',
    for_schools: 'FÜR SCHULEN',
    for_privates: 'PRIVATREISENDE',
    tours: 'TOURS',
    guides: 'STADTFÜHRER',
}

export const navbarFr = {
    services: 'SERVICES',
    all_services: 'TOUTES SERVICES',
    for_schools: 'POUR LES ÉCOLES',
    for_privates: 'TOURS PRIVĖS',
    tours: 'VISITES',
    guides: 'GUIDES',
}