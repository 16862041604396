export const servicesEn = {
    main_title: 'The best tours for you',
    main_description: 'We have extensive experience in working with students, young people of all ages, with families as well as with anyone interested in learning more about the beauty of Florence and its outskirts in an interesting, entertaining and attractive way.  Each of our tours can be personalized or custom fit, regardless of its content.  We are ready to arrange other tour that the ones we offer. ',
    main_contact_us: 'For further information, please contact us directly here',
    for_schools_button: 'School group',
    for_privates_button: 'Private tour',
    schools_title: 'Tours for schools and students of all ages',
    schools_subtitle_1: 'Our school project—You Be The Tourists (in your hometown)!',
    schools_text_1: 'For the 2021/2022 school year, our Association, Guide Madrelingua di Firenze (AGMF) is offering a new educational project—I Turisti Siete Voi!—You Be The Tourists (in your hometown)!—which aims to meet the demand for more communicative interaction between students and native English speakers.  In this project our guide will collaborate with teachers in preparing and adapting our tour to the specific school programs and language level of each class.',
    schools_text_2: '<strong>"You Be The Tourists (in your hometown)!"</strong> offers guided tours of Florence, in a foreign language the students are studying, by a native language speaker as a form of out-of-the-classroom, interactive and interdisciplinary educational exercise with an ambitious aim:  to stimulate language abilities, enrich the students’ historical, artistic and cultural understanding, energize school outings and favor the socialization of students. <br /><br />A school may choose both the number of tours to take in an academic year, and the language spoken in each of our proposed tours:',
    schools_list_1: 'Curious Florentine Facts (and Legends)', 
    schools_list_2: 'A classic tour of Florence', 
    schools_list_3: 'A panoramic view of Florence', 
    schools_list_4: 'Sites of power in Florence', 
    schools_list_5: 'Florence and Science (at the Galileo Museum)',
    schools_text_3: 'Each tour will last at most three hours and cost €8 per student (not including the cost of whisperers or tickets to the Galileo Museum).',
    schools_contact_us: 'For other tour requests, please contact us directly here',
    schools_subtitle_2: 'School groups—we speak your language naturally.',
    schools_text_4: 'AGMF offers guided tours in each of our native languages to school groups visiting Florence.  Our native-speaker guide will take you around in an entertaining and instructive manner.',
    schools_list_6: 'Our rates: half day (max 3 hrs) €130', 
    schools_list_7: 'whole day (max. 6 hrs) €260', 
    schools_text_5: 'Entry fees and whisperers are not included.  The maximum size of a group is subject to the rules established for the date of the tour.',
    schools_text_6: 'AGMF has a native-speaker guide to match your every tour guiding request.',
    schools_tours_title: 'Most populars tours for schools',
    privates_title: 'Private tours',
    privates_subtitle: 'Why choose our tours?',
    privates_text_1: 'When working in the name of our Association, we work exclusively in our native language so as to guarantee fully comprehensible, top quality presentations to our clients.  Our is not merely a linguistically distinctive approach, we feel we are also better able to relate our tours to our clients as only a native speaker can do, highlighting historical and cultural aspects and drawing comparisons or contrasts which resonate more with people whose language and background we share.',
    privates_text_2: 'We stress that each of our guides has lived in Florence for many years.  We are Florentine by adoption.  We know and love our city every bit as much as native Florentines do (maybe even more so).<br /><br />We have extensive experience in working with students, young people of all ages, families as well as with anyone interested in learning more about the beauty of Florence.  We strive to make our tours in your language interesting , attractive, and entertaining.  Each of our tours can be personalized and custom fit, as can the tour’s destinations and contents.',
    privates_contact_us: 'For further information, please contact us directly here',
    privates_list_1: 'Our rates: half day (max 3 hrs) €160', 
    privates_list_2: 'whole day (max. 6 hrs) €320', 
    privates_list_3: '€55 more for every additional hour', 
    privates_list_4: 'Entry fees and whisperers are not included.',
    privates_text_3: 'GMF has a native-speaker guide to match your every tour guiding request.',
    privates_tours_title: 'Our proposals',
}

export const servicesIt = {
    main_title: 'I migliori tour per soddisfare le tue esigenze',
    main_description: '<strong>Visite guidate e tour a Firenze e provincia con guide e accompagnatori turistici madrelingua abilitati.</strong><br />Abbiamo molta esperienza con gli studenti, giovani di tutte le età, le famiglie e con chiunque sia interessato a conoscere le bellezze di Firenze e dintorni in un modo interessante, piacevole e attraente, in compagnia di una guida madrelingua. Tutti nostri tour possono essere personalizzati e organizzati su misura, anche con altre destinazioni.',
    main_contact_us: 'Contattateci per ulteriori informazioni',
    for_schools_button: 'Per Scuole',
    for_privates_button: 'Per Privati',
    schools_title: 'Tour Per Scuole e studenti di tutte le età!',
    schools_subtitle_1: 'Progetto scuola – I Turisti Siete Voi!',
    schools_text_1: '<strong>Visite guidate a Firenze con guida madrelingua in inglese, francese, spagnolo e/o tedesco, per studenti delle scuole secondarie di I° e II° grado.</strong><br /><br />Con l’anno scolastico 2021/2022 la nostra Associazione Guide Madrelingua di Firenze (AGMF) propone un nuovo progetto di offerta formativa, I Turisti Siete Voi, che risponde al bisogno di maggior dialogo fra gli studenti e persone di madrelingua. Il progetto prevede la collaborazione con gli insegnanti per preparare e adattare la visita al programma didattico ed al livello linguistico di ogni classe.',
    schools_text_2: '<strong>"I Turisti Siete Voi"</strong> è un progetto di visite guidate a Firenze, in lingua straniera, inteso come offerta formativa, interattiva ed interdisciplinare, fuori dall’aula, dall’obbiettivo ambizioso: <strong>stimolare</strong> le capacità linguistiche, <strong>arricchire</strong> il bagaglio storico- artistico e culturale, <strong>potenziare</strong> le uscite scolastiche e <strong>favorire</strong> la socializzazione tra gli alunni. <br /><br />La scuola potrà scegliere sia il numero di visite a cui partecipare nell’arco dell’anno accademico, sia la lingua da utilizzare durante ogni una delle nostre proposte:',
    schools_list_1: 'Curiosità fiorentine', 
    schools_list_2: 'Firenze classica', 
    schools_list_3: 'Firenze panoramica', 
    schools_list_4: 'I Palazzi del Potere a Firenze', 
    schools_list_5: 'Firenze e Scienza',
    schools_text_3: 'Le uscite didattiche avranno una durata massima di 3 ore Costo: 8 euro a studente (auricolari e biglietti museo Galileo a parte).',
    schools_contact_us: 'Contattateci per visite diverse da quelle proposte o maggiori informazioni.',
    schools_subtitle_2: 'Scolaresche - La vostra lingua è la nostra!',
    schools_text_4: 'AGMF propone tour guidati in tutte le lingue per le scolaresche in visita a Firenze. Sarete accompagnati da una nostra guida madrelingua dal metodo divertente e dinamico.',
    schools_list_6: 'Tariffa: mezza giornata (max 3 h) 130 euro', 
    schools_list_7: 'Giornata intera (max 6 h) 260 euro', 
    schools_text_5: 'Biglietti e auricolari esclusi. Numero massimo di partecipanti per gruppo soggetto alla normativa vigente al momento.',
    schools_text_6: 'AGMF troverà la guida madrelingua perfetta per ogni richiesta.',
    schools_tours_title: 'I tour più popolari per le scuole',
    privates_title: 'Tour Per Privati',
    privates_subtitle: 'Perchè scegliere i nostri tour?',
    privates_text_1: 'Lavoriamo in nome della nostra associazione esclusivamente nelle nostre lingue madre rispettive, in questo modo garantiamo ai nostri clienti una presentazione al cento per cento comprensibile e di qualità. Ma non si tratta soltanto di un fatto linguistico, questa nostra caratteristica offre la possibilità di mettere i fatti riportati in relazione al paese di origine dei turisti, pensiamo che solo una madrelingua sa cosa sia più interessante, diverso o uguale per loro, e di conseguenza sa fare una scelta degli aspetti storici e culturali da raccontare, più adatta alla persona o gruppo di persone che ha davanti.',
    privates_text_2: 'Vogliamo sottolineare che tutte le nostre guide vivono a Firenze da molti anni, sono fiorentine di adozione, la conoscono e la amano tanto quanto un fiorentino vero (forse di più). <br /><br />Abbiamo molta esperienza con gli studenti, giovani di tutte le età, famiglie e con chiunque sia interessato a conoscere le bellezze di Firenze e dintorni in un modo interessante, piacevole e attraente, in compagnia di una guida madrelingua. Tutti nostri tour possono essere personalizzati e organizzati su misura, anche con altre destinazioni.',
    privates_contact_us: 'Contattateci per ulteriori informazioni',
    privates_list_1: 'Tariffa: 160 euro mezza giornata (3 h)', 
    privates_list_2: '320 euro giornata intera (6 h)', 
    privates_list_3: '55 euro per ogni eventuale ora in più', 
    privates_list_4: 'Auricolari e biglietti d’ingresso a carico del cliente',
    privates_text_3: 'AGMF troverà la guida madrelingua perfetta per ogni richiesta.',
    privates_tours_title: 'Le nostre proposte',
}

export const servicesEs = {
    main_title: 'El mejor tour para satisfacer tus necesidades',
    main_description: '<strong>Tours y visitas guiadas en Florencia y provincia con guías y acompañantes turísticos Madrelingua autorizados.</strong><br />Tenemos gran experiencia en visitas con estudiantes, jóvenes de todas las edades y con todas aquellas personas que estén interesadas en conocer las bellezas de Florencia y sus alrededores, de una manera agradable y atractiva, en compañía de un guía en lengua materna. Todos nuestros tours se pueden organizar a medida y de manera personalizada, pudiendo elegir otros destinos.',
    main_contact_us: 'Para mayor información: contacto',
    for_schools_button: 'Colegios',
    for_privates_button: 'Visitas Privadas',
    schools_title: 'Visitas para colegios y estudiantes de todas las edades',
    schools_subtitle_1: 'Proyecto escolar - ¡Los turistas sois vosotros!',
    schools_text_1: '<strong>Visitas guiadas en Florencia con guía Madrelingua en ingles, francés, español o alemán, para estudiantes de colegios de enseñanza secundaria de grado I° e II°</strong><br /><br />Para el curso escolar 2021/2022 nuestra Asociación Guías Madrelingua de Florencia (AGMF) propone un nuevo proyecto de oferta formativa, "Los turistas sois vosotros", que responde a la necesidad de mayor dialogo entre estudiantes y hablantes nativos. Este proyecto contempla la colaboración con los profesores para preparar y adaptar la visita al programa didáctico y nivel lingüístico de cada clase.',
    schools_text_2: '<strong>"Los turistas sois vosotros"</strong> es un proyecto de visitas guiadas en Florencia, en lengua extranjera, pensado como oferta formativa, interactiva e interdisciplinar en el idioma elegido,  con un objetivo ambicioso: estimular la capacidad lingüística, enriquecer el bagaje histórico -  artistico y cultural, y favorecer la socialización entre los alumnos.<br /><br /> El colegio podrá elegir tanto el  número de visitas a realizar como el idioma empleado en cualquiera de las distintas opciones:',
    schools_list_1: 'Curiosidades florentinas', 
    schools_list_2: 'Florencia clásica', 
    schools_list_3: 'Florencia panorámica', 
    schools_list_4: 'Palacios del Poder en Florencia', 
    schools_list_5: 'Florencia y la Ciencia',
    schools_text_3: 'Las visitas didácticas duraran un máximo de 3 horas. Tarifa: 8€ por estudiante (auriculares y entrada museo Galileo aparte)',
    schools_contact_us: 'Nos adaptamos a cualquier necesidad por lo que si necesitáis un programa diferente, no dudéis en poneros en contacto con nosotros.',
    schools_subtitle_2: 'Viajes de estudio - ¡Vuestro idioma es el nuestro!',
    schools_text_4: 'AGMF propone tours guiados en todos los idiomas para grupos de estudiantes de visita en Florencia. Un guía Madrelingua, utilizando un método divertido y dinámico, os acompañará en vuestra visita.',
    schools_list_6: 'Tarifa: medio día (máx. 3h) 130€', 
    schools_list_7: 'día entero (máx. 6h) 260€', 
    schools_text_5: 'Entradas y auriculares no incluidos. El numero máximo de participantes por grupo dependerá de la normativa vigente en cada momento.',
    schools_text_6: 'AGMF encontrara el guía Madrelingua perfecto para cada ocasión',
    schools_tours_title: 'Las visitas más populares para los colegios',
    privates_title: 'Visitas privadas',
    privates_subtitle: '¿Por qué elegir nuestras visitas? ',
    privates_text_1: 'Los guías de la asociación GMF trabajan exclusivamente en su lengua materna garantizando de esta manera a nuestros clientes una visita de calidad, compresible al cien por cien a la vez que entretenida y divertida ya que al proceder del mismo país que los turistas y expresarnos en su mismo idioma podemos escoger que anécdotas, aspectos históricos y culturales pueden ser los más adecuados para la persona o grupo de personas a las que van dirigidos.',
    privates_text_2: 'Queremos subrayar que todas nuestras guías viven en Florencia desde hace muchos años, son florentinas de adopción y conocen y aman tanto la ciudad como un verdadero florentino (o más). <br /><br />Tenemos gran experiencia en visitas con estudiantes, familias y con cualquier persona interesada en conocer las bellezas de Florencia y sus alrededores, de una manera interesante, agradable y atractiva, Todos nuestros tours  se pueden organizar a medida y de manera personalizada, pudiendo elegir otros destinos.',
    privates_contact_us: 'Para mayor información: contact',
    privates_list_1: 'Tarifa: 160 € medio día (3h)', 
    privates_list_2: '320€ día completo (6h)', 
    privates_list_3: 'Hora extra: 55€', 
    privates_list_4: 'Auriculares y entradas a cargo del cliente',
    privates_text_3: 'AGMF encontrara el guia Madrelingua perfecto para cada ocasión',
    privates_tours_title: 'Nuestras propuestas',
}

export const servicesDe = {
    main_title: 'Die beste Tour für Sie',
    main_description: '<strong>Touren in Florenz und Umgebung mit patentierten muttersprachlichen Stadtführern.</strong><br />Wir sind erfahren mit Schülern und jungen Menschen jeden Alters, Familien und Gästen, die allein reisen oder in Gruppen. Wir präsentieren ihnen Florenz auf eine interessante, angenehme unterhaltsame Art. Alle unsere Touren können persönlichen Wünschen angepasst werden, das betrifft auch die Sehenswürdigkeiten selber. Wir sind immer dazu bereit, auch Touren zu organisieren, die nicht auf unserer Homepage stehen.',
    main_contact_us: 'Für weitere Informationen kontaktieren Sie',
    for_schools_button: 'Für schulen',
    for_privates_button: 'Privatreisende',
    schools_title: 'Touren für Schüler jeglichen Alters',
    schools_subtitle_1: 'Projekt Schulen – Die Touristen seid Ihr!',
    schools_text_1: '<strong>Florenztouren mit einer/m muttersprachlichen Stadtführer/in auf Englisch, Französisch, Spanisch u/o Deutsch, für Schüler der „scuole secondarie di I° e II° grado“.</strong><br /><br />Mit dem Schuljahr 2021/2022 bietet unsere „Associazione Guide Madrelingua Firenze (AGMF)” ein neues Projekt an, „Die Touristen seid Ihr“.  Damit ermöglichen wir einen direkten Austausch zwischen Schülern einer Fremdsprache und einem/r Muttersprachler/in. Das Projekt sieht eine Zusammenarbeit mit den Lehrkräften vor, um das didaktische Programm den sprachlichen Fähigkeiten jeder Klasse anzupassen.',
    schools_text_2: '<strong>"Die Touristen seid Ihr"</strong> ist ein Projekt, das darauf basiert, Schülern Florenztouren in einer Fremdsprache anzubieten. Es versteht sich als interaktives und interdisziplinäres Projekt außerhalb des Klassenzimmers mit folgenden Zielen: Die sprachlichen Fähigkeiten zu stimulieren, sich historisch-kulturelles Wissen anzueignen, eine größere Anzahl von Ausflügen anzuregen und das Sozialverhalten der Schüler zu fördern. <br /><br />Sie können sowohl die Anzahl der von uns angebotenen Touren für das akademische Jahr 2021/2022 wählen als auch die gewünschte Sprache (Englisch, Französisch, Spanisch, Deutsch).',
    schools_list_1: 'Florentiner Kuriositäten', 
    schools_list_2: 'Klassischer Stadtrundgang', 
    schools_list_3: 'Florenz von oben', 
    schools_list_4: 'Die schönsten Florentiner Paläste', 
    schools_list_5: 'Florenz und die Wissenschaft',
    schools_text_3: 'Die Touren dauern max. 3 Stunden. Kosten: 8 Euro pro Schüler (Kopfhörer und Eintritt Museo Galileo extra).',
    schools_contact_us: 'Kontaktieren Sie uns für weitere Informationen',
    schools_subtitle_2: 'Schulausflüge – Eure Sprache ist unsere Sprache!',
    schools_text_4: 'Unsere „Associazione Guide Madrelingua Firenze (AGMF)” bietet Schulen Touren in jeder Sprache an, die nach Florenz kommen und die Stadt besichtigen wollen. Sie werden von einem Mitglied der Associazione geführt. Unsere Touren sind lehrreich, dynamisch und unterhaltsam.',
    schools_list_6: 'Tarife: halber Tag (max. 3 Stunden) 130 Euro', 
    schools_list_7: 'Ganzer Tag (max 6 Stunden): 260 Euro', 
    schools_text_5: 'Kopfhörer extra. Die maximale Teilnehmerzahl hängt von den jeweiligen',
    schools_text_6: 'AGMF findet für jegliche Anfrage die perfekte Person für Sie, die Sie führt und begleitet.',
    schools_tours_title: 'Die beliebtesten Touren für Schulen',
    privates_title: 'Privattouren',
    privates_subtitle: 'Warum sollten Sie unsere Touren buchen?',
    privates_text_1: 'Wir arbeiten im Namen unserer Associazione ausnahmslos in unserer jeweiligen Muttersprache. Auf diese Weise garantieren wir unseren Kunden einen hundertprozentig verständlichen und qualitätsvollen Service. Abgesehen von der Sprache erlaubt es unsere Spezialität, nur mit Muttersprachlern zu arbeiten, die auf der Tour angesprochenen Fakten und Themen mit dem jeweiligen Herkunftsland in Beziehung zu setzen. Wir glauben, dass nur ein/e Muttersprachler/in abschätzen kann, was unsere Kunden wirklich interessiert, welche Aspekte von Florenz der heimischen Kultur und Geschichte ähneln und welche nicht. Die Wahl der historischen und kulturellen Aspekte von Florenz, die es zu präsentieren gilt, wird auf diese Weise unseren Kunden und ihrer Welt angepasst.',
    privates_text_2: 'Wir wollen betonen, dass alle unsere Mitglieder seit diversen Jahren in Florenz leben, Adoptiv-Florentiner sind, die die Stadt kennen und lieben wie ein Florentiner (vielleicht sogar noch mehr). <br /><br />Wir sind erfahren mit Schülern und jungen Menschen jeden Alters, Familien und Gästen, die allein reisen oder in Gruppen. Wir präsentieren ihnen Florenz auf eine interessante, angenehme unterhaltsame Art. Alle Touren können persönlichen Wünschen angepasst werden, das betrifft auch die Sehenswürdigkeiten selber.',
    privates_contact_us: 'Für weitere Informationen kontaktieren Sie',
    privates_list_1: 'Tarife: halber Tag (max. 3 Stunden) 160 Euro', 
    privates_list_2: 'Ganzer Tag (max 6 Stunden) 320 Euro', 
    privates_list_3: 'Für jede weitere Stunde 55 Euro extra', 
    privates_list_4: 'Kopfhörer und Eintrittsgelder ausgeschlossen',
    privates_text_3: 'AGMF findet für jegliche Anfrage die perfekte Person für Sie, die Sie führt und begleitet.',
    privates_tours_title: 'Unsere Vorschläge',
}


export const servicesFr = {
    main_title: 'Les meilleurs tours pour satisfaire vos exigences',
    main_description: '<strong>Nos tours et visites à Florence et dans ses environs sont menés per des guides et accompagnateurs touristiques autorisés, dans leur langue maternelle.</strong><br />Nous avons beaucoup d’expérience avec les étudiants et les jeunes de tous les âges, les familles, ainsi qu’avec tous ceux qui sont intéressés à connaître la beauté de Florence et de ses alentours d’une façon intéressante, agréable et attrayante, accompagnés d’un guide de langue maternelle.  Le contenu de chacune de nos visites peut être personnalisé et organisé sur mesure, par exemple en incluant également d’autres sites.  Contactez-nous pour avoir d’autres informations et un devis.',
    main_contact_us: 'Contactez-nous pour en savoir plus',
    for_schools_button: 'Pour les écoles',
    for_privates_button: 'tours privės',
    schools_title: 'Tours pour groupes d’écoliers et étudiants de tout âge.',
    schools_subtitle_1: 'Notre projet pour les écoles italiennes – Les touristes, c’est vous (chez vous)!',
    schools_text_1: '<strong>Visites à Florence avec des guides de langue maternelle française, anglaise, espagnole ou allemande pour élèves d’écoles italiennes du secondaire (collège ou lycée).</strong><br /><br />Dès l’année scolaire 2021/2022 notre Association (AGMF) propose un nouveau projet de formation, «Les touristes, c’est vous», pour favoriser le dialogue entre étudiants et personnes de langue maternelle.  Ce projet prévoit la collaboration entre professeur et guide afin de préparer et adapter la visite aux programmes scolaires et au niveau de langue de la classe.',
    schools_text_2: '<strong>"Les touristes, c’est vous"</strong> est un projet qui offre des visites guidées, en langue étrangère, comme activité pédagogique, interactive et interdisciplinaire, hors de la salle de classe, avec des objectifs ambitieux:  stimuler les capacités linguistiques, enrichir les connaissances historiques, artistiques et culturelles, augmenter les possibilités de sorties scolaires et favoriser une meilleure insertion sociale des élèves. <br /><br />Chaque école pourra choisir aussi bien le nombre de visites auxquelles participer au cours de l’année scolaire, que la langue à utiliser pour chaque sortie proposée:',
    schools_list_1: 'Curiosités florentines', 
    schools_list_2: 'Florence classique', 
    schools_list_3: 'Florence panoramique', 
    schools_list_4: 'Les palais du pouvoir', 
    schools_list_5: 'Florence et la Science au Musée Galilée',
    schools_text_3: 'Chaque sortie aura une durée limitée à trois heures. Coût:  8 euros par étudiant (+ coût des écouteurs et billets au musée Galilée).',
    schools_contact_us: 'Pour des visites différentes de celles proposées ci-dessus, veuillez nous contacter directement.',
    schools_subtitle_2: 'Pour des classes étrangères en visite à Florence – votre langue est la nôtre!',
    schools_text_4: 'AGMF propose aux classes en visite à Florence toutes ses visites, menées de façon amusante et dynamique par un de nos guides qui vous parlera dans votre langue maternelle.',
    schools_list_6: 'Notre tarif: une demi-journée (3 heures) €130', 
    schools_list_7: 'Une journée (6 heures) €260', 
    schools_text_5: 'Le coût des billets et des écouteurs est exclu.  Le nombre maximum de participants admis dans un groupe varie selon les règlements en vigueur au moment de la visite.',
    schools_text_6: 'AGMF vous trouvera un guide qui parle votre langue maternelle et qui vous conviendra parfaitement.',
    schools_tours_title: 'Nos tours plus populaires pour les écoles',
    privates_title: 'Visites privées',
    privates_subtitle: 'Pourquoi choisir nos visites?',
    privates_text_1: 'Nous travaillons pour l’association exclusivement dans notre langue maternelle.  Ceci nous permet de garantir à nos clients des explications de qualité et compréhensibles à 100%.  Il ne s’agit pas seulement d’une question linguistique : cette caractéristique nous consent en outre de mettre tout ce que nous disons en relation avec le pays et la culture d’origine de nos clients.  Le fait que nos guides aient le même bagage linguistique et culturel que leurs clients leur permet de juger ce que les visiteurs trouveront le plus intéressant , parce que semblable ou différent à leurs yeux, et permettra par conséquent à nos guides de décider quels faits historiques, culturels ou sociaux privilégier dans leur narration, en fonction de chaque public.',
    privates_text_2: 'Nous voulons souligner le fait que tous nos guides ont vécu à Florence pendant de nombreuses années.  Nous sommes tous florentins d’adoption et nous aimons notre ville au moins autant qu’un vrai Florentin (parfois même plus). <br/><br/>Nous avons beaucoup d’expérience avec les étudiants et les jeunes de tous les âges, les familles, ainsi qu’avec tous ceux qui sont intéressés à connaître la beauté de Florence et de ses alentours d’une façon intéressante, agréable et attrayante, accompagnés d’un guide de langue maternelle.  Le contenu de chacune de nos visites peut être personnalisé et organisé sur mesure, par exemple en incluant également d’autres sites.',
    privates_contact_us: 'Contactez-nous pour en savoir plus',
    privates_list_1: 'Notre tarif: une demi-journée (3 heures) €160', 
    privates_list_2: 'Une journée (6 heures) €320', 
    privates_list_3: '55 euros pour chaque heure supplémentaire', 
    privates_list_4: 'Ecouteurs et billets sont à la charge des clients',
    privates_text_3: 'AGMF vous trouvera un guide qui parle votre langue maternelle et qui vous conviendra parfaitement',
    privates_tours_title: 'Nos suggestions',
}