export const blurpsEn = {
    certified_title: 'Our guides are all officially licensed',
    certified_description: 'We are experienced, guaranteed professionals in our native language.',
    for_you_title: 'The perfect choice for you',
    for_you_description: 'We share a language and a background.',
    for_kids_title: 'Perfect for young people',
    for_kids_description: 'Our school tours are conceived to instruct and entertain at the same time.',
    intriguing_title: 'Intriguing and interesting',
    intriguing_description: 'You’ll be thirsty to know more after one of our tours.',
    visit_florence_title: 'Visit the marvels of Florence',
    visit_florence_description: 'Come experience what it means to walk around the most beautiful city in the world',
}

export const blurpsIt = {
    certified_title: 'Le nostre guide sono ufficialmente riconosciute',
    certified_description: 'Garantiamo esperienza e professionalità in tutte le lingue',
    for_you_title: 'Perfetto per te',
    for_you_description: 'Vieniamo dalla tua terra, e parliamo la tua lingua',
    for_kids_title: 'Perfetto per Ragazzi',
    for_kids_description: 'I nostri tour per le scuole sono pensati per divertire ed istruire al tempo stesso.',
    intriguing_title: 'Intrigante ed Interessante',
    intriguing_description: 'Dopo un nostro tour ne vorrete sapere sempre di più.',
    visit_florence_title: 'Visita le meraviglie fiorentine',
    visit_florence_description: 'Vieni a provare cosa significa camminare nella più bella città del mondo',
}

export const blurpsEs = {
    certified_title: 'Nuestras guías estan autorizadas y oficialmente reconocidas',
    certified_description: 'Garantizamos experiencia y profesionalidad en todos los idiomas',
    for_you_title: 'Perfecto para ti',
    for_you_description: 'Conocemos tus costumbres, hablamos tu idioma',
    for_kids_title: 'Perfecto para jóvenes',
    for_kids_description: 'Nuestras visitas guiadas para los colegios están pensadas para divertir e instruir al mismo tiempo',
    intriguing_title: 'Intrigante e interesante',
    intriguing_description: 'Al final de nuestro tour deseareis  saber más y más',
    visit_florence_title: 'Visita las maravillas florentinas ',
    visit_florence_description: 'Ven a experimentar el significado de pasear por una de las ciudades más bellas del mundo',
}

export const blurpsDe = {
    certified_title: 'Unsere Stadtführer sind patentiert und offiziell anerkannt.',
    certified_description: 'Wir garantieren Erfahrung und Professionalität in allen Sprachen.',
    for_you_title: 'Perfekt für Sie',
    for_you_description: 'Wir kommen aus Deinem Land, wir sprechen Deine Sprache.',
    for_kids_title: 'Perfekt für Schüler',
    for_kids_description: 'Unsere Touren für Schulen sind unterhaltsam und gleichzeitig lehrreich.',
    intriguing_title: 'Faszinierend und Interessant',
    intriguing_description: 'Nach einer Tour mit uns wollen Sie immer mehr wissen.',
    visit_florence_title: 'Besichtigen Sie die Wunder von Florenz',
    visit_florence_description: 'Kommen und spüren Sie was es heißt, durch die schönste Stadt der Welt zu spazieren.',
}

export const blurpsFr = {
    certified_title: 'Nos guides ont tous leur brevet officiel',
    certified_description: 'Nous garantissons expertise et professionnalisme dans toutes les langues proposées.',
    for_you_title: 'Parfait pour vous',
    for_you_description: 'Nous provenons de votre terre et nous parlons votre langue',
    for_kids_title: 'Parfait pour les jeunes',
    for_kids_description: 'Nos tours pour les écoles sont conçus pour amuser et instruire en même temps.',
    intriguing_title: 'Intriguant et intéressant',
    intriguing_description: 'Après une de nos visites, vous voudrez en savoir encore plus.',
    visit_florence_title: 'Visitez les merveilles de Florence',
    visit_florence_description: 'Venez vivre l’émotion de se promener dans la plus belle ville au monde.',
}