import leone from '../../assets/FOTO per il sito/Curiosità/Leoni vari/leone.jpg'
import ritratto from '../../assets/FOTO per il sito/Curiosità/ritratto1.jpg'

import ponteVecchio from '../../assets/FOTO per il sito/Ponte Vecchio/ponteVecchio.jpg'
import firenzeClassica from '../../assets/FOTO per il sito/Firenze in generale/firenzeClassica.jpg'

import panoramica from '../../assets/FOTO per il sito/Firenze in generale/panoramica2.jpeg'
import rampe from '../../assets/FOTO per il sito/Per Firenze Panoramica/20210504_135701.jpg'

import battistero from '../../assets/battistero.jpg'
import campanile from '../../assets/FOTO per il sito/Firenze in generale/campanile.jpg'

import uffizi from '../../assets/uffiziInterno3.jpg'
import uffizi2 from '../../assets/FOTO per il sito/Uffizi esterno/uffizi.jpg'

import spiniFerroni from '../../assets/FOTO per il sito/Palazzo Spini Feroni/spiniferroni.jpg'
import palazzoVecchio from '../../assets/palazzo-vecchio.jpg'

import david from '../../assets/david2.jpg'
import michelangelo from '../../assets/michelangelo.jpg'

import santaCroce from '../../assets/FOTO per il sito/Santa Croce - SMN/SantaCroce.jpg'
import smn from '../../assets/smn2.jpg'

import galileo from '../../assets/galileo.jpg'
import museoGalileo from '../../assets/FOTO per il sito/Museo Galileo esterno/museogalileo.jpg'

import sanLorenzo from '../../assets/sanlorenzo.jpg'
import lorenzo from '../../assets/lorenzo.jpg'

export const toursIt = [
    {
        slug: 'curiosità_fiorentine',
        name: 'Curiosità Fiorentine',
        card: '<p><strong>Tour adatto ai giovani e ai curiosi di tutte le età.</strong> Passeggiata nel centro storico di Firenze seguendo le orme di affascinanti leggende e curiosità. <br/> Scopriremo insieme segni e simboli che raccontano aspetti drammatici o divertenti della storia fiorentina, spesso sconosciuti ai suoi stessi abitanti. </p>',
        cardImg: leone,
        img: ritratto,
        content: "<p>Nelle immediate vicinanze di luoghi d'interesse come il Duomo, Piazza della Signoria, le chiese di Santa Croce o Santa Maria Novella, decifreremo segni e simboli che raccontano aspetti drammatici o divertenti della storia fiorentina. <br/><br/> Scopriremo volti nascosti e autoritratti di artisti famosi. Conosceremo fantasmi misteriosi e piccoli intrattenitori di corte. Vedremo segni delle alluvioni che hanno devastato la città e dei luoghi di speranza per i bambini abbandonati. Parleremo delle disavventure della palla dorata. Andremo alla scoperta delle buchette del vino e dei leoni di Firenze, simbolo del carattere fiero dei suoi abitanti. <br/><br/> Questo tour è adatto a ospiti che già conoscono Firenze o che sono meno interessati alle ricostruzioni storiche e più interessati ad aneddoti e dettagli nascosti. L'esperienza ha dimostrato che questo tour è adatto anche a famiglie con bambini.</p>",
        start: 'Palazzo Vecchio',
        end:'La Cattedrale',
        points: 'Palazzo Vecchio, il Duomo, Vicoli e strade del Centro Storico di Firenze',
        duration: 'mezza giornata (3h)',
        languages: 'En, Fr, Es, De, (Arabo solo per tour privati)'
    },
    {
        slug: "firenze_classica",
        name: "Firenze classica. Un tour per tutti.",
        card: "<p><strong>Alla scoperta o riscoperta di Firenze e della sua storia.</strong> <br/>Una passeggiata tra i luoghi più importanti e affascinanti del centro storico, come Piazza della Repubblica, Ponte Vecchio, Piazza della Signoria, Palazzo Vecchio, e il complesso della Cattedrale di Santa Maria del Fiore.<br/>Incontreremo personaggi di fama mondiale come Michelangelo, Brunelleschi, Donatello e la famiglia Medici.</p>",
        cardImg: ponteVecchio,
        img: firenzeClassica,
        content: "<p>Durante la nostra passeggiata nel centro storico di Firenze non incontreremo solo alcuni degli edifici più famosi al mondo, come il Duomo fiorentino o la Cupola del Brunelleschi, ma anche personaggi come Arnolfo di Cambio, Michelangelo o la famiglia Medici. <br/><br/>Da Piazza della Repubblica, dove iniziò la storia di Firenze, arriveremo in Piazza della Signoria, forse il più bel museo di scultura all'aperto del mondo. Vi troveremo, tra l'altro, una delle statue bronzee più famose di Benvenuto Cellini, un miracolo in marmo del Giambologna e una copia del David di Michelangelo. Da lì cammineremo verso il Ponte Vecchio con la sua vista sull'Arno, la Galleria degli Uffizi e il Piazzale Michelangelo. Sulla strada per Piazza Santa Croce scopriremo il luogo dove sorgeva l'anfiteatro romano, la casa dove visse il giovane Michelangelo e il luogo dove sorge il più antico palazzo cittadino di Firenze. In Piazza del Duomo vedremo il Battistero, il più antico edificio in piedi di Firenze, e impareremo di più sulla costruzione della cupola del Duomo da parte del Brunelleschi. Concluderemo la nostra passeggiata in città con una visita al Palazzo Medici.<br/><br/> Possibilità di prolungare il tour con una visita alla Galleria dell’Accademia per ammirare il David di Michelangelo.</p>",
        start: 'Piazza della Repubblica',
        end:'Palazzo Medici Riccardi',
        points: 'Piazza della Repubblica, Palazzo Vecchio, Ponte Vecchio, Uffizi, Santa Croce, Duomo, Palazzo Medici Riccardi',
        duration: 'mezza giornata (3h) + 1 h per Accademia (opzionale)',
        languages: 'En, Fr, Es, De, (Arabo solo per tour privati)'
    },
    {
        slug: "firenze_panoramica",
        name: "Firenze Panoramica",
        card: "<p>Passeggiata fiorentina per ammirare la città dall’alto. Il modo migliore per godersi la meravigliosa vista di Firenze in tutto il suo splendore……e per tenerci in forma!</p>",
        cardImg: panoramica,
        img: rampe,
        content: "<p>Passeggiata fiorentina per ammirare la città dall’alto. Il modo migliore per godersi la meravigliosa vista di Firenze in tutto il suo splendore……e per tenerci in forma! <br/><br/> Il tour comincia in Piazza Poggi, sotto l’antica torre di San Niccolò. Saliremo per le Rampe, accompagnati da cascate e belle fontane, fino alla terrazza panoramica del Piazzale Michelangelo. Da qui ci divertiremo a riconoscere i monumenti della città… uno spettacolo unico! Se possibile raggiungeremo la chiesa di San Miniato al Monte, una delle più antiche e belle di Firenze. Scendendo verso il centro storico, troveremo il Giardino delle Rose, con le simpatiche statue dell’artista belga Folon. Dopo una piacevole e breve discesa entreremo da Porta San Miniato e percorrendo via San Niccolò potremo curiosare le sue interessanti botteghe artigianali. La strada ci porterà in Piazza dei Mozzi, da dove usciremo sul lungarno. Da qui potremo osservare da vicino alcuni degli edifici identificati prima dall'alto del piazzale. Ritorneremo a piazza Poggi, concludendo la nostra piacevole passeggiata.</p>",
        start: 'Piazza Poggi',
        end:'Piazza Poggi',
        points: 'Porta San Niccolò, Piazzale Michelangelo, Giardino delle Rose',
        duration: 'mezza giornata (3h)',
        languages: 'En, Fr, Es, De, (Arabo solo per tour privati)'
    },
    {
        slug: "centro_religioso",
        name: "Centro Religioso",
        card: "<p>Complesso Monumentale del Duomo di Firenze. <br/> Passeggiata tra i principali monumenti: Il Battistero di San Giovanni, la Cattedrale di Santa Maria del Fiore con la Cupola del Brunelleschi, il Campanile di Giotto e visita al Nuovo Museo del Duomo.</p>",
        cardImg: battistero,
        img: campanile,
        content: "<p>Inizieremo il tour con una visita del Battistero, il più antico edificio ancora in piedi di Firenze, e ammireremo i suoi straordinari mosaici medievali. Con la costruzione della cattedrale, Arnolfo di Cambio ha cambiato il volto della città realizzando la quarta chiesa più grande della Cristianità, coronata dalla famosa cupola di Filippo Brunelleschi. Parleremo del grande architetto e della sua genialità. Il tour si concluderà con una visita al Museo del Duomo dove troveremo gli originali delle porte bronzee del Battistero di Andrea Pisano e Lorenzo Ghiberti, nonché le statue originali che erano sulla facciata e sul campanile della cattedrale. Potremo ammirare la Maria Maddalena di Donatello, gli strumenti e i modelli originali utilizzati per costruire la cupola della cattedrale, e una delle opere principali di Michelangelo, la commovente Pietà Bandini.</p>",
        start: 'Opera del Duomo, Piazza S. Giovanni',
        end:'Museo dell’Opera del Duomo n.7',
        points: 'I mosaici del Battistero, la Porta del Paradiso, le sculture di Donatello, la Cupola di Brunelleschi',
        duration: 'mezza giornata (3h)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "galleria_uffizi",
        name: "La Galleria degli Uffizi",
        card: "<p>Una visita al museo più famoso d’Italia e tra i più importanti al mondo. Custodisce molti dei capolavori della pittura del Rinascimento italiano oltre alla meravigliosa collezione di sculture antiche e quella di autoritratti di artisti famosi. Un percorso di storia dell’arte affascinante e sorprendente.</p>",
        cardImg: uffizi,
        img: uffizi2,
        content: "<p>In un contesto unico, come l’edificio degli Uffizi, costruito nel XVI secolo dal grande architetto Giorgio Vasari per Cosimo I de Medici, troveremo numerosi capolavori del rinascimento italiano. Attraverso un percorso storico che va dal medioevo fino al‘600, ammireremo opere di Giotto, Masaccio, Filippo Lippi, Botticelli, Leonardo, Michelangelo, Raffaello, Caravaggio, e non solo. La magnifica collezione di sculture antiche ci accompagnerà durante tutto il nostro percorso.<br/><br/>Scopriremo le novità di un museo vivo e in continuo cambiamento: I Nuovi Uffizi ti sorprenderanno!</p>",
        start: 'Ingresso Museo, Piazzale degli Uffizi',
        end:'Uscita Museo',
        points: 'La Primavera di Botticelli, l’Annunciazione di Leonardo, il Tondo Doni di Michelangelo, la Medusa di Caravaggio…',
        duration: 'mezza giornata (3h)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "palazzi_potere",
        name: "Palazzi del Potere",
        card: "<p>Conosceremo i palazzi del potere di Firenze e la loro storia, dalle origini fino ad oggi. Scopriremo i cambiamenti architettonici e di destinazione d’uso... <br/>Alcuni esempi: Palazzo Vecchio; il Bargello; Palazzo Pitti; Palazzo Medici Riccardi; Palazzo Spini Feroni e altri.</p>",
        cardImg: spiniFerroni,
        img: palazzoVecchio,
        content: "<p>Esploreremo esternamente i palazzi che hanno avuto un’importanza storica e politica per Firenze. Scopriremo le varie destinazioni d’uso dai loro inizi fino ad oggi. <br/><br/>Torre della Castagna: Torre medievale, primo luogo di riunioni dei Priori di Firenze…<br/><br/>Palazzo Vecchio, o della Signoria: Palazzo simbolo del governo di Firenze dal 1299 fino ad oggi… <br/><br/>Palazzo Pitti: Il palazzo di residenza del Granducato di Toscana…<br/><br/>Palazzo Medici Riccardi: La prima residenza importante della famiglia Medici… <br/><br/>Il Bargello: Sede del Capitano del Popolo, poi del Podestà, del Bargello, carcere e un luogo di esecuzione di condanne… <br/><br/>Palazzo Spini Feroni: Antica residenza medievale di importanti famiglie…</p>",
        start: 'Palazzo Medici Riccardi',
        end:'Piazza Santa Trinita',
        points: 'Palazzo Vecchio, il Bargello, Palazzo Pitti, Palazzo Medici Riccardi, Palazzo Spini Feroni, Torre della Castagna',
        duration: 'mezza giornata (3h)',
        languages: 'En, Fr, Es, De, (Arabo solo per tour privati)'
    },
    {
        slug: "michelangelo",
        name: "In Giro a Firenze con Michelangelo",
        card: "<p>Alla scoperta dei luoghi e dei capolavori più importanti di Michelangelo a Firenze</p>",
        cardImg: david,
        img: michelangelo,
        content: "<p>Metà giornata (3h): <br/><br/>Michelangelo ha lasciato il maggior numero dei suoi capolavori tra Firenze e Roma. Andremo a scoprire i suoi capolavori giovanili a Casa Buonarroti poi faremo confronti tra il suo Bacco e altri capolavori di scultura al Museo del Bargello. Successivamente, andremo ad ammirare la sua opera più famosa, il David, al Museo dell’Accademia. Avremo occasione di ripercorrere le tappe più importanti della vita di Michelangelo e ne scopriremo alcuni fatti meno noti. <br/><br/>Giornata intera (6h + pausa pranzo): <br/><br/>Per chi sceglie di fare la giornata intera, oltre a quello descritto sopra, studieremo Michelangelo architetto, nonché scultore, nella Sacrestia Nuova e nell'affascinante biblioteca Laurenziana </p>",
        start: 'Museo Casa Buonarroti',
        end:'Galleria dell’Accademia (3h), Complesso di San Lorenzo (6h)',
        points: 'Casa Buonarroti, il Bargello, Galleria dell’Accademia, Sacrestia Nuova, Biblioteca Laurenziana',
        duration: 'mezza giornata (3h) o giornata intera (6h + pausa pranzo)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "smn_santa_croce",
        name: "Santa Maria Novella e Santa Croce",
        card: "<p>Le due chiese mendicanti di Santa Maria Novella e Santa Croce sono tra i luoghi più famosi della città, e contengono alcuni dei monumenti e opera d’arte più importanti a Firenze.</p>",
        cardImg: santaCroce,
        img: smn,
        content: "<p>I Domenicani iniziarono la storia degli ordini mendicanti a Firenze. La storia di Santa Maria Novella è affascinante tanto quanto alcune delle opere d'arte di fama mondiale che possiamo visitare nella chiesa, come l'affresco della Trinità di Masaccio, il Crocifisso ligneo di Giotto, il Crocifisso del Brunelleschi o il ciclo di affreschi di Domenico Ghirlandaio.<br/><br/> Santa Croce, o il \"Pantheon fiorentino\", è la più grande chiesa francescana del mondo. Offre la possibilità di visitare le tombe di Galileo Galilei, Machiavelli o Michelangelo nonché gli straordinari affreschi di Giotto e dei suoi successori o un'opera tarda di Filippo Brunelleschi, la Cappella dei Pazzi.</p>",
        start: 'Piazza Santa Maria Novella',
        end:'Piazza Santa Croce',
        points: 'Gli affreschi di Masaccio e del Ghirlandaio, il crucifisso del Brunelleschi, gli affreschi di Giotto, le tombe di Galileo e di Michelangelo, la Cappella dei Pazzi del Brunelleschi',
        duration: 'giornata intera, 6h + pausa pranzo, oppure 3 h singola chiesa e dintorni',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "museo_galileo",
        name: "Firenze e Scienza: Museo Galileo",
        card: "<p>Questo museo conserva una delle raccolte di strumenti scientifici storici più importanti al mondo, in particolare quelli appartenuti a Galileo, oltre alle interessanti collezioni Medicee e Lorenesi.</p>",
        cardImg: galileo,
        img: museoGalileo,
        content: "<p>Con il Museo Galileo, Firenze gode di una straordinaria risorsa didattica per scoprire come, nel corso dei secoli, si pensava fosse il cosmo—l’universo che circonda la terra con i pianeti e le stelle. Lì potrete vedere e apprezzare l’ingegno di tanti che hanno fatto progredire la nostra comprensione del mondo, Galileo incluso, e ammirare oggetti costruiti a Firenze e altrove per poter misurare il tempo e rappresentare i movimenti planetari nel cosmo.  Di volta in volta, questo giro verrà preparato nei suoi particolari in collaborazione con gli insegnanti per garantire una migliore fruizione da parte degli alunni coinvolti. </p>",
        start: 'Ingresso museo, Piazza dei Giudici 1',
        end:'Uscita museo',
        points: 'Gli strumenti scientifici, in particolare quelli di Galileo',
        duration: 'mezza giornata (3h)',
        languages: 'En, Fr'
    },
    {
        slug: "medici",
        name: "I Medici",
        card: "<p>La famiglia più famosa di Firenze che ha determinato la storia e la cultura della città.</p>",
        cardImg: sanLorenzo,
        img: lorenzo,
        content: "<p>Andremo alla scoperta della storia di una delle famiglie più famose d´Europa. Scopriremo i personaggi e le vicende che hanno segnato la sorte di Firenze e della Toscana attraverso un percorso tra i luoghi più emblematici della città come il Complesso di San Lorenzo, Palazzo Medici Riccardi, Palazzo Vecchio e Palazzo Pitti.<br/><br/>Il tour è previsto in 3 o 6 ore, con o senza ingressi.<br/><br/>Mezza giornata (3h): Palazzo Medici Riccardi, il Complesso di San Lorenzo.<br/><br/>Giornata intera (6h + pranzo): Programma della mezza giornata + Palazzo Vecchio e Palazzo Pitti (esterno)</p>",
        start: 'San Lorenzo',
        end:' Palazzo Vecchio (3h) o Palazzo Pitti (6h)',
        points: 'San Lorenzo, Palazzo Medici, Palazzo Vecchio',
        duration: 'mezza giornata (3h) o giornata intera (6h + pausa pranzo)',
        languages: 'En, Fr, Es, De, Ar'
    },
]

export const toursEn = [
    {
        slug: 'curiosità_fiorentine',
        name: 'Florentine curiosities',
        card: '<p><strong>A tour for the curious, young or old.</strong> This walk through the historic center of Florence tracks fascinating legends and curiosities.  Together, we will discover signs and symbols which relate to dramatic or amusing episodes of Florentine history, often not known even to the city’s inhabitants.</p>',
        cardImg: leone,
        img: ritratto,
        content: "<p>In the immediate vicinities of some main sites of interest such as the Duomo, Piazza della Signoria, and the churches of Santa Croce or Santa Maria Novella, we will decipher still-visible signs and symbols which relate to dramatic or entertaining events of Florence’s history. <br/><br/> We will discover hidden faces and  self-portraits by famous artists.  We will cross the path of mysterious ghosts and small court entertainers.  We will see the signs of floods that devastated the city and the places where abandoned infants could hope for a better life.  We will discuss the misadventures of a golden ball.  We will spot wine-selling “holes” in old buildings and view lions in the city—the symbol of city pride and character. <br/><br/> This tour is particularly well-suited to those who know Florence already or to those who are less interested in history and more drawn to anecdotes and hidden details.  Our experience shows this tour to be well suited to families with children. </p>",
        start: 'Palazzo Vecchio',
        end:'The Duomo',
        points: 'Palazzo Vecchio, the Duomo, the street and alleys of the old center of town.',
        duration: 'Half day (3 hrs)',
        languages: 'En, Fr, Es, De, (Arabic for private tours only)'
    },
    {
        slug: "firenze_classica",
        name: "A classic tour of Florence (a tour for everyone)",
        card: "<p><strong>Discovering or rediscovering Florence and its history.</strong> <br/>We walk through the most important and fascinating sites of the historic center, such as Piazza della Repubblica, the Ponte Vecchio, Piazza della Signoria, Palazzo Vecchio, and the complex of the cathedral (the duomo) of Santa Maria del Fiore.  Along the way we come across the work of world-famous historical figures including Michelangelo, Brunelleschi, and various members of the Medici family.</p>",
        cardImg: ponteVecchio,
        img: firenzeClassica,
        content: "<p>Along our walk through the historic center of Florence we will see some of the world’s most famous buildings—the Duomo and its dome by Brunelleschi—but also the work of other greats including Arnolfo di Cambio, Brunelleschi, Michelangelo, and effigies of members of the Medici family. <br/><br/>Starting in Piazza della Repubblica, where the history of Florence begins, we go to Piazza della Signoria, to view perhaps the world’s greatest collection of open-air sculpture including Benvenuto Cellini’s most famous bronze statue of Perseus, a marble miracle by Giambologna, and a copy of Michelangelo’s David in its original emplacement.  From there we walk past the Uffizi Gallery to the Ponte Vecchio with its fine view of the Arno river and Piazzale Michelangelo up above, across the river.  On the way to the piazza and church of Santa Croce, we walk past the site of the Roman amphitheater, past the house where a young Michelangelo lived and the oldest standing residential building of Florence.  Then, in Piazza del Duomo we view the baptistery—the oldest building in Florence still standing—and we learn more about the construction of the dome by Brunelleschi.  Our walk concludes with a visit to the courtyard of the Medici Palace.<br/><br/> One may plan to extend this tour by an hour for a visit to the Accademia Gallery to see Michelangelo’s David.</p>",
        start: 'Piazza della Repubblica',
        end:'Palazzo Medici-Riccardi (or the Accademia)',
        points: 'Piazza della Repubblica, Palazzo Vecchio, Ponte Vecchio, Uffizi, Santa Croce, Duomo, Palazzo Medici Riccardi',
        duration: 'Half day (3 hrs) + 1hr for the Accademia',
        languages: 'En, Fr, Es, De, (Arabic for private tours only)'
    },
    {
        slug: "firenze_panoramica",
        name: "A panoramic tour of Florence",
        card: "<p>This walk enables us to view the city from above.  This is the best way to enjoy a marvelous vista of Florence in all its splendor… and to stay in shape!</p>",
        cardImg: panoramica,
        img: rampe,
        content: "<p>This loop walk enables us to view the city from above.  This is the best way to enjoy a marvelous vista of Florence in all its splendor… and to stay in shape! <br/><br/> This tour starts in Piazza Poggi, under the tower of the gate of San Niccolò.  We will walk up the Rampe, the switch-back road, past waterfalls and fountains to reach the panoramic lookout point in Piazzale Michelangelo.  Here we can have fun identifying monuments in the city… a wonderful sight!  If we wish, we may proceed a little further up to view the wonderful early Romanesque façade  of San Miniato al Monte.  Coming back down into town we pass the Rose Garden with its witty and endearing statues by Belgian artist Folon.  Our walk down bring us to the San Miniato Gate.  Along via San Niccolò we can window-shop in interesting artisan’s shops.  We come out onto the south bank of the Arno and make our way upstream to our starting point.</p>",
        start: 'Piazza Poggi',
        end:'Piazza Poggi',
        points: 'Porta San Niccolò, Piazzale Michelangelo, the Rose Garden',
        duration: 'Half-day (3 hrs)',
        languages: 'En, Fr, Es, De, (Arabic for private tours only)'
    },
    {
        slug: "centro_religioso",
        name: "Florence’s Religious Center",
        card: "<p>The Monumental Duomo Complex. <br/> A walk among the principal religious monuments around the Cathedral:  the Baptistry of Saint John, the Cathedral, or duomo, of Santa Maria del Fiore, the Dome by Brunelleschi, the bell tower by Giotto, ending with a visit to the new Museum of the Duomo complex.</p>",
        cardImg: battistero,
        img: campanile,
        content: "<p>This tour begins with a visit to the Baptistery, Florence’s oldest building still standing, and to its extraordinary medieval mosaics inside.  The cathedral conceived by Arnolfo di Cambio changed the face of the city and gave it its most famous landmark and the fourth largest Christian church in the world.  It is crowned by Filippo Brunelleschi’s dome whose innovative design and building techniques we will discuss.  Our tour ends at the Museum of the Duomo (Museo dell’Opera del Duomo) where we can admire the original bronze doors that graced the Baptistery by Andrea Pisano and Lorenzo Ghiberti, Donatello’s penitent Mary Magdalene, as well as much else, including  Michelangelo’s last and incomplete sculpture group, the moving Pietà Bandini.</p>",
        start: 'Piazza S. Giovanni',
        end:'Museo dell’Opera del Duomo',
        points: 'The Baptistry, the Duomo, Giotto’s bell  tower, the Museo dell’Opera del Duomo',
        duration: 'Half day (3 hrs)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "The Uffizi Gallery",
        name: "La Galleria degli Uffizi",
        card: "<p>Visit one of the most famous museums in Italy, among the most important in the world.  It holds many masterpieces of Italian Renaissance painting in addition to a wonderful collection of antique sculptures, as well as a collection of famous artists’ self-portraits.  A fascinating and surprising itinerary in the history of art.</p>",
        cardImg: uffizi,
        img: uffizi2,
        content: "<p>This Uffizi building, built by the great XVIth-century architect Giorgio Vasari for Cosimo I de Medici, is the unique setting for great masterpieces of the Italian Renaissance.  Following a historical progression from the Middle Ages to the 1600s, we can admire works by Giotto, Masaccio, Filippo Lippi, Botticelli, Leonardo, Michelangelo, Raphael, Titian, and Caravaggio, among others.  A magnificent collection of antique sculptures will accompany us along the way.  You may discover new things in this ever-changing museum. <br/><br/>To those who know it, the new Uffizi will surprise you!</p>",
        start: 'The Museum entrance',
        end:'The Museum exit',
        points: 'Botticelli’s Primavera, Leonardo’s Annunciation , Michelangelo’s Doni Tondo, Caravaggio’s Medusa',
        duration: 'Half day (3 hrs)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "palazzi_potere",
        name: "Sites of Power",
        card: "<p>We will come to know the buildings, the palazzi, where political power was exercised and their history, including the different uses to which they were put,  from their beginnings to the present.</p>",
        cardImg: spiniFerroni,
        img: palazzoVecchio,
        content: "<p>We will explore the exterior of the buildings, palazzi, of greatest political significance in the history of Florence.  We will discover how they were variously used from their beginnings to the present. <br/><br/>The Convent of San Marco—its re-construction for the Dominicans was financed by Cosimo the Elder Medici, designed by Cosimo’s pet architect , Michelozzo.  This square also looks onto the place where Lorenzo the Magnificent had his sculpture garden.<br/><br/>Palazzo Vecchio, previously known as the palazzo of the Signoria (the later name given to the council of the Priors), was the symbol of the Republican government of Florence from 1299 when its construction began.<br/><br/>Palazzo Pitti  bought by Cosimo I, Medici, became the Grand Dukes’ residence.<br/><br/>Now a museum for sculpture, among other things, the Bargello first housed the Captain of the People, then the podestà, then the Bargello (chief of police). It has also been used as a prison and place of torture and executions. </p>",
        start: 'Convento of San Marco',
        end:'Piazza Pitti',
        points: 'Palazzo Vecchio; il Bargello; Palazzo Pitti; Palazzo Medici Riccardi; Palazzo Spini Feroni, Torre della Castagna, Convento of San Marco',
        duration: 'Half day (3 hrs) or full day (6 hrs)',
        languages: 'En, Fr, Es, De, (Arabic for private tours only)'
    },
    {
        slug: "michelangelo",
        name: "Following Michelangelo’s footsteps",
        card: "<p>Discovering the sites and the masterpieces associated with Michelangelo’s time in Florence</p>",
        cardImg: david,
        img: michelangelo,
        content: "<p>The half day tour (3 hrs): <br/><br/>Michelangelo has left us the greatest number of his works in Florence and Rome.  We start with great works of his youth at Casa Buonarroti, we then compare his Bacchus to other masterpieces of sculpture at the Bargello Museum.  We subsequently proceed to the Accademia to admire his most famous work:  the David.  Along the way we will go over the major phases of his career and learn lesser-known facts about him. <br/><br/>Full day (6 hr) tour with a lunch break: <br/><br/>On the full day tour, we also get to see Michelangelo’s work as an architect (and sculptor) in the Medici Chapels and in the fascinating Laurentian Library. </p>",
        start: 'Museo Casa Buonarroti',
        end:'The Accademia Gallery (3hr), Piazza San Lorenzo (6hr)',
        points: 'Casa Buonarroti, the Bargello Museum, the Gallery of the Accademia, the Medici Chapels, the Laurentian Library',
        duration: 'Half day (3 hrs) or full day (6 hrs)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "smn_santa_croce",
        name: "Santa Maria Novella, Santa Croce",
        card: "<p>These two churches, each belonging to one of the two new mendicant orders of the XIIIth century, are among the most famous sites of the city and contain some of the most important art work in Florence.</p>",
        cardImg: santaCroce,
        img: smn,
        content: "<p>Santa Maria Novella is the church on the site where the Dominicans first established themselves in Florence.  The history of this church is as fascinating as that of some of the world famous artwork it contains, including Masaccio’s fresco of the Trinity, Giotto’s monumental wooden cross, Brunelleschi’s Crucifix and the great fresco cycle by Domenico Ghirlandaio.<br/><br/> XIVth-century Santa Croce is still the largest Franciscan church in the world.  It is also known as the “Florentine Pantheon” for holding the tombs of the likes of Michelangelo, Galileo, and Machiavelli as well as boasting extraordinary fresco cycles by Giotto and by his successors, and Brunelleschi’s late architectural masterpiece, the Pazzi Chapel. </p>",
        start: 'Piazza Santa Maria Novella',
        end:'Piazza Santa Croce',
        points: 'Santa Maria Novella with its Renaissance façade of Santa Maria Novella by Leon Battista Alberti and the Tornabuoni Chapel (Ghirlandaio fresco cycle);  Santa Croce and its monumental tombs and cenotaphs, the Pazzi Chapel.',
        duration: 'Full day (6 hrs + lunch break) or half day (3hrs) of a tour of one church and its neighborhood',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "museo_galileo",
        name: "Florence and Science (at the Galileo Museum)",
        card: "<p>The Galileo Museum houses one of the most important collections in the world of historical scientific instruments, most especially those belonging to Galileo, as well as elements of the collections assembled by the Medici and then the Lorraine Grand-dukes.</p>",
        cardImg: galileo,
        img: museoGalileo,
        content: "<p>In the Galileo Museum, Florence enjoys an extraordinary learning resource for discovering how, over the course of centuries, people conceived the cosmos—the universe surrounding the earth, including the planets and the stars.  Here one can see and appreciate the ingenuity of many who contributed to improve our understanding of the world, including Galileo, while admiring objects built in Florence and elsewhere used to measure time and to represent the movements of the planets in the cosmos.  On each occasion, this tour will be prepared in detail in collaboration with the teachers to ensure the fullest understanding on the part of the students.</p>",
        start: 'Museum entrance',
        end:'Museum exit',
        points: 'Scientific instruments, particularly Galileo’s',
        duration: 'Half day (3 hrs)',
        languages: 'En, Fr'
    },
    {
        slug: "medici",
        name: "The Medici",
        card: "<p>A tour about Florence’s most famous family who left their indelible mark on the history and culture of the city.</p>",
        cardImg: sanLorenzo,
        img: lorenzo,
        content: "<p>Half day (3 hrs): <br/><br/> We review the history of one of the most famous families In Europe through buildings closely associated with them.  We will visit the church of San Lorenzo, designed by Brunelleschi and funded mainly by the Medici.  Medici family funerals took place here.  We then visit the Medici Chapels—the “New Sacristy” designed by Michelangelo and decorated with statues he sculpted, as well as the Medici Chapel, designed to house the tombs of the later Medici rulers and their families.  We also go into the Medici Palace and see how this building was a great novelty in Florence, then copied by others. <br/><br/>Full day (6 hrs + lunch break): <br/><br/> The full day includes the half-day tour in the morning and, after the lunch break, a visit to Palazzo Vecchio whose function was completely overhauled by the later Medici and Palazzo Pitti, the residence of the Medici Grandukes as well as of their Lorraine successors.</p>",
        start: 'San Lorenzo church',
        end:'Piazza San Lorenzo (3 hr tour) or Palazzo Pitti (6hr tour)',
        points: 'San Lorenzo, Palazzo Medici, Palazzo Vecchio, Palazzo Pitti.',
        duration: 'Half day (3hrs) or full day (6hrs + lunch break)',
        languages: 'En, Fr, Es, De, Ar'
    },
]

export const toursEs = [
    {
        slug: 'curiosità_fiorentine',
        name: 'Curiosidades Florentinas',
        card: '<p><strong>Un tour perfecto para jóvenes y curiosos de todas las edades.</strong> Paseo por el centro histórico de Florencia siguiendo las huellas de fascinantes leyendas y curiosidades. <br/> Juntos descubriremos signos y símbolos que hablan de aspectos dramáticos o divertidos de la historia florentina, a menudo desconocidos por sus propios habitantes. </p>',
        cardImg: leone,
        img: ritratto,
        content: "<p>Al lado de los lugares de mayor interés de la ciudad, como el Duomo, la plaza de la Signoria, la iglesia de Santa Croce o Santa Maria Novella, descifraremos signos y símbolos  que nos narran aspectos dramáticos o divertidos de  la historia florentina.<br/><br/> Descubriremos rostros ocultos  y autorretratos de artistas famosos. Conoceremos fantasmas misteriosos y bufones de la Corte. Veremos marcas de las inundaciones que han devastado la ciudad y conoceremos orfanatos y hospicios. Hablaremos de las desventuras de la bola dorada. Iremos a descubrir las ventanitas del vino y los leones de Florencia, símbolo del  carácter decidido de sus habitantes.<br/><br/> Esta tour está pensado para personas que ya conocen Florencia o tienen menos interés por su historia. Personas deseosas de conocer anécdotas y curiosidades. La experiencia nos ha demostrado que este tour es muy recomendable para familias con niños.</p>",
        start: 'Palacio Vecchio',
        end:'la Catedral',
        points: 'Palacio Vecchio, el Duomo, las calles del centro històrico e Florencia',
        duration: 'medio día (3h)',
        languages: 'En, Fr, Es, De, (Árabe solo para visitas privadas)'
    },
    {
        slug: "firenze_classica",
        name: "Florencia clásica. Un tour para todos",
        card: "<p><strong>Descubriendo Florencia y su historia.</strong> <br/>Una paseo por los lugares mas importantes y fascinantes del centro histórico, como plaza de la Repubblica, el Ponte Vecchio, Plaza de la Signoria, Palazo Vecchio y el complejo de la Catedral de Santa Maria del Fiore. Conoceremos a personajes de fama mundial como Miguel Ángel, Brunelleschi y la familia Medicis.</p>",
        cardImg: ponteVecchio,
        img: firenzeClassica,
        content: "<p>Durante nuestro paseo por el centro histórico de Florencia , no solo conoceremos algunos de los edificios más famosos del mundo, como el Duomo fiorentino o la Cupula de Brunelleschi, sino también a personajes como Arnolfo de Cambio, Leonardo da Vinci , Miguel Ángel o la familia Medici <br/><br/>Desde la plaza de la República, donde comenzó la historia de Florencia, llegaremos a la plaza de la Signoria, probablemente el museo de esculturas al aire libre más bonito del mundo.  Aquí se encuentran, entre otras, una de las estatuas de bronce más famosas de Benvenuto Cellini, un milagro en mármol de Giamboloña y una copia del David de Miguel Ángel. Desde allí caminaremos hacia el Ponte Vecchio, donde podemos admirar el rio Arno, la Galeria de los Uffizi y el Piazzale Michelangelo. De camino hacia la plaza Santa Croce descubriremos el lugar donde se encontraba el antiguo anfiteatro romano y la casa en donde vivió el joven Miguel Ángel. En la plaza del Duomo veremos el Baptistero, el edificio en pie más antiguo de Florencia, y aprenderemos más cosas sobre Brunelleschi. Concluiremos nuestro paseo  por la ciudad en el Palacio Medici.<br/><br/> Posibilidad de alargar el tour con una visita a la Galería de la Academia para admirar el David de Miguel Ángel.</p>",
        start: 'Plaza de la Republica',
        end:'Palacio Medici Riccardi',
        points: 'Plaza de la República, Palacio Veccchio, Ponte Vecchio, Uffizi, Santa Croce, Duomo, Palazzo Medici Riccardi',
        duration: 'medio día (3h) + 1 h para Accademia (facultativo)',
        languages: 'En, Fr, Es, De, (Árabe solo para visitas privadas)'
    },
    {
        slug: "firenze_panoramica",
        name: "Florencia Panoràmica",
        card: "<p>Paseo florentino para admirar la ciudad desde lo alto. La mejor manera de disfrutar de la maravillosa vista de Florencia con todo su esplendor...¡y mantenernos en forma!</p>",
        cardImg: panoramica,
        img: rampe,
        content: "<p>Paseo florentino para admirar la ciudad desde lo alto. La mejor manera de disfrutar de la maravillosa vista de Florencia con todo su esplendor....¡y mantenernos en forma! <br/><br/> El tour empieza en la plaza Poggi, junto a la torre de San Niccolò. Subiremos por las Rampas, acompañados por cascadas y  fuentes, hasta la terraza panorámica del Piazzale Michelangelo. Desde aquí nos divertiremos reconociendo los monumentos de la ciudad... ¡un espectáculo único! Si tenemos tiempo llegaremos hasta la iglesia de San Miniato al Monte, una de las más antiguas y bellas de Florencia. Descendiendo hacia el centro histórico encontraremos el Jardín de las Rosas, con las simpáticas estatuas del artista belga Folon. Tras una breve y agradable bajada entraremos por la Puerta de San Miniato y recorreremos la calle de San Niccolò, donde podremos curiosear sus interesantes talleres de artesanía. Antes de terminar nuestro paseo saldremos a la orilla del rio para admirar la ciudad desde abajo regresando a nuestro punto de inicio.</p>",
        start: 'Plaza Poggi',
        end:'Plaza Poggi',
        points: 'Puerta de San Nicolo, Piazzale Michelangelo, Jardin de las Rosas',
        duration: 'medio día (3h)',
        languages: 'En, Fr, Es, De, (Árabe solo para visitas privadas)'
    },
    {
        slug: "centro_religioso",
        name: "Centro Religioso",
        card: "<p>Complejo Monumental del Duomo de Florencia.<br/> Paseo entre los principales monumentos: el Baptisterio de San Juan, la Catedral de Santa Maria dei Fiori con la Cupula de Brunelleschi, el Campanario de Giotto y la visita al Nuevo Museo del Duomo.</p>",
        cardImg: battistero,
        img: campanile,
        content: "<p>Comenzamos el tour con la visita al Baptisterio, el edificio en pie más antiguo de Florencia,  y admiraremos sus extraordinarios mosaicos medievales.  Con la construcción de la Catedral de Santa Maria dei Fiori, Arnolfo di Cambio cambió la imagen de la ciudad, realizando la cuarta iglesia más grande de la cristiandad. Está coronada por la famosa cúpula de Filippo Brunelleschi. Hablaremos del gran arquitecto y de su genialidad. La visita finalizará en el Museo del Duomo, donde podremos admirar los originales de las puertas de bronce  del Baptisterio realizadas por Andrea Pisano y Lorenzo Ghiberti, las estatuas de la antigua fachada y las decoraciones del campanario de la catedral. Encontraremos también la Santa  María Magdalena de Donatello, los instrumentos y modelos originales utilizados en la construcción de la catedral y una de las obras importantes de Miguel Ángel, la conmovedora Piedad Bandini.</p>",
        start: 'Opera del Duomo, Plaza S. Giovanni',
        end:'Museo de la Opera del Duomo',
        points: 'Mosaicos del Baptisterio, Puerta del Paraiso, esculturas de Donatell, Cupula de Brunelleschi',
        duration: 'medio día (3h)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "galleria_uffizi",
        name: "Galería de los Uffizi",
        card: "<p>Una visita al museo más famoso de Italia y uno de los más importantes del mundo. Podremos admirar gran parte de las obras maestras del Renacimiento italiano, además de las maravillosas colecciones de esculturas antiguas y de autorretratos de artistas famosos. Un recorrido a través de la historia del arte, fascinante y sorprendente.</p>",
        cardImg: uffizi,
        img: uffizi2,
        content: "<p>En un ambiente único, como es el edificio de los Uffizi, construido en el S XVI, por el architecto Giorgio Vasari, para Cosimo I de Medicis, encontraremos las obras maestras del Renacimiento italiano. A través de un recorrido histórico, que va desde la época medieval hasta el S XVII, admiraremos la pintura de Giotto, Masaccio, Filippo Lippi, Botticelli, Leonardo, Miguel Angel, Rafael, Tiziano, Caravaggio... La magnífica colección de esculturas antiguas nos acompañará a lo largo de todo nuestro recorrido. <br/><br/>Descubriremos juntos las novedades de un museo vivo y en continua evolución. ¡Los Nuevos Uffizi te sorprenderán!</p>",
        start: 'Entrada Museo',
        end:'Salida Museo',
        points: 'La Primavera de Botticelli, la Anunciacion de Leonardo, el Tondo Doni de Miguel Angel, la Medusa de Caravaggio',
        duration: 'medio día (3h)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "palazzi_potere",
        name: "Palacios del Poder",
        card: "<p>Conoceremos la historia de los palacios del Poder  de Florencia, desde sus orígenes hasta nuestros días. Descubriremos los cambios producidos tanto en su arquitectura como en su uso...<br/>Algunos ejemplos: Palacio Vecchio, El Bargello, Palacio Pitti, Palacio Medici Riccardi, Palacio Spini Feroni entre otros.</p>",
        cardImg: spiniFerroni,
        img: palazzoVecchio,
        content: "<p>Exploraremos en su exterior los edificios que han tenido una importancia histórica y política para Florencia.<br/><br/>Descubriremos las distintas funciones que han tenido desde su construcción hasta nuestros días.<br/><br/>Palacio Vecchio, o de la Signoria: edificio símbolo del gobierno de Florencia desde el 1299 hasta hoy... <br/><br/>Palacio Pitti: la residencia del Gran Ducado de Toscana…<br/><br/>Palacio Medici Riccardi: la primera residencia importante de la familia Medici…<br/><br/>El Bargello: Pasó de ser sede del “Capitano del Popolo” y posteriormente del “Podestá”, a albergar la cárcel y ser lugar de ejecución de los condenados a muerte... <br/><br/>Palacio Spini Feroni: antigua residencia medieval de importantes familias...</p>",
        start: 'Palacio Medici Riccardi',
        end:'Plaza Santa Trinidad',
        points: 'Palacio Vecchio, el Bargello, Palacio Pitti, Palacio Medici Riccardi, Palacio Spini Feroni, Torre de la Castaña',
        duration: 'medio día (3h)',
        languages: 'En, Fr, Es, De, (Árabe solo para visitas privadas)'
    },
    {
        slug: "michelangelo",
        name: "De paseo por Florencia con Miguel Angel",
        card: "<p>Descubriremos los lugares y obras maestras más importantes de Miguel Ángel en Florencia</p>",
        cardImg: david,
        img: michelangelo,
        content: "<p>Medio día (3h): <br/><br/>La mayor parte de las obras maestras de Miguel Ángel las encontramos en Florencia y Roma. Antes de admirar en la Galería de la Academia la obra más famosa del artista, el David,  visitaremos alguno de sus primeros trabajos importantes, como los que encontramos en la casa Buonarroti una de sus viviendas en Florencia, hoy museo consagrado al artista y su representación de Bacco del museo del Bargello.. Durante el tour además de la vida de Miguel Ángel se relataran anécdotas del artista. <br/><br/>Día entero (6h + descanso para comer): <br/><br/>En el tour de día completo (6h), estudiaremos además a Miguel Ángel escultor y arquitecto, con la visita a las Capillas de los Medicis y a la fascinante biblioteca Laurenciana. </p>",
        start: 'Museo Casa Buonarroti',
        end:'Galeria de la Academia (3h), Complejo de San Lorenzo (6h)',
        points: 'Casa Buonarroti, el Bargello, Galeria de la Academia, Capillas Mediceas, Biblioteca Laurenciana',
        duration: 'medio día (3h) o día entero (6h + pausa para comer)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "smn_santa_croce",
        name: "Santa Maria Novella y Santa Croce",
        card: "<p>Las dos iglesias mendicantes Santa Maria Novella y Santa Croce se encuentran entre los lugares más famosos de la ciudad, en su interior podremos admirar algunas de las obras de arte y monumentos más importantes de Florencia.</p>",
        cardImg: santaCroce,
        img: smn,
        content: "<p>Fueron los Dominicos los que comenzaron la historia de las órdenes mendicantes en la ciudad. La historia de Santa Maria Novella es tan fascinante como lo son algunas de las obras de arte de fama mundial, que podemos admirar en su interior, como la Trinidad de Masaccio, el Crucifijo de Brunelleschi o el ciclo de frescos de Domenico Ghirlandaio.<br/><br/> Santa Croce, o “Panteon florentino”, es la iglesia de la orden de los Franciscanos más grande del mundo. En ella podemos visitar las tumbas de Galileo Galilei, Macchiavelli o Miguel Ángel, además de contemplar los extraordinarios frescos de Giotto y de sus sucesores, o la Capilla de los Pazzi, obra tardía de Bruneslleschi.</p>",
        start: 'Plaza Santa Maria Novella',
        end:'Plaza Santa Croce',
        points: 'Frescos de Masaccio y Ghirlandaio, Crucifijo de Brunelleschi, frerscos de Giotto, tumbas de Galileo y Miguel Angel, la Capilla Pazzi de Brunelleschi...',
        duration: 'día completo (6h + descanso para comer), o medio día (3h), tour de una iglesia y alrededores',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "museo_galileo",
        name: "Florencia y la Ciencia: Museo Galileo",
        card: "<p>El Museo Galileo de Florencia conserva una de las colecciones de instrumentos científicos históricos más importantes del mundo. Podremos admirar  instrumentos personales de Galileo, además de las interesantes colecciones de la familia de los Medecis y Lorena.</p>",
        cardImg: galileo,
        img: museoGalileo,
        content: "<p>El Museo Galileo, representa para Florencia un recurso educativo extraordinario ya que permite a los visitantes descubrir el concepto que se ha tenido del cosmos durante muchos siglos. En él podréis apreciar el ingenio de todos aquellos, incluido Galileo, que han hecho posible la actual comprensión del universo que rodea la Tierra, los planetas y las estrellas. Admirar los objetos construidos en Florencia y otros lugares para medir el tiempo y representar los movimientos planetarios del cosmos. Los detalles de esta visita se preparan en colaboración con los profesores para garantizar un mejor aprovechamiento por parte de los alumnos participantes.</p>",
        start: 'Entrada museo Galileo, plaza dei Giudici 1',
        end:'Salida museo',
        points: 'Instrumentos cientificos, en especial los que pertenecieron a Galileo',
        duration: 'medio día (3h)',
        languages: 'En, Fr'
    },
    {
        slug: "medici",
        name: "Los Medicis",
        card: "<p>La familia más importante de Florencia y que ha determinado la hístora y la cultura de la ciudad.</p>",
        cardImg: sanLorenzo,
        img: lorenzo,
        content: "<p>Descubriremos la história de una de las familias más famosas de Europa. Conoceremos a los personajes y las respectivas azañas que han marcado el destino de Florencia y de la Toscana a través de un recorrido por los lugares más emblemáticos de la ciudad, como el Complejo de San Lorenzo, Palacio Medici Riccardi, Palacio Vecchio y Palacio Pitti.<br/><br/>El tour está previsto con una duración de 3 o 6 horas, con o sin visitas al interior de los monumentos. <br/><br/>Medio día (3h): Palacio Medici Riccardi, il Complesso di San Lorenzo<br/><br/>Día entero (6h + descanso para comer): programa del medio día + Palacio Vecchio y Palacio Pitti.</p>",
        start: 'San Lorenzo',
        end:'Palacio Vecchio (3h) o Palacio Pitti (6h)',
        points: 'San Lorenzo, Palacio Medici, Palacio Vecchio',
        duration: 'medio día (3h) o día entero (6h + descanso para comer)',
        languages: 'En, Fr, Es, De, Ar'
    },
]

export const toursDe = [
    {
        slug: 'curiosità_fiorentine',
        name: 'Florentiner Kuriositäten',
        card: '<p>Diese Tour ist jür jeden geeignet, der auf die ganz besonderen Anekdoten von Florenz neugierig ist, die auch vielen Florentinern unbekannt sind.<br/> Wir spazieren durch Florenz und folgen den Legenden und Kuriositäten der Stadt. Dabei entschlüsseln wir Symbole und Zeichen, die sowohl auf dramatische wie komische Anekdoten verweisen. </p>',
        cardImg: leone,
        img: ritratto,
        content: "<p>In direkter Nähe des Doms, der Piazza della Signoria, in der Umgebung von Santa Maria Novella oder Santa Croce entziffern wir Zeichen und Symbole, die sowohl auf dramatische wie komische Anekdoten verweisen. <br/><br/> Wir entdecken versteckte Gesichter, Selbstportäts von bekannten Künstlern, Gespenster oder kleine Unterhalter des Hofes. Hinweise auf Überschwemmungen, die die Stadt zerstört haben, und Orte der Zuflucht für allein gelassene Kinder sind ebenfalls teil unserer Spaziergänge. Wir sprechen über das Mißgeschick der goldenen Kugel des Doms, über die sog. Weinlöcher und die Löwen, die als stolzes Smybol von Florenz omnipräsent sind. <br/><br/> Diese Tour eignet sich für Gäste, die Florenz bereits kennen oder die sich weniger für historische Details intressieren, sondern für Anekdoten und Kuriositäten. Die Erfahrung hat gezeigt, dass diese Tour auch besonders für Kinder geeignet ist.</p>",
        start: 'Palazzo Vecchio',
        end:'Dom',
        points: 'Palazzo Vecchio, il Duomo, Vicoli e strade del Centro Storico di Firenze',
        duration: 'Halber Tag (3 Stunden)',
        languages: 'En, Fr, Es, De, (Arabisch nur für Touristen)'
    },
    {
        slug: "firenze_classica",
        name: "Klassischer Stadtrundgang",
        card: "<p><strong>Ein Spaziergang durch die Altstad, vorbei an den bekanntesten Sehenswürdigkeiten.</strong> <br/>Piazza della Repubblica, Ponte Vecchio, Piazza della Signoria, Palazzo Vecchio und der Domkomplex von Santa Maria del Fiore. Wir begegnen weltbrühmten Persönlichkeiten wie Michelangelo, Brunelleschi und die Familie Medici.</p>",
        cardImg: ponteVecchio,
        img: firenzeClassica,
        content: "<p>Auf unserem Spaziergang durch die Altstadt von Florenz begegnen wir nicht nur einigen der berühmtesten Bauten der Welt wie dem Florentiner Dom und Brunelleschis Kuppel, sondern auch Persönlichkeiten wie Arnolfo di Cambio, Leonardo da Vinci, Michelangelo oder der Medici Familie. <br/><br/>Die Geschichte von Florenz beginnt auf der Piazza della Repubblica von wo aus wir die Piazza della Signoria erreichen, das vielleicht schönste open-air Skulpturenmuseum der Welt. Dort finden wir u.a. eine der bekanntesten Bronezstatuen Benvenuto Cellinis, ein Wunder in Mamor von Giambologna und eine Kopie von Michelangelos David. Von dort spazieren wir zum Ponte Vecchio mit seinem Blick auf den Arno, die Uffizien und den Piazzale Michelangelo. Auf dem Weg zur Piazza Santa Croce entdecken wir den Ort, wo das römische Amphitheater stand, das Haus, in dem der junge Michelangelo wohnte und der Platz, an dem der älteste Stadtpalast von Florenz steht. Auf dem Domplatz sehen wir das Baptisterium, älteste noch stehende Gebäude von Florenz, und wir erfahren mehr über Brunelleschis Bau der Domkuppel. Mit dem Blick auf den Medicipalast und er Geschichte der Medici runden wir unseren Stadtspaziergang ab.<br/><br/> Es besteht die Möglichkeit, einen Besuch der Accademia mit einzubeziehen, um Michelangelos David zu besichtigen.</p>",
        start: 'Piazza della Repubblica',
        end:'Palazzo Medici Riccardi',
        points: 'Piazza della Repubblica, Palazzo Vecchio, Ponte Vecchio, Uffizi, Santa Croce, Dom, Palazzo Medici Riccardi',
        duration: 'Halber Tag (3 Stunden) + (1 Stunde Accademia)',
        languages: 'En, Fr, Es, De, (Arabisch nur für Touristen)'
    },
    {
        slug: "firenze_panoramica",
        name: "Florenz von oben",
        card: "<p>Ein Spaziergang, um Florenz von oben zu bestaunen.  Panoramablicke und Bewegung!</p>",
        cardImg: panoramica,
        img: rampe,
        content: "<p>Ein Spaziergang, um Florenz von oben zu bestaunen.  Panoramablicke und Bewegung!<br/><br/> Wir beginnen unsere Tour in der Piazza Poggi mit dem mittelalterlichen Stadttor von San Niccolò. Ein Weg schlängelt sich an Brunnen und Grotten entlang bis auf die Aussichtsterrasse des Piazzale Michelangelo. Von dort bewundern wir all die Sehenswürdigkeiten von Florenz von oben, ein einzigartiges Schauspiel! Weiter geht es hinauf zu San Miniato al Monte, einer der ältesten und schönsten Kirche der Stadt. Im Innern bewundern wir mittelalterliche Mosaiken und Fresken. Auf dem Rückweg ins Stadtzentrum bewundern wir den Rosengarten und einige Skulpturen des belgischen Künstlers Folon. Durch das mittelalterliche Stadttor San Miniato mit seinen Holzportalen kehren wir zurück ins Stadtzentrum und schlendern durch die Via San Niccolò mit seinen kleinen Geschäften und Galerien. Von dort erreichen wir die Piazza dei Mozzi, von wo wir aus den Arno erreichen.  Wir beschließen unsere Tour mit einem Spaziergang entlang des Arno, bis wir unseren Ausgangspunkt wieder erreicht haben. </p>",
        start: 'Piazza Poggi',
        end:'Piazza Poggi',
        points: 'Porta San Niccolò, Piazzale Michelangelo, Giardino delle Rose',
        duration: 'Halber Tag (3 Stunden)',
        languages: 'En, Fr, Es, De, (Arabisch nur für Touristen)'
    },
    {
        slug: "centro_religioso",
        name: "Der Dombezirk",
        card: "<p>Wir besichtigen die Gebäude, die Teil des Dombezirke sind: das Baptisterium, der Dom Santa Maria del Fiore mit der Kuppel Brunelleschis, Giottos Glockentum und das neue Dommuseum.</p>",
        cardImg: battistero,
        img: campanile,
        content: "<p>Wir beginnen mit dem Besuch des Baptisteriums, dem ältesten, bis heute intakt gebliebenen Gebäude der Stadt, und bewundern seine wunderschönen mittelalterlichen Mosaiken im Innern. Von dort geht es zur gegenüber liegenden Kahtedrale. Der Architekt Arnolfo di Cambio entwarf eine der größten Kirchen der Christenheit. Sie wird bekrönt von Brunelleschis berühmter Kuppel, deren Geschichte wir erfahren und die Sie besteigen können. Die Tour endet mit dem Besuch des Dommuseums, wo wir die originalen Bronzetüren von Andrea Pisano und Lorenzo Ghiberti besichtigen sowie Originalstatuen der Fassaden und de Glockenturms der Kathedrale. Bewundern Sie auch die eindrucksvolle Maria Magdalena von Donatello und die vielleicht intimste Skulptur Michelangelos, die sog. Bandini-Pietà.</p>",
        start: 'Opera del Duomo, Piazza S. Giovanni',
        end:'Dommuseum',
        points: 'I mosaici del Battistero, la Porta del Paradiso, le sculture di Donatello, la Cupola di Brunelleschi',
        duration: 'Halber Tag (3 Stunden)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "galleria_uffizi",
        name: "Die Uffizien",
        card: "<p>Wir besichtigen das berühmteste Museum von Florenz und eines der wichtigsten der Welt. Bewundern Sie einige der berühmtesten Kunstwerke der italienischen Renaissance.</p>",
        cardImg: uffizi,
        img: uffizi2,
        content: "<p>Der Besuch der Uffizien, die von Giorgio Vasari im 16. Jahrunder für die Medici errichtet wurden und das Werke von der Antike bis ins 18. Jahrhundert ausstellt, ist einer der Höhepunkte für jeden Kunstliebhaber. Lassen Sie sich in die Geschichte eines der berühmtesten Museen der Welt einführen und bewundern Sie die Highlights der Sammlung: Meisterwerke von Giotto, Masaccio, Filippo Lippi, Botticelli, Leonardo, Michelangelo, Raphael, Caravaggio und vielen anderen Repräsentanten der italienischen Kunstgeschichte. Antike Skulpturen begleiten uns auf unseree Besichtigung.</p>",
        start: 'Eingang Uffizien, Piazzale degli Uffizi',
        end:'Ausgang Uffizien',
        points: 'Botticellis Frühling, Leonardos Verkündigung, Michelangelos Doni Tondo, Caravaggios Medusao…',
        duration: 'Halber Tag (3 Stunden)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "palazzi_potere",
        name: "Berühmte Florentiner Paläste",
        card: "<p>Lernen Sie einige der wichtigsten und schönsten Paläste der Stadt kennnen. Entdecken Sie die architektonischen Veränderungen, die mit persönlichen Ambitionen reicher Familien und Machtspielen einher gingen. <br/>Einige Beispiele: Palazzo Vecchio; Bargello; Palazzo Pitti; Palazzo Medici Riccardi; Palazzo Spini Feroni und ande</p>",
        cardImg: spiniFerroni,
        img: palazzoVecchio,
        content: "<p>Wir besuchen die Florentiner Paläste, die vom 13. Jahrhundert bis heute vor allem aus historischer und politischer Sicht interessant sind.  <br/><br/>Torre della Castagna: mittelalterlicher Turm, der bei den politischen Wahlen der Republik Florenz eine ganz  besondere Rolle spielte.<br/><br/>Palazzo Vecchio oder Palazzo della Signoria: der Stadtpalast, der seit dem 13. Jahrhundert bis heute als Regierungssitz fungiert <br/><br/>Palazzo Pitti: Residenz der Medici, der Großherzöge der Toskana<br/><br/>Palazzo Spini Feroni:  mittelalterlicher Palast der Bankiersfamilie Spini</p>",
        start: 'Palazzo Medici Riccardi',
        end:'Piazza Santa Trinita',
        points: 'Palazzo Vecchio, il Bargello, Palazzo Pitti, Palazzo Medici Riccardi, Palazzo Spini Feroni, Torre della Castagna',
        duration: 'mezza giornata (3h)',
        languages: 'En, Fr, Es, De, (Arabisch nur für Touristen)'
    },
    {
        slug: "michelangelo",
        name: "Durch Florenz mit Michelangelo",
        card: "<p>Auf der Entdeckung der Orte, an denen Michelangelo gewirkt hat, und der Meisterwerke, die er Florenz hinterlassen hat.</p>",
        cardImg: david,
        img: michelangelo,
        content: "<p>Halber Tag (3 Stunden): <br/><br/>Michelangelo hat in Florenz nach Rom die größte Anzahl seiner Meisterwerke hinterlassen. Besichtigen Sie einige Glanzstücke seines Frühwerkes mit dem in der Casa Buonarroti und seinem Bacchus im Bargello Museum bevor wir in der Accademia sein berühmtestes Werk betrachten, den David.  <br/><br/>Ganzer Tag (6 Stunden mit Mittagspause): <br/><br/>Wer sich für einen ganzen Tag auf den Spuren Michelangelos entscheidet, kann den Künstler nicht nur Bildhauer sondern auch als Architekt in den Medici Kapellen bewundern sowie in der faszinierenden Bibliothek Laurenziana. </p>",
        start: 'Museum Casa Buonarroti',
        end:'Accademia (3 Stunden), Kirche von San Lorenzo (6 Stunden)',
        points: 'Casa Buonarroti, Bargello, Accandemia, Medici Kapellen, Bibliothek Laurenziana',
        duration: 'Halber Tag (3 Stunden) oder ganzer Tag (6 Stunden mit Mittagspause)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "smn_santa_croce",
        name: "Santa Maria Novella und Santa Croce",
        card: "<p>Zu den berühmtesten Sehenswürdigkeiten der Stadt gehören die beiden Bettelordenskirchen Santa Maria Novella und Santa Croce.</p>",
        cardImg: santaCroce,
        img: smn,
        content: "<p>Die Dominikaner begannen die Geschichte der Bettelorden in Florenz. Die Geschichte Santa Maria Novellas ist ebenso faszinierend wie einige der weltberühmten Kunstwerke, die wir in der Kirche zu besichtigen, wie Masaccios Trinitätsfresko, Brunelleschis Kruzifix oder den Freskenzyklus von Domenico Ghirlandaio. <br/><br/> Santa Croce, oder das „Florentiner Pantheon“ ist die größte Franziskanerkirche der Welt. Sie bietet die Möglichkeit, die Gräber Galileo Galileis, Macchiavellis oder Michelangelos zu besichtigen sowie die herausragenden Freskos Giottos und seiner Nachfolger oder ein Spätwerk Filippo Brunelleschis, die Pazzi Kapelle.</p>",
        start: 'Piazza Santa Maria Novella',
        end:'Piazza Santa Croce',
        points: 'Fresken von Masaccio und Ghirlandaio, Kruzifix Brunelleschi,  Fresken GIottos, Grabmäler Galileis und Michelangelos, Brunelleschis Pazzi Kapelle',
        duration: 'Halber Tag (3 Stunden) oder ganzer Tag (6 Stunden mit Mittagspause)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "museo_galileo",
        name: "Florenz und die Wissenschaften: Das Galileo Museum",
        card: "<p>Dieses Museum besitzt eine der größten Sammlungen der Welt an historischen wissenschaftlichen Instrumenten. Besonders interessant sind diejenigen, die Galileo Galilei gehörten und Teil der Medici-Sammlungen waren.</p>",
        cardImg: galileo,
        img: museoGalileo,
        content: "<p>Das Florentiner Galileo Museum bietet hervorragende Möglichkeiten, didaktisch aufzuarbeiten und zu erklären, wie Menschen über die Jahrhunderte hinweg den Kosmos wahrgenommen haben, das Universum, das die Welt umgibt einschließlich der Planeten und Sterne. Der Einfallsreichtum und Eifer, mit denen Forscher inklusiv Galileos dazu beigetragen haben, unsere Welt besser zu verstehen, unterschiedliche Methoden zu entwickeln, mit denen Zeit und die Bewegung der Planeten gemessen werden konnte, treten in diesem Museum eindrucksvoll vor Augen. Diese Tour wird in Absprache mit den Lehrkräften vorbereitet, um den Schülern größtmögliches Verständnis zu ermöglichen. </p>",
        start: 'Museumseingang',
        end:'Museumseingang',
        points: 'Gli strumenti scientifici, in particolare quelli di Galileo',
        duration: 'Halber Tag (3 Stunden)',
        languages: 'En, Fr'
    },
    {
        slug: "medici",
        name: "Die Medici",
        card: "<p>Die berühmteste Familie in Florenz, die die Geschichte und Kultur Stadt maβgeblich beinflusst hat.</p>",
        cardImg: sanLorenzo,
        img: lorenzo,
        content: "<p>Entdecken Sie die Geschichte einer der berühmtesten Familien Europas. Lernen Sie die wichtigsten Familienmitglieder der Medici kennen, die über Jahrhunderte die Geschicke der Stadt Florenz und der Toskana gelenkt haben, und bestaunen Sie Sehenswürdigkeiten wie den Komplex der Kirche San Lorenzo, den Palazzo Medici, den Palazzo Vecchio sowie den Palazzo Pitti.<br/><br/>Die Tour dauert 3 bzw. 6 Stunden.<br/><br/>Halber Tag (3 Stunden): Palazzo Medici, Komplex der Kirche von San Lorenzo<br/><br/>Ganzer Tag (6 Stunden mit Mittagspause): Programm des Vormittags + Palazzo Vecchio und Palazzo Pitti (von auβen) </p>",
        start: 'San Lorenzo',
        end:' Palazzo Vecchio (3 Stunden) o Palazzo Pitti (6 Stunden)',
        points: 'San Lorenzo, Palazzo Medici, Palazzo Vecchio',
        duration: 'Halber Tag (3 Stunden) oder ganzer Tag (6 Stunden mit Mittagspause)',
        languages: 'En, Fr, Es, De, Ar'
    },
]

export const toursFr = [
    {
        slug: 'curiosità_fiorentine',
        name: 'Curiosités florentines',
        card: '<p><strong>Un tour adapté aux enfants et aux curieux de tous âges.</strong> Une promenade au centre de Florence en suivant les traces de légendes fascinantes et de faits curieux.  Nous découvrirons ensemble signes et symboles qui renvoient à des épisodes dramatiques ou amusants de l’histoire de Florence que ses habitants eux mêmes souvent ne connaissent pas.</p>',
        cardImg: leone,
        img: ritratto,
        content: "<p>Tout près de sites d’intérêt général tels que la Cathédrale, ou Duomo, la Place de la Signoria, les églises de Santa Croce ou de Santa Maria Novella, nous irons déchiffrer signes et symboles qui renvoient à des épisodes dramatiques ou amusants de l’histoire de Florence.<br/><br/> Nous irons à la découverte de visages cachés et d’autoportraits d’artistes célèbres.  Nous ferons la connaissance de fantômes mystérieux et de petits personnages à la cour ducale.  Nous verrons jusques où sont arriveées les inondations qui ont dévasté la ville, ainsi qu’une fenêtre qui donne espoir aux enfants abandonnés.  Nous réfléchirons sur les mésaventures d’une boule dorée.  Nous irons à la découverte des fenêtres à vin et des lions de Florence, symboles de la fierté de ses habitants. <br/><br/> Ce tour convient aussi bien à ceux qui connaissent déjà Florence qu’à ceux qui sont moins intéressés aux reconstructions historiques qu’aux anecdotes et aux détails cachés.  L’expérience nous indique que ce tour plaît aux familles avec des enfants.</p>",
        start: 'de Palazzo Vecchio',
        end:'la Cathédrale',
        points: 'Palazzo Vecchio, le Duomo, les rues et ruelles du centre historique de Florence. ',
        duration: 'une demi-journée (3 h)',
        languages: 'En, Fr, Es, De, (Arabe seulement pour tour privés)'
    },
    {
        slug: "firenze_classica",
        name: "Florence classique—un tour pour tout le monde.",
        card: "<p><strong>Nous irons à la découverte de Florence et de son histoire.</strong> <br/>Notre promenade nous conduira à travers les lieux les plus importants et fascinants du centre historique de la ville, tels Piazza della Repubblica, le Ponte Vecchio, Piazza della Signoria, ainsi que l’ ensemble monumental de la Cathédrale de Santa Maria del Fiore (Duomo).  Le long du parcours, nous rencontrerons des personnages de renommée mondiale tels Michel-Ange, Brunelleschi, e plusieurs membres de la famille Médicis.</p>",
        cardImg: ponteVecchio,
        img: firenzeClassica,
        content: "<p>Au cours de notre promenade dans le centre historique de Florence nous allons non seulement admirer certains des plus célèbres édifices au monde – la cathédrale ou Duomo de Florence avec son dôme par Brunelleschi, mais aussi faire la connaissance de personnages historiques tels qu’Arnolfo di Cambio, Léonard de Vinci, Michel-Ange, et plusieurs membres de la famille des Médicis.<br/><br/>De Piazza della Repubblica, où commence l’histoire de Florence, nous rejoindrons Piazza della Signoria, peut-être le plus beau musée de sculpture en plein-air au monde.  Là nous admirerons, entre autres, une des statues en bronze les plus célèbres de Benvenuto Cellini, un miracle en marbre de Giambologna (Jean de Boulogne) et une fameuse copie du David de Michel-Ange.  De là nous poursuivrons notre chemin en traversant la cour de la Galerie des Offices pour aboutir au Ponte Vecchio sur le fleuve Arno d’où nous pourrons admirer la vue vers le Piazzale Michelangelo, sur la colline de San Miniato.  En marchant vers Piazza Santa Croce, nous verrons le site de l’amphithéâtre romain, la maison où habitait le jeune Michel-Ange et le plus ancien hôtel privé de Florence.  De retour à la place de la cathédrale, nous verrons le baptistère, le plus ancien édifice qui soit toujours debout à Florence et nous admirerons le génie de Brunelleschi qui sut élever le vaste dôme qui complète la cathédrale.  Nous terminerons notre promenade au palais des Médicis.<br/><br/> Il existe la possibilité de prolonger ce tour par une visite à la galerie de l’Accademia pour admirer le David de Michel-Ange.</p>",
        start: 'Piazza della Repubblica',
        end:'Palais Medici-Riccardi (ou bien l’Accademia lors d’un prolongement)',
        points: 'Piazza della Repubblica, Palazzo Vecchio, Ponte Vecchio, la cour des Offices, Santa Croce, la Cathédrale ou Duomo, le Palais des Médicis ',
        duration: 'une demi-journée (3 h), avec la possibilité d’ajouter une heure pour une visite à l’Accademia',
        languages: 'En, Fr, Es, De, (Arabe seulement pour tour privés)'
    },
    {
        slug: "firenze_panoramica",
        name: "Florence panoramique",
        card: "<p>Circuit à pied pour admirer Florence d’en haut.  Voici la meilleure façon pour apprécier une vue merveilleuse sur la ville dans toute sa splendeur… et pour se maintenir en forme!</p>",
        cardImg: panoramica,
        img: rampe,
        content: "<p>Un circuit à pied pour admirer Florence vue d’en haut.  C’est la meilleure façon d’apprécier la vue merveilleuse de Florence dans toute sa splendeur… et pour se maintenir en forme! <br/><br/> Ce tour commence à Piazza Poggi, près de l’ancienne porte de San Niccolò.  De là nous monterons des rampes d’escalier bordées de cascades et de belles fontaines jusqu’au Piazzale Michelangelo d’où, nous nous amuserons à reconnaître les monuments de la ville qui s’étend à nos pieds… un spectacle unique!  Si les conditions le permettent, nous monterons jusqu’à l’ancienne église de San Miniato al Monte, une des plus belles de Florence.  En redescendant vers le centre-ville, nous traverserons le Jardin des Roses avec ses sympathiques statues de l’artiste belge Folon.  Après une agréable et brève descente nous rentrerons en ville par la Porte de San Miniato et nous parcourrons via San Niccolò où nous pourrons flâner parmi ses boutiques et ateliers d’artisans.  Nous conclurons ce tour en retournant le long de l’Arno vers notre point de départ - ce qui nous permettra d’admirer la ville vue d’en bas.</p>",
        start: 'Piazza Poggi',
        end:'Piazza Poggi',
        points: 'Porta San Niccolò, Piazzale Michelangelo,(San Miniato), le Jardin des Roses.',
        duration: 'une demi-journée (3 h)',
        languages: 'En, Fr, Es, De, (Arabe seulement pour tour privés)'
    },
    {
        slug: "centro_religioso",
        name: "Le grand ensemble de la cathédrale",
        card: "<p>L’ensemble monumental de la cathédrale de Florence</p>",
        cardImg: battistero,
        img: campanile,
        content: "<p>Nous commencerons ce tour par une visite au Baptistère, le plus ancien édifice de Florence qui soit toujours debout, et nous admirerons ses merveilleuses mosaïques médiévales.  En concevant la construction de la nouvelle cathédrale à la fin du treizième siècle, Arnolfo di Cambio changea le visage de la ville, créant la quatrième plus grande église de la Chrétienté, couronnée par la suite par le fameux dôme de Filippo Brunelleschi, le grand architecte dont nous discuterons le génie.  Ce tour se conclut par une visite au Musée de la Cathédrale où nous verrons les portes en bronze originales du Baptistère réalisées par Andrea Pisano et Lorenzo Ghiberti, ainsi que les statues de la première façade de la cathédrale et de son campanile.  Nous pourrons aussi admirer la sculpture de Marie-Madeleine de Donatello, des instruments et modèles de l’époque utilisés pour la construction du dôme, et une des dernières œuvres de Michel-Ange, l’émouvante Pietà Bandini.</p>",
        start: 'Piazza San Giovanni',
        end:'Museo dell’Opera del Duomo',
        points: 'I mosaici del Battistero, la Porta del Paradiso, le sculture di Donatello, la Cupola di Brunelleschi',
        duration: 'une demi-journée (3 h)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "galleria_uffizi",
        name: "La Galerie des Offices",
        card: "<p>Songez à faire une visite au musée le plus célèbre d’Italie et parmi les plus importants au monde.  Il contient la plus grande collection de peintures de la Renaissance italienne ainsi qu’une merveilleuse collection de sculptures antiques et une collection d’autoportraits d’artistes de renom.  Ce tour offre un parcours d’histoire de l’art à la fois fascinant et surprenant.</p>",
        cardImg: uffizi,
        img: uffizi2,
        content: "<p>A’ l’intérieur du vaste bâtiment des Offices, érigé au XVIème siècle par le grand architecte Giorgio Vasari pour Cosme Ier de Médicis, nous trouverons de grands chefs-d’œuvre de la Renaissance italienne.  En suivant un parcours chronologique qui part du Moyen Âge et arrive au XVIIème siècle, nous pourrons admirer des œuvres de Giotto, Masaccio, Filippo Lippi, Botticelli, Léonard de Vinci, Michel-Ange, Raphaël, Titien, Caravage, parmi tant d’autres.  La magnifique collection de sculptures antiques nous accompagnera tout le long de notre parcours.<br/><br/>Vous pourrez découvrir les nouveautés d’un musée qui ne cesse de changer:  les Nouveaux Offices vous surprendront!</p>",
        start: 'Entrée du Musée des Offices dans la cour des Offices',
        end:'Sortie du musée (Piazza del Grano)',
        points: 'La Primavera di Botticelli, l’Annunciazione di Leonardo, il Tondo Doni di Michelangelo, la Medusa di Caravaggio…',
        duration: 'une demi-journée (3 h)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "palazzi_potere",
        name: "Les Palais du Pouvoir",
        card: "<p>Nous irons connaitre les palais où s’est exercé le pouvoir à Florence et leur histoire - des origines jusqu’à nos jours -, ainsi que l’évolution qu’ils ont subie au cours du temps, aussi bien dans leur architecture que dans leur utilisation.</p>",
        cardImg: spiniFerroni,
        img: palazzoVecchio,
        content: "<p>Nous irons explorer de l’extérieur les palais qui ont eu la plus grande importance historique et politique pour Florence.  Nous verrons de quelle façon leur utilisation a changé depuis les origines jusqu’à nos jours. <br/><br/>La Tour de la Châtaigne (della Castagna) :  Tour médiévale qui fut le premier lieu de réunion du Conseil des Prieurs (priori) qui gouvernait Florence. <br/><br/>Palazzo Vecchio, ou della Signoria (d’après le nom donné au Conseil des Prieurs):  Ce palais symbolise le gouvernement de Florence de 1299 à aujourd’hui. <br/><br/>Le Palais Pitti:  la résidence des grands-ducs de Toscane.<br/><br/>Le Palais des Médicis (ensuite Medici-Riccardi) fut le premier palais de la famille des Médicis. <br/><br/>Le Bargello :  construit vers 1264 pour le Capitaine du Peuple, il passa ensuite au podestat, au Bargello (chef de la garde des rues) pour devenir une prison pour les condamnés à mort et un lieu de torture.</p>",
        start: 'Palazzo Medici Riccardi',
        end:'Palazzo Pitti',
        points: 'Palazzo Vecchio, il Bargello, Palazzo Pitti, Palazzo Medici Riccardi, Palazzo Spini Feroni, Torre della Castagna',
        duration: 'une demi-journée (3 h)',
        languages: 'En, Fr, Es, De, (Arabe seulement pour tour privés)'
    },
    {
        slug: "michelangelo",
        name: "Sur les pas de Michel-Ange à Florence",
        card: "<p>Ce tour vous conduit à la découverte des lieux et des œuvres les plus importantes de Michel-Ange à Florence.</p>",
        cardImg: david,
        img: michelangelo,
        content: "<p>Demi-journée (3 heures):<br/><br/>Michel-Ange nous a laissé la majorité de ses œuvres entre Florence et Rome.  Nous commencerons par découvrir ses œuvres de jeunesse à la Casa Buonarroti, puis nous irons au Musée du Bargello comparer son Bacchus à d’autres chefs d’œuvres de sculpture.  De là, nous irons admirer son œuvre la plus célèbre, le David, au musée de l’Accademia.  Nous aurons aussi le temps de repasser les étapes principales de la vie de Michel-Ange et d’en découvrir certains aspects moins connus. <br/><br/>Journée entière:  6 heures + pause déjeuner: <br/><br/>Le matin, nous suivrons le programme de la demi-journée; l’après-midi, nous nous consacrerons au Michel-Ange architecte et sculpteur, aux Chapelles des Médicis et dans la fascinante Bibliothèque laurentienne.</p>",
        start: 'Musée de Casa Buonarroti',
        end:'Galerie de l’Académie (Galleria dell’Accademia) (3h), l’ensemble monumental de Saint Laurent (6h)',
        points: 'Casa Buonarroti, le Bargello, la Galerie de l’Académie, les chapelles des Médicis, la Bibliothèque laurentienne',
        duration: 'une demi-journée (3 h) ou une journée entière (6 h + pause déjeuner)',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "smn_santa_croce",
        name: "Sainte Marie Novelle et Sainte Croix",
        card: "<p>Ces deux églises d’ordres mendiants, Santa Maria Novella des dominicains et Santa Croce des franciscains, sont parmi les lieux les plus fameux de la ville.  Chacune église possède œuvres d’art et monuments parmi les plus importants de Florence.</p>",
        cardImg: santaCroce,
        img: smn,
        content: "<p>C’est ici que commence l’histoire de l’ordre mendiant des Dominicains à Florence.  Cette histoire est tout aussi fascinante que celle des œuvres de renommée mondiale que nous pouvons visiter à l’intérieur tels le fresque de la Trinité de Masaccio, la croix en bois de Giotto, le Crucifix de Brunelleschi ou le cycle de fresques de Ghirlandaio. <br/><br/> Santa Croce, le «Panthéon Florentin» est la plus grande église franciscaine au monde.  Elle contient les tombeaux de Michel Ange, Galilée et de Machiavel ainsi que des fresques de Giotto et de ses successeurs, et parmi les derniers ouvrages de Brunelleschi, la Capelle des Pazzi.</p>",
        start: 'Piazza Santa Maria Novella',
        end:'Piazza Santa Croce',
        points: 'Fresques de Masaccio e de Ghirlandaio, crucifix de Brunelleschi à Santa Maria Novella ; fresques de Giotto, tombeaux de Michel Ange et de Galilée, la chapelle des Pazzi de Brunelleschi',
        duration: 'une journée entière (6h + pause déjeuner) ou bien une demi-journée (3h) pour une église et ses alentours.',
        languages: 'En, Fr, Es, De, Ar'
    },
    {
        slug: "museo_galileo",
        name: "Florence et la Science au Musée Galilée",
        card: "<p>Ce musée contient une des plus importantes collections d’instruments scientifiques historiques au monde, en particulier ceux ayant appartenu à Galilée, ainsi que les intéressantes collections formées sous les Médicis et la maison de Lorraine.</p>",
        cardImg: galileo,
        img: museoGalileo,
        content: "<p>Grâce au Musée Galilée, Florence bénéficie d’une extraordinaire ressource pédagogique pour découvrir comment, au cours des siècles, on s’imaginait le cosmos—l’univers qui entoure la terre avec les planètes et les étoiles.  C’est là que l’on pourra voir et apprécier les idées pratiques de nombreux esprit qui ont fait progresser notre compréhension du monde, Galilée inclus, et admirer des objets construits à Florence et ailleurs pour mesurer le temps et représenter les mouvements planétaires dans le cosmos.  Le parcours exact de chaque visite sera décidé en collaboration avec les professeurs pour consentir aux élèves d’en profiter le mieux possible. </p>",
        start: 'Entrée du musée Galilée (Piazza dei Giudici)',
        end:'Sortie du musée Galilée (Piazza dei Giudici)',
        points: 'Les instruments scientifiques, ceux de Galilée en particulier.',
        duration: 'une demi-journée (3 h)',
        languages: 'En, Fr'
    },
    {
        slug: "medici",
        name: "Les Médicis",
        card: "<p>L’histoire de la famille florentine la plus célèbre  dont l’influence a déterminé l’histoire et la culture de la ville.</p>",
        cardImg: sanLorenzo,
        img: lorenzo,
        content: "<p>Durée: une demi-journée (3 heures):<br/><br/>Nous irons à la découverte de l’histoire d’une des plus fameuses familles d’Europe.  Après avoir visité l’église de San Lorenzo/Saint Laurent, édifiée par Brunelleschi et par la suite utilisée pour les funérailles de la famille Médicis, nous verrons comment le Palais des Médicis, bâti presque en même temps que San Lorenzo, fut une nouveauté de premier ordre à Florence.  <br/><br/> Durée: journée entière (6 heures + pause déjeuner) <br/><br/>Outre les visites décrites ci-dessus qui se dérouleront le matin, l’après-midi sera consacré à la visite de Palazzo Vecchio, le symbole des changements radicaux qu’imposèrent les Médicis lorsqu’ils devinrent Ducs de Florence.  Nous finirons notre tour oltrarno (la rive gauche de l’Arno) au Palais Pitti, la dernière résidence des grands-ducs Médicis, ainsi que celle de tous leurs successeurs Habsbourg-Lorraine.</p>",
        start: 'Piazza San Lorenzo',
        end:'Piazza San Lorenzo (3 heures) ou Palais Pitti (6 heures)',
        points: 'San Lorenzo, Palais Médicis, Palazzo Vecchio, Palais Pitti.',
        duration: 'une demi-journée (3 h) ou une journée entière (6 h + pause déjeuner)',
        languages: 'En, Fr, Es, De, Ar'
    },
]

export const homePageIdx = [0, 1, 2, 5]
export const schoolsIdx = [0, 1]
export const privatesIdx = [6, 7]