import { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Contacts from './pages/Contacts';
import Guides from './pages/Guides';
import HomePage from './pages/HomePage';
import Services from './pages/Services';
import Tours from './pages/Tours';
import Tour from './pages/Tour';
import ScrollToTopUI from './components/ScrollToTopUI'
import ScrollToTop from './components/ScrollToTop';

import { toursEn, toursIt, toursEs, toursDe, toursFr } from './content/tours/tours'
import { blurpsEn, blurpsIt, blurpsEs, blurpsDe, blurpsFr } from './content/various/blurps'
import { tourPageEn, tourPageIt, tourPageEs, tourPageDe, tourPageFr } from './content/tour/tourPage'
import { buttonsEn, buttonsIt, buttonsEs, buttonsDe, buttonsFr } from './content/various/buttons'


const LanguageRedirection = props => {
  const { language, setLanguage } = props;
  useEffect(() => {
    const lang = window.navigator.language.slice(0, 2)
    const permittedLang = ['en', 'fr', 'es', 'de', 'it', 'ar'];

    if (permittedLang.includes(lang)) {
      setLanguage(lang)
    } else {
      setLanguage('en')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Redirect to={`/${language}`} />
    </>
  )
}

function App() {
  const history = useHistory()
  
  const [language, setLanguage] = useState('en')
  const [tours, setTours] = useState([])
  const [blurps, setBlurps] = useState({})
  const [content, setContent] = useState({})
  const [buttons, setButtons] = useState({})

  useEffect(() => {
    switch (history.location.pathname.slice(1, 3)) {
      case 'it':
        setLanguage('it')
        break
      case 'es':
        setLanguage('es')
        break
      case 'de':
        setLanguage('de')
        break
      case 'fr':
        setLanguage('fr')
        break
      default:
        setLanguage('en')
        break
    }
    switch (language) {
      case 'it':
        setBlurps(blurpsIt)
        setContent(tourPageIt)
        setButtons(buttonsIt)
        setTours(toursIt)
        break
      case 'es':
        setBlurps(blurpsEs)
        setContent(tourPageEs)
        setButtons(buttonsEs)
        setTours(toursEs)
        break
      case 'de':
        setBlurps(blurpsDe)
        setContent(tourPageDe)
        setButtons(buttonsDe)
        setTours(toursDe)
        break
      case 'fr':
        setBlurps(blurpsFr)
        setContent(tourPageFr)
        setButtons(buttonsFr)
        setTours(toursFr)
        break
      default:
        setBlurps(blurpsEn)
        setContent(tourPageEn)
        setButtons(buttonsEn)
        setTours(toursEn)
        break
    }
  }, [language, history.location.pathname])

  return (
    <div className="App">
      <NavBar language={language} setLanguage={setLanguage} />
      <ScrollToTop history={history} />
      <Switch>
        <Route exact path='/' render={props => <LanguageRedirection {...props} language={language} setLanguage={setLanguage} />} />
        <Route exact path='/:language' render={props => <HomePage {...props} tours={tours} />} />
        <Route path='/:language/services' render={props => <Services {...props} tours={tours} />} />
        <Route path='/:language/tours' render={props => <Tours {...props} tours={tours} />} />
        <Route path='/:language/guides' render={props => <Guides {...props} />} />
        <Route path='/:language/contacts' render={props => <Contacts {...props} />} />
        {tours.map(tour => <Route path={`/:langauage/${tour.slug}`} key={uuidv4} render={props => <Tour {...props} tour={tour} blurps={blurps} content={content} buttons={buttons} />} />)}
        <Route path='/:language/tour' render={props => <Tour {...props} />} />
      </Switch>
      <Footer language={language} />

      <ScrollToTopUI />
    </div>
  );
}

export default App;
