export const contactsEn = {
    title: 'Book your tour now',
    description: "<p>Please fill in this form specifying tour, date and time, language, the number of participants, and if the tour is for a school group or a private tour.</p><p>We offer a greater range of tours than are listed on our website.  Contact us for more information.</p><p>We'll get back to you as soon as we can!</p>",
    name: 'First Name',
    surname: 'Last Name',
    message: 'Your Message',
    button: 'Contact us',
    disclaimer: 'We ask for your name and email to book your tour.  <strong>Under no circumstance do we log or share your data with third parties</strong>'
}

export const contactsIt = {
    title: 'Prenota ora il tuo tour',
    description: "<p>Per favore compila il form qua sotto specificando il tour, la data e l'ora, la lingua, il numero di persone nel gruppo, e se sei intenzionato a prenotare un tour privato o per una scolaresca.</p><p>Disponibilità ad organizzare tour diversi da quelli da noi proposti. Contattaci per ulteriori informazioni e preventivi.</p><p>Ti ricontatteremo al più presto!</p>",
    name: 'Nome',
    surname: 'Cognome',
    message: 'Il tuo messaggio',
    button: 'Mettiti in contatto con noi',
    disclaimer: '<strong>N.B.</strong> Richiediamo alcune informazioni, come nome e indirizzo email, solo per permetterci di aiutarti a prenotare il tuo tour. <strong>Non cataloghiamo nè condividiamo i tuoi dati con servizi terzi in nessun caso.</strong>'
}

export const contactsEs = {
    title: 'Reserva ahora tu tour',
    description: "<p>Por favor rellena el espacio de abajo especificando el tour, el día y hora, el idioma, el número de personas del grupo, y si tienes intención de reservar un tour privado o para grupos escolares.</p><p>Disponibilidad para organizar tour distintos de aquellos propuestos. Contactanos para mayor información y presupuestos.</p><p>Te responderemos lo antes posible!</p>",
    name: 'Nombre',
    surname: 'Apellido',
    message: 'Tu mensaje',
    button: 'Ponte en contacto con nosotros',
    disclaimer: 'Pedimos algunas informaciones, como nombre y dirección de correo, solo para poder ayudarte a reservar tu tour. No catalogamos ni condividimos tus datos con en ningun caso.'
}

export const contactsDe = {
    title: 'Buchen Sie jetzt Ihre Tour',
    description: "<p>Füllen Sie folgendes Formular aus, indem Sie die von Ihnen gewählte Tour angeben, das Datum, die Uhrzeit, die Sprache und die Teilnehmerzahl. Geben Sie bitte an, ob sie als Privatperson oder für eine Schule buchen.</p><p>Gerne stelle wir uns auf Ihre Wünsche ein und passen unsere Touren ganz Ihren Bedürfnissen an.</p><p>Wir antworten schnellstmöglich!</p>",
    name: 'Vorname',
    surname: 'Nachname',
    message: 'Ihre Nachricht',
    button: 'Setzen Sie sich mit uns in Verbindung',
    disclaimer: 'Wir bitten Sie um die Angabe Ihres Namens und Ihrer Emailadresse ausschließlich, um Ihnen bei der Buchung ggf. zur Seite zu stehen. Keine Ihrer Angaben werden gespeichert oder an Dritte weitergegeben.'
}

export const contactsFr = {
    title: 'Réservez votre visite',
    description: "<p>Nous vous prions de bien vouloir remplir ce formulaire en indiquant la visite qui vous intéresse, le jour et l’heure à laquelle vous voudriez la faire, en quelle langue, le nombre de participants et leur âge, et s’il s’agit d’une visite privée ou d’un groupe d’élèves.</p><p>Nous pouvons organiser des tours différents de ceux indiqués ici.   Pour en savoir plus, contactez-nous. </p><p>Nous vous répondrons au plus vite.</p>",
    name: 'Prénom',
    surname: 'Nom',
    message: 'Votre message ',
    button: 'Ecrivez nous',
    disclaimer: 'NB:  Nous vous demandons certaines information personnelles - votre nom et votre adresse de courrier électronique - seulement pour nous permettre de réserver votre tour.  Nous ne cataloguons ni partageons vous information avec personne d’autre. '
}