import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import kids from '../assets/kids.png'
import certifiedIcon from '../assets/certified-icon.png'
import idea from '../assets/idea.png'
import globe from '../assets/globe2.png'

import disinfection from '../assets/disinfection.jpg'


import globeIcon from '../assets/globe.png'
import watchIcon from '../assets/watch.png'

import magnifyingIcon from '../assets/magnifying.png'
import arrowDownIcon from '../assets/arrow.svg'
import arrowUpIcon from '../assets/arrowUpBlack.png'
import paymentIcon from '../assets/payment.png'

const StyledTour = styled.section`
    background-color: #fffdf7;
    p{
        font-size: 1.6rem;
        line-height: 1.6;
    }
    h1{
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        margin: 30px auto;
    }
    h3{
        font-size: 2rem;
        text-align: center;
        font-weight: bold;
        padding: 15px 0;
    }
    .container{
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
        .info{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            @media screen and (max-width: 1300px) {
                flex-direction: column-reverse;
            }
            .left{
                width: 53%;
                @media screen and (max-width: 1300px) {
                    width:100%;
                }
                img{
                    width: 100%;
                }
            }
            .right{
                position: relative;
                width: 45%;
                background-color: #FF9505;
                padding: 3px 30px;
                @media screen and (max-width: 1300px) {
                    width:100%;
                }
                p{
                    font-size: 1.6rem;
                    padding: 6px 0;
                    @media screen and (max-width: 1350px) {
                        font-size: 1.4rem;
                    }
                }
                div{
                    display: flex;
                    align-items: center;
                    img{
                        height: 20px; 
                        margin-right: 20px;
                    }
                }
                
                a{
                    position: absolute;
                    bottom: 20px;
                    display: inline-block;
                    margin: 0 30%;
                    text-align: center;
                    white-space: nowrap;
                    font-size: 1.6rem;
                    border: 1px solid;
                    border-radius: 8px;
                    background-color: #fffdf7;
                    padding: 15px 20px;
                    box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
                    transition: .3s;
                    :hover{
                        background-color: rgb(214, 214, 214);
                    }
                    @media screen and (max-width: 1300px) {
                        position: unset;
                        margin: 20px 35%;
                    }
                    @media screen and (max-width: 600px) {
                        margin: 20px 30%;
                    }
                }
            }
        }
        .content{
            display: flex;
            @media screen and (max-width: 1000px) {
                flex-direction: column;
            }
            h3{
                font-size: 2.6rem;
            }
            p{
                text-align: justify;
                padding-right: 30px;
            }
            .text{
                width: 53%;
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
            .blurps-top{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-left: 30px;
                width: 48%;
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
                @media screen and (max-width: 650px) {
                    padding-bottom: 10px;
                }
                .blurp {
                    display: flex;
                    padding-right: 5px;
                    align-items: center;
                    @media screen and (max-width: 1000px) {
                        width: 48%;  
                        padding-bottom: 30px;
                    }
                    @media screen and (max-width: 650px) {
                        width: 100%;
                    }
                    img {
                        height: 50px;
                        padding-right: 25px;
                    }
                    div{
                        h3{
                            font-size: 1.8rem;
                            font-weight: bold;
                            padding-bottom: 5px;
                            text-align: left;
                        }
                        p{
                            font-size: 1.2rem;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
        .covid{
            border: 1px solid;
            padding: 12px;
            margin: 20px 0;
            background-color: #e5e5ff;
            .title{
                display: flex;
                align-items: center;
                
                img{
                    height: 50px;
                    padding-right: 15px;
                }
                p{
                    font-weight: bold;
                }
            }
        }

        .bot-button{
            display: inline-block;
            text-align: center;
            width: 100%;
            margin: 20px auto;
            padding: 15px;
            background-color: #FF9505;
            border: 1px solid;
            border-radius: 8px;
            font-size: 1.8rem;
            font-weight: bold;
            box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
            transition: .3s;
            :hover{
                background-color: #FF9505A6
            }
        }
    }
`

const Tour = props => {
    const { match, tour, blurps, content, buttons } = props;

    return (
        <StyledTour >
            <Helmet>
                <title>AGMF - {tour.name}</title>
                <meta name="description" content={tour.card} />
            </Helmet>
            <div className='container'>
                <h1>{tour.name}</h1>
                <div className='info'>
                    <div className='left'>
                        <img src={tour.img} alt="" />
                    </div>
                    <div className='right'>
                        <h3>{content.info}</h3>
                        <div>
                            <img src={arrowDownIcon} alt=""/>
                            <p><strong>{content.start}</strong> {tour.start}</p>
                        </div>
                        <div>
                            <img src={arrowUpIcon} alt=""/>
                            <p><strong>{content.end}</strong> {tour.end}</p>
                        </div>
                        <div>
                            <img src={magnifyingIcon} alt=""/>
                            <p><strong>{content.points}</strong> {tour.points}</p>
                        </div>
                        <div>
                            <img src={paymentIcon} alt=""/>
                            <p><strong>{content.price}</strong> {content.price_description}</p>
                        </div>
                        <div>
                            <img src={watchIcon} alt=""/>
                            <p><strong>{content.duration}</strong> {tour.duration}</p>
                        </div>
                        <div>
                            <img src={globeIcon} alt=""/>
                            <p><strong>{content.languages}</strong> {tour.languages}</p>
                        </div>
                        <p className='nb'><strong>{content.disclaimer}</strong></p>
                        <Link to={`/${match.params.language}/contacts`}>{buttons.book_now}</Link>
                    </div>
                </div>
                <div className='content'>
                    <div className='text'>
                        <h3>{content.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: tour.content }} />
                    </div>
                    <div className='blurps-top'>
                        <div className='blurp'>
                            <img src={certifiedIcon} alt="" />
                            <div>
                                <h3>{blurps.certified_title}</h3>
                                <p>{blurps.certified_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={globe} alt="" />
                            <div>
                                <h3>{blurps.for_you_title}</h3>
                                <p>{blurps.for_you_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={idea} alt="" />
                            <div>
                                <h3>{blurps.intriguing_title}</h3>
                                <p>{blurps.intriguing_description}</p>
                            </div>
                        </div>
                        <div className='blurp'>
                            <img src={kids} alt="" />
                            <div>
                                <h3>{blurps.for_kids_title}</h3>
                                <p>{blurps.for_kids_description}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='covid'>
                    <div className='title'>
                        <img src={disinfection} alt="" />
                        <p>{content.covid}</p>
                    </div>     
                </div>

                <Link to={`/${match.params.language}/contacts`} className='bot-button'>{buttons.book_now}</Link>
            </div>

        </StyledTour>
    )
}

export default Tour;