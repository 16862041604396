export const toursPageEn = {
    title: 'Pick the tour you prefer',
    description: 'AGMF has a native-speaker guide to match your every tour guiding request.',
    button: 'Discover our Tours'
}

export const toursPageIt = {
    title: 'Scegli il tour che preferisici',
    description: 'AGMF troverà la guida madrelingua perfetta per te',
    button: 'Scopri i nostri Tour'
}

export const toursPageEs = {
    title: 'Elige el tour que prefieras',
    description: 'AGMF encontrara el guía Madrelingua perfecto para cada ocasión',
    button: 'Descubre nuestros tours'
}

export const toursPageDe = {
    title: 'Wählen Sie Ihre Tour',
    description: 'AGMF findet für jegliche Anfrage die perfekte Person für Sie, die Sie führt und begleitet.',
    button: 'Erfahren Sie mehr über unsere Touren'
}

export const toursPageFr = {
    title: 'Choisissez le tour que vous préférez',
    description: 'AGMF vous trouvera un guide qui parle votre langue maternelle et qui vous conviendra parfaitement.',
    button: 'Voici nos tours'
}