import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import { blurpsEn, blurpsIt, blurpsEs, blurpsDe, blurpsFr } from '../content/various/blurps'
import { buttonsEn, buttonsIt, buttonsEs, buttonsDe, buttonsFr } from '../content/various/buttons'
import { toursPageEn, toursPageIt, toursPageEs, toursPageDe, toursPageFr } from '../content/tours/toursPage'
import { tourPageEn, tourPageIt, tourPageEs, tourPageDe, tourPageFr } from '../content/tour/tourPage'

import tour from '../assets/florenceTour.jpg';

import kids from '../assets/kids.png'
import certifiedIcon from '../assets/certified-icon.png'
import idea from '../assets/idea.png'
import globe from '../assets/globe2.png'

import globeIcon from '../assets/globe.png'
import watchIcon from '../assets/watch.png'

const StyledTours = styled.section`
    background-color: #fffdf7;
    .header{
        background-image: url(${tour});
        height: 500px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;
        padding-top: 120px;
        @media screen and (max-width: 1000px) {
           background-position: right;
        }
        @media screen and (max-width: 650px) {
            height: 400px;
            padding-top: 100px;
        }
        h1{
            display: inline-block;
            font-size: 4rem;
            font-weight: bold;
            color: #fffdf7;
            padding: 0 10px;
            @media screen and (max-width: 1000px) {
                font-size: 3rem;
            }
            @media screen and (max-width: 650px) {
                font-size: 2.6rem;
            }
        }
        p{
            display: inline-block;
            color: black;
            font-size: 2rem;
            margin-top: 40px;
            padding: 0 25px;
            color: #fffdf7;
            @media screen and (max-width: 1000px) {
                font-size: 1.8rem;
            }
            @media screen and (max-width: 650px) {
                font-size: 1.6rem;
            }
        }
        .button{
            display: inline-block;
            font-size: 2rem;
            padding: 10px 35px 8px;
            border: 1px solid;
            border-radius: 8px;
            background-color: white;
            margin-top: 60px;
            box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.8);
            transition: .3s;
            :hover{
                background-color: rgba(254, 254, 254, 0.22)
            }
            @media screen and (max-width: 1000px) {
                font-size: 1.8rem;
            }
            @media screen and (max-width: 650px) {
                font-size: 1.6rem;
            }
        }
    }
    .blurps-top{
        max-width: 1400px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 90%;
        padding: 40px 0;
        border-bottom: 1px solid #846A6A70;
        .blurp {
            display: flex;
            padding-right: 5px;
            width: 24%;
            @media screen and (max-width: 1000px) {
                    width: 48%;  
                    padding-bottom: 30px;
                }
            @media screen and (max-width: 650px) {
                width: 100%;
                padding-bottom: 30px;
            }
            img {
                height: 50px;
                padding-right: 8px;
            }
            div{
                h3{
                    font-size: 1.8rem;
                    font-weight: bold;
                    padding-bottom: 5px;
                }
                p{
                    font-size: 1.2rem;
                    line-height: 1.5;
                }
            }
        }
    }
    .category{
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
        padding: 40px 0;
        border-bottom: 1px solid #846A6A70;
        h2 {
            text-align: center;
            font-size: 3rem;
            padding-bottom: 30px;
        }
        .container{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .card{
                position: relative;
                height: 800px;
                width: 40%;
                border: 1px solid #846A6A70;
                margin: 10px 0;
                background-color: white;
                box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.2);
                @media screen and (max-width: 1200px) {
                    margin: 10px auto;
                    width: 60%;
                }
                @media screen and (max-width: 650px) {
                    width: 100%;
                }
                h3{
                    text-align: center;
                    font-size: 1.8rem;
                    font-weight: bold;
                    padding-top: 20px;
                }
                img{
                    height: 420px;
                    width: 100%;
                    transition: .6s;
                    :hover{
                        transform: scale(1.005);
                    }
                    @media screen and (max-width: 650px) {
                        height: 220px;
                    }
                }
                p{
                    font-size: 1.4rem;
                    line-height: 1.4;
                    padding: 20px 20px 10px; 
                }
                .info{
                    position: absolute;
                    bottom: 75px;
                    div{
                        display: flex;
                        align-items: center;
                        padding-left: 20px;
                        img{
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
                .button{
                    position: absolute;
                    bottom: 0;
                    margin: 20px 35%;
                    text-align: center;
                    font-size: 1.4rem;
                    border: 1px solid;
                    border-radius: 8px;
                    background-color: #FF9505;
                    padding: 10px 20px;
                    box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
                    transition: .3s;
                    :hover{
                        background-color: #FF9505A6
                    }
                    @media screen and (max-width: 650px) {
                        padding: 10px;
                    }
                }
            }
        }
    }
    .bot-button{
        display: block;
        max-width: 1400px;
        text-align: center;
        width: 80%;
        margin: 20px auto;
        padding: 15px;
        background-color: #FF9505;
        border: 1px solid;
        border-radius: 8px;
        font-size: 1.8rem;
        font-weight: bold;
        box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
        transition: .3s;
        :hover{
            background-color: #FF9505A6
        }
    }
`

const Tours = props => {
    const { match, tours } = props;
    const [blurps, setBlurps] = useState({})
    const [buttons, setButtons] = useState({})
    const [toursContent, setToursContent] = useState({})
    const [tourContent, setTourContent] = useState({})

    useEffect(() => {
        switch (match.params.language) {
            case 'it':
                setBlurps(blurpsIt)
                setButtons(buttonsIt)
                setToursContent(toursPageIt)
                setTourContent(tourPageIt)
                break
            case 'es':
                setBlurps(blurpsEs)
                setButtons(buttonsEs)
                setToursContent(toursPageEs)
                setTourContent(tourPageEs)
                break
            case 'de':
                setBlurps(blurpsDe)
                setButtons(buttonsDe)
                setToursContent(toursPageDe)
                setTourContent(tourPageDe)
                break
            case 'fr':
                setBlurps(blurpsFr)
                setButtons(buttonsFr)
                setToursContent(toursPageFr)
                setTourContent(tourPageFr)
                break
            default:
                setBlurps(blurpsEn)
                setButtons(buttonsEn)
                setToursContent(toursPageEn)
                setTourContent(tourPageEn)
                break
        }
    }, [match.params.language])

    return (
        <StyledTours>
            <Helmet>
                <title>{`AGMF - ${toursContent.title}`}</title>
                <meta name="description" content={`${toursContent.description}`} />
            </Helmet>
            <div className='header'>
                <h1>{toursContent.title}</h1>
                <br />
                <p>{toursContent.description}</p>
                <br />
                <HashLink smooth to={`/${match.params.language}/tours#populars`} className='button'>{toursContent.button}</HashLink>
            </div>

            <div className='blurps-top'>
                <div className='blurp'>
                    <img src={certifiedIcon} alt="" />
                    <div>
                        <h3>{blurps.certified_title}</h3>
                        <p>{blurps.certified_description}</p>
                    </div>
                </div>
                <div className='blurp'>
                    <img src={globe} alt="" />
                    <div>
                        <h3>{blurps.for_you_title}</h3>
                        <p>{blurps.for_you_description}</p>
                    </div>
                </div>
                <div className='blurp'>
                    <img src={idea} alt="" />
                    <div>
                        <h3>{blurps.intriguing_title}</h3>
                        <p>{blurps.intriguing_description}</p>
                    </div>
                </div>
                <div className='blurp'>
                    <img src={kids} alt="" />
                    <div>
                        <h3>{blurps.for_kids_title}</h3>
                        <p>{blurps.for_kids_description}</p>
                    </div>
                </div>
            </div>


            <div className='category' id='populars'>
                <div className='container'>
                    {tours.map(tour => (
                        <Link to={`/${match.params.language}/${tour.slug}`} className='card'>
                            <img src={tour.cardImg} alt="" />
                            <h3>{tour.name}</h3>
                            <div dangerouslySetInnerHTML={{ __html: tour.card }} />
                            <div className='info'>
                                <div>
                                    <img src={globeIcon} alt="" />
                                    <p>{tourContent.languages} {tour.languages}</p>
                                </div>
                                <div>
                                    <img src={watchIcon} alt="" />
                                    <p>{tourContent.duration} {tour.duration}</p>
                                </div>
                            </div>
                            <div className='button'>{buttons.discover_more}</div>
                        </Link>
                    ))}
                </div>
            </div>
            <Link to={`/${match.params.language}/contacts`} className='bot-button'>{buttons.book_now}</Link>
        </StyledTours >
    )
}

export default Tours;