import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'

import { guidesEn, guidesIt, guidesEs, guidesDe, guidesFr } from '../content/guides/guides'

import martino from '../assets/FOTO per il sito/Martino/martino.png'
import carmen from '../assets/FOTO per il sito/Carmen/carmen.png'
import angela from '../assets/angela.png'
import amal from '../assets/FOTO per il sito/Amal/amal2.png'


const StyledGuides = styled.section`
    background-color: #fffdf7;
    .header{
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
        padding: 40px 0;
        border-bottom: 1px solid #846A6A70;
        h1{
            font-size: 3rem;
        }
        p{
            font-size: 1.6rem;
            line-height: 1.5;
            padding-top: 15px;
        }
    }
    .content{
        .card{
            display: flex;
            justify-content: space-between;
            max-width: 1400px;
            margin: 0 auto;
            width: 90%;
            padding: 30px 0;
            @media screen and (max-width: 650px) {
                flex-direction: column;
            }
            img{
                height: 200px;
                border-radius: 50%;
                margin: 0 50px;

                @media screen and (max-width: 650px) {
                    margin: 0 auto;
                    order: -1;
                    margin-bottom: 20px;
                }
            }
            .info{
                
                h3{
                    font-size: 2rem;
                    font-weight: bold;
                    padding-bottom: 20px;
                }
                p{
                    font-size: 1.6rem;
                    line-height: 1.5;
                }
            }
        }
    }
`

const Guides = props => {
    const { match } = props
    const [guides, setGuides] = useState({})

    useEffect(() => {
        switch (match.params.language) {
            case 'it':
                setGuides(guidesIt)
                break
            case 'es':
                setGuides(guidesEs)
                break
            case 'de':
                setGuides(guidesDe)
                break
            case 'fr':
                setGuides(guidesFr)
                break
            default:
                setGuides(guidesEn)
                break
        }
    }, [match.params.language])

    return (
        <StyledGuides>
            <Helmet>
                <title>{`AGMF - ${guides.title}`}</title>
                <meta name="description" content={`${guides.description}`} />
            </Helmet>
            <div className='header'>
                <h1>{guides.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: guides.description }} /><p />
            </div>
            <div className='content'>
                <div className='card'>
                    <img src={angela} alt="" />
                    <div className='info'>
                        <h3>Angela Oberer – Deutsch</h3>
                        <p>{guides.guide_description_1}</p>
                    </div>
                </div>
                <div className='card'>
                    <div className='info'>
                        <h3>Carmen Higuera - Español</h3>
                        <p>{guides.guide_description_2}</p>
                    </div>
                    <img src={carmen} alt="" />
                </div>
                <div className='card'>
                    <img src={martino} alt="" />
                    <div className='info'>
                        <h3>Martino Traxler – English</h3>
                        <p>{guides.guide_description_3}</p>
                    </div>
                </div>
                <div className='card'>
                    <div className='info'>
                        <h3>Amal El Khoury – Arabo باللغة العربية</h3>
                        <p>{guides.guide_description_4}</p>
                    </div>
                    <img src={amal} alt="" />
                </div>
                {/* <div className='card'>
                    <img src={obama} alt="" />
                    <div className='info'>
                        <h3>Obama Pallino</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div> */}
            </div>
        </StyledGuides>
    )
}

export default Guides;