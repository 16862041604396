import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'

import { contactsEn, contactsIt, contactsEs, contactsDe, contactsFr } from '../content/contacts/contacts'

import groups from '../assets/groups.png'


const StyledContacts = styled.section`
    margin: 0 auto;
    background-color: #fffdf7;
    padding: 20px 0;
    .info{
        max-width: 1400px;
        width: 80%;
        display: flex;
        margin: 0 auto;
        padding: 30px 0;
        padding-bottom: 0;
        height: 300px;
        background-color: #FF9505;
        @media screen and (max-width: 650px) {
            height: 400px;
        }
        h2{
            text-align: center;
            font-size: 3.5rem;
            font-weight: bold;
            @media screen and (max-width: 1300px) {
                font-size: 2.6rem;
            }
            @media screen and (max-width: 650px) {
                text-align: left;
                padding-left: 20px;
            }
        }
        p{
            font-size: 1.7rem;
            line-height: 1.5;
            padding: 10px 20px;
            @media screen and (max-width: 1400px) {
                font-size: 1.6rem;
            }
        }
        img{
            @media screen and (max-width: 1300px) {
                display: none;
            }
        }
    }
    form{
        max-width: 1400px;
        margin: 0 auto;
        padding-top: 30px;
        width: 80%;
        div{
            p{
                font-size: 1.4rem;
                padding-top: 12px;
                padding-bottom: 7px;
            }
            input{
                width: 100%;
                padding: 10px 5px;
                border-radius: 8px;
            }
            textarea{
                width: 100%;
                border-radius: 8px;
                height: 180px;
                padding: 10px 5px;
            }
        }
        .full-name{
            display: flex;
            justify-content: center;
            div{
                width: 50%;
            }
            .name{
                width: 95%;
            }
        }
        .submit{
            text-align: center;
            width: 100%;
            margin: 20px auto;
            padding: 15px;
            background-color: #FF9505;
            border: 1px solid;
            border-radius: 8px;
            font-size: 1.8rem;
            font-weight: bold;
            box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
            transition: .3s;
            :hover{
                background-color: #FF9505A6
            }
        }
    }  
    .data{
        max-width: 1400px;
        margin: 0 auto;
        width: 80%;
        font-size: 1.4rem;
        padding-bottom: 20px;
    }
`

const Contacts = props => {
    const { match } = props;
    const [contacts, setContacts] = useState({})

    useEffect(() => {
        switch (match.params.language) {
            case 'it':
                setContacts(contactsIt)
                break
            case 'es':
                setContacts(contactsEs)
                break
            case 'de':
                setContacts(contactsDe)
                break
            case 'fr':
                setContacts(contactsFr)
                break
            default:
                setContacts(contactsEn)
                break
        }
    }, [match.params.language])

    return (
        <StyledContacts>
            <Helmet>
                <title>{`AGMF - ${contacts.title}`}</title>
                <meta name="description" content={`${contacts.description}`} />
            </Helmet>
            <div className='info'>
                <img src={groups} alt="" />
                <div>
                    <h2>{contacts.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: contacts.description }} />
                </div>
            </div>
            <form action="https://submit-form.com/U5ioMXES" >
                <div className='full-name'>
                    <div>
                        <p>{contacts.name}</p>
                        <input className='name' type="text" name='name' placeholder='Mario' />
                    </div>
                    <div>
                        <p>{contacts.surname}</p>
                        <input type="text" name='surname' placeholder='Rossi' />
                    </div>
                </div>
                <div>
                    <p>E-mail</p>
                    <input type="email" name='mail' placeholder='mariorossi@esempio.it' />
                </div>
                <div>
                    <p>{contacts.message}</p>
                    <textarea name='message' placeholder='Facci sapere le tue esigenze..' />
                </div>
                <button className='submit' type="submit">{contacts.button}</button>
            </form>
            <p className='data' dangerouslySetInnerHTML={{ __html: contacts.disclaimer }} />
        </StyledContacts>
    )
}

export default Contacts;