export const homepageEn = {
    header_title: 'Native-speaker Tourist Guides in Your Language in Florence',
    header_description: 'Visit Florence as you’ve never done before... Florence is well worth it!',
    header_button: 'Discover more',
    content_title: 'Why tour with us?',
    content_description: '<strong>Our Native-Speakers’ Guiding Association of Florence (AGMF)</strong> ensures fully comprehensible quality tour presentations.<br></br><br></br>Our members are officially licensed professional guides working in their native language:  English, French, Spanish, German, Arab, Italian, Portuguese, Russian…  This innate ability sets us apart and also allows us to offer an innovative program to schools at all secondary levels, in any of these languages.<br></br><br></br>Moreover, we each pride ourselves in our distinctive personal approach with our clients.  We are all experienced.  We are all passionate about our work and professional, all the while, constantly keeping up to date with recent developments in this field.  Our tours can all be tailored, in any language available, to the wishes of our clients.',
    content_description2: 'AGMF will pair you with a fellow native-language speaking guide who can meet your every request',
    contacts_title: 'Book your tour with us!',
    contacts_description: 'Florence is well worth it!  Indulge In the pleasure of visiting one of the world’s most beautiful cities with an AGFM native-English-speaking guide.',
    book_now_button: 'BOOK NOW',
    services_title: 'What kind of tour are you looking for?',
    tours_title: 'Our tour proposals'
}

export const homepageIt = {
    header_title: 'Le tue Guide Madrelingua a Firenze',
    header_description: 'Visita Firenze come non lo hai fatto prima... Firenze vale la pena!',
    header_button: 'Scopri di più',
    content_title: 'Perchè venire in tour con noi?',
    content_description: '<strong> L’Associazione Guide Madrelingua Firenze (AGMF)</strong> garantisce ai nostri clienti una presentazione al <strong>100% comprensibile e di qualità</strong>. <br></br><br></br><strong>I nostri soci, guide turistiche e accompagnatori, abilitati e riconosciuti ufficialmente, lavorano nella propria lingua madre</strong>: inglese, francese, spagnolo, tedesco, arabo, italiano, portoghese, russo…. Questa particolarità ci differenzia e ci permette di offrire un programma <strong>innovativo per le scuole</strong> , in tutte le lingue e i livelli richiesti. <br></br><br></br>Non solo, la nostra guida madrelingua avrà un approccio decisamente diverso e personalizzato con i <strong>turisti stranieri</strong> . Tutte le nostre guide hanno <strong>molta esperienza</strong>, lavorano con grande <strong>passione e professionalità</strong>  e seguono corsi per essere <strong>sempre aggiornate</strong> . Tutti i nostri tour possono essere fatti su misura, in tutte le lingue richieste e con diverse destinazioni.',
    content_description2: 'AGMF troverà la guida madrelingua perfetta per ogni richiesta.',
    contacts_title: 'Prenota ora il tuo tour!',
    contacts_description: 'Firenze vale la pena! Concediti il piacere di visitare una delle città più belle del mondo con le nostre guide AGFM madrelingua',
    book_now_button: 'PRENOTA ORA',
    services_title: 'Che genere di vista t\'interessa?',
    tours_title: 'Le nostre proposte'
}

export const homepageEs = {
    header_title: 'Tus Guias Turisticos Madrelingua en Florencia',
    header_description: 'Visita Florencia como no lo has hecho hasta ahora... ¡Florencia vale la pena!',
    header_button: 'Scobre mas',
    content_title: '¿Por qué elegirnos?',
    content_description: '<strong> La Asociación Guías Madrelingua Florencia (AGMF)</strong> garantiza a nuestros clientes una presentación de calidad, 100% comprensible. <br></br><br></br>Los socios, guías y acompañantes turísticos, autorizados y reconocidos oficialmente, trabajamos en nuestra lengua materna: inglés, francés, español, alemán, árabe, italiano, portugués, ruso... Esta particularidad, que nos caracteriza e identifica, nos permite ofrecer un programa innovador para los colegios, en todos los idiomas y niveles solicitados. <br></br><br></br>Además, gracias a ello, nuestro guía Madrelingua tendrá un enfoque diferente y personalizado para cada grupo de turistas en función de su idioma de origen. Todos nuestros guías tienen mucha experiencia, trabajan con pasión y profesionalidad y siguen cursos de formación para estar siempre al día. Todas nuestra visitas ser pueden organizar a medida, en todos los idiomas deseados y con distintos destinos.',
    content_description2: 'En AGMF encontrara el guía Madrelingua perfecto para cada ocasión',
    contacts_title: 'Reserva ahora tu tour!',
    contacts_description: 'Florencia vale la pena! Concédete el placer de visitar una de las ciudades más bellas del mundo con una guía AGMF Madrelingua',
    book_now_button: 'RESERVA AHORA',
    services_title: '¿Qué tipo de visita te interesa?',
    tours_title: 'Nuestras propuestas'
}

export const homepageDe = {
    header_title: 'Stadtführer, die Sie in Ihrer Muttersprache begleiten',
    header_description: 'Besichtigen Sie Florenz, wie Sie es vorher noch nie getan haben – Florenz lohnt sich!',
    header_button: 'Entdecken Sie mehr',
    content_title: 'Warum sollten Sie uns buchen?',
    content_description: '<strong> Unsere „Associazione Guide Madrelingua Firenze (AGMF)”</strong> garantiert ihren Kunden hundertprozentig verständliche und qualitätsvolle Touren.<br></br><br></br>Die Mitglieder sind patentierte Stadtführer, die in ihrer Muttersprache arbeiten: Englisch, Französisch, Spanisch, Deutsch, Arabisch, Italienisch, Portugiesisch, Russisch etc. Diese Besonderheit zeichnet uns aus und erlaubt es uns, ein innovatives Programm für Schulen jeder Art und Schüler jeden Alters in allen Sprachen anzubieten.<br></br><br></br>Unsere Stadtführer haben langjährige Erfahrung und legen viel Enthusiasmus und Professionalität in ihren Beruf. Sie besuchen zusätzlich regelmäßig Seminare, um immer auf dem neuesten Stand zu stehen. Alle unsere Touren können auf individuelle Bedürfnisse abgestimmt werden.',
    content_description2: 'AGMF findet für jegliche Anfrage die perfekte Person die Sie führt und begleitet.',
    contacts_title: 'Buchen Sie jetzt Ihre Tour!',
    contacts_description: 'Florenz lohnt sich! Gönnen Sie sich das Vegnügen, eine der schönsten Städte mit unseren Stadtührern der AGMF zu besichtigen.',
    book_now_button: 'BUCHEN SIE JETZT',
    services_title: 'An welchen Touren sind Sie interessiert?',
    tours_title: 'Geschäftsbedingungen'
}

export const homepageFr = {
    header_title: 'Des guides touristiques à Florence dont la langue maternelle est la vôtre.',
    header_description: 'Visitez Florence comme vous ne l’avez jamais fait auparavant.',
    header_button: 'Pour en savoir plus',
    content_title: 'Pourquoi faire une visite avec nous?',
    content_description: '<strong> L’Association des Guides de Langue Maternelle de Florence (AGMF)</strong> garantit à ses clients des visites de qualité et des explications compréhensibles à 100%. <br></br><br></br>Nos membres, tous guides touristiques et accompagnateurs officiellement autorisés, travaillent pour notre association uniquement dans leur propre langue maternelle:  à l’heure actuelle, en français, anglais, espagnol, allemand ou arabe.  Cette caractéristique, chez nous innée, nous permet d’un côté de nous différencier des guides italiens et de l’autre d’offrir un programme innovateur pour les écoles secondaires, dans toutes les langues étudiées et à tous les niveaux.<br></br><br></br>En outre, votre guide de langue maternelle saura adopter avec vous une approche plus personnalisée.  Tous nos guides possèdent une solide expérience, travaillent avec beaucoup de passion en faisant preuve de professionnalisme, et se tiennent constamment à jour.  Chacune de nos visites peut être faite sur mesure, en fonction des différents publics et dans chacune des langues disponibles.',
    content_description2: 'L’AGMF vous trouvera le guide parfait pour chacune de vos exigences.',
    contacts_title: 'Réservez votre visite!',
    contacts_description: 'Florence en vaut la peine!  Concédez-vous le plaisir de visiter une des plus belles villes au monde avec un guide AGFM qui parle votre langue maternelle.',
    book_now_button: 'POUR RĖSERVER',
    services_title: 'A quel genre de tour êtes-vous intéressés?',
    tours_title: 'Nos suggestions'
}