import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { navbarEn, navbarIt, navbarEs, navbarDe, navbarFr } from '../content/navbar/navbar'
import { buttonsEn, buttonsIt, buttonsEs, buttonsDe, buttonsFr } from '../content/various/buttons'
// import fb from '../assets/fb-logo.png'
// import ig from '../assets/ig-logo.png'
// import ta from '../assets/tripadv-logo.jpg'
// import wa from '../assets/Whatsapp-logo.png'
import mail from '../assets/Mail-logo.png'

const StyledFooter = styled.section`
    background-color: #846A6A;
    height: 250px;
    text-align: center;
    .logos{
        display: flex;
        justify-content: center;
        align-items: center;
        a{
            color: white;
            font-size: 1.8rem;
        }
        img{
            height: 40px;
            margin: 50px 15px;
            @media screen and (max-width: 1000px) {
                height: 35px;
            }
        }
    }
    .navbar{
        display: flex;
        justify-content: space-around;
        margin: 0 auto 40px;
        width: 60%;
        font-size: 1.6rem;
        @media screen and (max-width: 1000px) {
            font-size: 1rem;
            width: 90%;
        }
    }
`

const Footer = props => {
    const { language } = props;
    const [navbar, setNavBar] = useState({})
    const [buttons, setButtons] = useState({})

    useEffect(() => {
        switch (language) {
            case 'it':
                setButtons(buttonsIt)
                setNavBar(navbarIt)
                break
            case 'es':
                setButtons(buttonsEs)
                setNavBar(navbarEs)
                break
            case 'de':
                setButtons(buttonsDe)
                setNavBar(navbarDe)
                break
            case 'fr':
                setButtons(buttonsFr)
                setNavBar(navbarFr)
                break
            default:
                setButtons(buttonsEn)
                setNavBar(navbarEn)
                break
        }
    }, [language])

    return (
        <StyledFooter>
            <div className='logos'>
                {/* <img src={fb} alt="" />
                <img src={ig} alt="" />
                <img src={ta} alt="" />
                <img src={wa} alt="" /> */}
                <a href="mailto:info@guidemadrelinguafirenze.com"><img src={mail} alt="" /></a>
                <a href="mailto:info@guidemadrelinguafirenze.com">info@guidemadrelinguafirenze.com</a>
            </div>
            <div className='navbar'>
                <Link to={`/${language}/`}>HOME</Link>
                <Link to={`/${language}/services`}>{navbar.services}</Link>
                <Link to={`/${language}/tours`}>{navbar.tours}</Link>
                <Link to={`/${language}/guides`}>{navbar.guides}</Link>
                <Link to={`/${language}`}>PRIVACY</Link>
                <Link to={`/${language}/contacts`} className='contacts'>{buttons.book_now}</Link>
            </div>
            <div>© Copyright 2021 |  ASSOCIAZIONE GUIDE MADRELINGUA FIRENZE |  Via Francesco Puccinotti, 35  -  50129 Firenze  |  Tutti i diritti sono riservti</div>

        </StyledFooter>
    )
}

export default Footer;