export const tourPageEn = {
    info: 'About this tour:',
    start: 'Starting point:',
    end: 'End point:',
    points: 'Points of interest:',
    price: 'Price:',
    price_description: 'Please see SERVICES',
    duration: 'Length:',
    languages: 'Available in:',
    disclaimer: 'Start and finish are merely indicative; they may change to suit the participants’ logistic needs.',
    title: 'Find out more',
    covid: 'We carefully follow all COVID-19 regulations:  You are in safe hands while you enjoy your tour.'
}

export const tourPageIt = {
    info: 'Informazioni sul tour:',
    start: 'Luogo di inizio:',
    end: "Luogo d'arrivo:",
    points: "Punti d'interesse:",
    price: 'Costo:',
    price_description: 'Vedi pagina servizi',
    duration: 'Durata:',
    languages: 'Disponibile in:',
    disclaimer: 'I punti di partenza e di arrivo sono indicativi e possono cambiare secondo i bisogni logistici dei partecipanti',
    title: 'Panoramica del tour:',
    covid: 'Rispettiamo tutte le politiche alla lotta al Covid-19. Godetevi un tour con noi in totale sicurezza.'
}

export const tourPageEs = {
    info: 'Información sobre el tour:',
    start: 'Punto de inicio:',
    end: 'Punto de llegada:',
    points: 'Puntos de interés:',
    price: 'Tarifa:',
    price_description: 'Ver página de servicios',
    duration: 'Duración:',
    languages: 'Disponible en:',
    disclaimer: 'Los puntos de inicio y llegada son indicativos y pueden variar dependiendo de las necesidades logísticas de los participantes',
    title: 'Descripción del recorrido',
    covid: 'Cumplimos con todas las normas para combatir el COVID-19. Disfruta de  un  recorrido con nosotros con total seguridad.'
}

export const tourPageDe = {
    info: 'Weitere Infomationen:',
    start: 'Startpunkt der Tour:',
    end: 'Endpunkt der Tour: ',
    points: 'Sehenswürdigkeiten:',
    price: 'Kosten:',
    price_description: 'Sehen Sie unter Serviceleistungen',
    duration: 'Dauer:',
    languages: 'Tour auf:',
    disclaimer: 'Start- und Endpunkt der Tour können jedoch auch individuellen Wünschen angepasst werden.',
    title: 'Entdecken Sie mehr...',
    covid: 'Wir respektieren alle Sicherheitsvorkehrungen im Kampf gegen Covid-19. Genießen Sie eine Tour in vollkommener Sicherheit.'
}

export const tourPageFr = {
    info: 'Informations:',
    start: 'Départ:',
    end: 'Arrivée:',
    points: 'Lieux d’intérêt:',
    price: 'Coût:',
    price_description: 'Voire SERVICES',
    duration: 'Durée:',
    languages: 'Disponible en:',
    disclaimer: 'Le départ et l’arrivée sont indicatifs et peuvent changer selon les exigences des participants.',
    title: 'Aperçu',
    covid: 'Nous respectons les normes anti-Covid en vigueur: visitez en toute sécurité.'
}