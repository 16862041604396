export const guidesEn = {
    title: 'OUR FOUNDING GUIDES',
    description: 'Our association, AGMF, was founded by friends after years of friendship and guiding experience in our native languages.  As licensed guides we are specialized in presenting sites of cultural and historical interest in and around Florence in our native language.<br/><br/> Each of us has lived in Florence for many years.  We each consider ourselves Florentines by adoption.  We know and love our city every bit as much as the native-born (maybe even more).',
    guide_description_1: 'My name is Angela Oberer.  After university in Bonn, the ex capital of Germany, I came to Florence to complete my doctorate in history of art.  As I became aware of how many US university programs there were in this city, I dreamed of teaching art history surrounded by the highest concentration of artworks in the world which one finds in Florence.  So I started looking for work and have now been teaching for 20 years.  Then I started working as a tour guide.  I still get excited showing my clients squares, churches and museums of Florence.  I especially love telling the stories behind these works and pointing out unexpected or lesser-known particulars.  How do I do it?  Come see for yourselves.  I’m easy to find:  just look for the color orange!',
    guide_description_2: 'My name is Carmen Higuera, I am Spanish from Zaragoza, and I have been living in Florence since I was 23 years old when I came to study Art and Restoration.  I have worked as a restorer, taken part in antiques fairs and markets.  In 2009 I became an official licensed tour guide in Florence.  Since then I have worked mostly in Spanish, my mother tongue, and this always makes me feel like an eternal tourist.  I am a curious person, I like change, I adapt quickly and well to new things.  I feel fortunate to live in Florence, one of the most beautiful cities in the world with a fascinating history and a marvelous countryside around it.  Personally, I feel the need to surround myself in beauty which is very easy in Florence.  Working as a guide I have discovered the pleasure of sharing my knowledge and my feelings to others.  I hope to share these with you soon! ',
    guide_description_3: 'Having previously taught English as a Second Language and, later, philosophy in various American university settings, MT came late to tour guiding in Florence, which he does almost exclusively in English.  MT has also taught classes on Italian society and culture as well as ethics and logic at various U.S. study-abroad programs in Tuscany and, lately, remotely in the States.  In addition to his keen interest in Italian art and culture from the middle ages onward, MT also studies science from the Greeks through to the early-modern era for his tours at the Galileo Museum.',
    guide_description_4: 'I was born and raised in Beirut.  I received a degree in architecture in the US.  On my first trip to Florence, I fell in love with the city and I decided to move here.  Now, thirty years later, I am still here!  After many jobs, I decided to become a licensed tour assistant (accompagnatrice) and tour guide.  I speak Italian, Arabic, and English fluently. Italy really is il Bel Paese!  It really is a country of unparalleled beauty and creativity, like no other in the world, and a lot of that you find right here in Florence.  Despite problems and difficulties, Italy still succeeds in preserving its rich artistic and cultural heritage.  I find this particularly admirable coming from the Middle East where neglect and disinterest have allowed millennia of history and culture to be destroyed or forgotten, for the most part.  Visiting Florence is like taking a fascinating trip back in time.  It would be my pleasure to take you around, tell you some of its history and anecdotes, and share with you my passion for its beauty.',
}

export const guidesIt = {
    title: 'Le nostre guide',
    description: 'La nostra associazione, AGMF, è nata dopo anni di esperienza e amicizia di quattro guide di madre lingua straniera. Siamo guide e accompagnatori turistici abilitati, specializzati nel mostrare i luoghi d’interesse culturale e artistico di Firenze e non solo, ognuno nella propria lingua madre. <br/><br/> Tutte le nostre guide vivono a Firenze da molti anni, sono fiorentine di adozione e la conoscono e la amano tanto quanto un fiorentino vero (forse di più). ',
    guide_description_1: 'Dopo gli studi svolti in Germania, nell´ex capitale, la città di Bonn, ho fatto un dottorato in storia della arte per il quale sono venuta a Firenze. Quando mi sono resa conta di quante univeristà americane si trovano in questa città, è nato il mio soghno: volevo insegnare in mezzo alla concentrazione più alta di opere artistiche al mondo che si trova a Firenze. E quindi ho fatto domanda a diverse università e ormai sono 20 anni che insegno. Durante il periodo degli studi, ho cominciato a lavorare anche come guida. Ancora oggi mi entusiasma presentare, far vedere le piazza, le chiese e i musei ai miei ospiti. Con enorme piacere offro storie e dettagli meno conosciuti o inaspettati. Come? Venite e vedete voi stessi. Mi trovate facilmente: cercate il colore arancione….',
    guide_description_2: 'Mi chiamo Carmen Higuera, sono spagnola, di Zaragoza e vivo a Firenze da quando avevo 23 anni. Sono venuta per studiare Arte e Restauro. Ho lavorato come restauratrice, partecipato a mercati di antiquariato e fiere di artigianato. Nel 2009 ho preso l’abilitazione di guida turistica di Firenze. Da allora lavoro principalmente in spagnolo, la mia lingua madre, e questo mi fa sentire un’eterna turista. Sono una persona curiosa, mi piacciono i cambiamenti e mi adatto bene e in fretta alle novità. Mi sento fortunata a vivere a Firenze, una delle città più belle del mondo, con una storia affascinante, e dintorni naturali meravigliosi. Personalmente ho bisogno di sentirmi circondata dalla Bellezza, e a Firenze questo è molto facile. Lavorando come guida ho scoperto il piacere di trasmettere e condividere le mie conoscenze e le mie emozioni agli altri. Spero di poterlo fare presto anche con voi! ',
    guide_description_3: 'Con trascorsi nell’insegnamento dell’inglese come seconda lingua prima, e in programmi universitari statunitensi in filosofia poi, MT è approdato tardi alla professione di guida turistica a Firenze che ora svolge quasi esclusivamente in inglese assieme all’insegnamento di corsi di etica e di logica per vari programmi universitari americani a Firenze e (in rete) negli Stati Uniti. Oltre ad un vivo interesse per l’arte e per la cultura italiana dal Medioevo in poi, MT è anche appassionato di scienza medievale e moderna che ama divulgare al Museo Galileo.',
    guide_description_4: 'Sono nata e cresciuta a Beirut, e mi sono laureata in architettura negli Stati Uniti. Dopo un primo viaggio a Firenze, me ne sono innamorata e ho deciso di rimanerci, ed eccomi che vivo qui da oltre 30 anni! Dopo anni di lavori vari, ho deciso di diventare guida ed accompagnatrice turistica. Parlo correntemente l’italiano, l’arabo e l’inglese. L’Italia è davvero il Bel Paese! E’ il paese della bellezza e della creatività per eccellenza, incomparabile con nessun altro paese al mondo, e Firenze ne detiene una grossa fetta. L’Italia, nonostante i problemi e le difficoltà, riesce a conservare il suo ricco patrimonio artistico e culturale. Trovo questo fatto particolarmente ammirevole venendo dal Medioriente dove, purtroppo, l’incuria e il disinteresse hanno fatto sì che millenni di storia e cultura siano stati per la maggior parte distrutti o dimenticati. Visitare Firenze è come fare un viaggio affascinante nel tempo, e sarebbe per me un piacere accompagnarvici per raccontarvi le sue storie e condividere con voi la mia passione per le sue bellezze.',
}

export const guidesEs = {
    title: 'Nuestras Guías',
    description: 'Nuestra asociación, AGMF, nace de la experiencia y amistad de cuatro guías turísticas de distintos paises. Somos guías y acompañantes turísticos autorizados, especializados en mostrar los lugares de interés cultural y artístico de Florencia, especialmente, cada uno en nuestra lengua materna.<br/><br/> Todas nuestras guías viven en Florencia desde hace muchos años, son florentinas de adopción y la conocen y aman tanto como un verdadero florentino (o más). ',
    guide_description_1: 'Me llamo Angela Oberer. Después de mis estudios en Bonn antigua capital de Alemania realice un doctorado en historia del arte, por el que vine a Florencia. Cuando me di cuenta de la gran cantidad de universidades que había en esta ciudad nació mi sueño: enseñar en medio de la mayor concentración de obras artísticas del mundo, que se encuentra en Florencia. Contacté con varias universidades y ya llevo más de 20 años dando clases. Durante mi época de estudiante empecé a trabajar como guía. Todavía hoy en día me encanta presentar, mostrar las plazas, las iglesias y museos a los viajeros. Con enorme satisfacción os ofrezco historias y detalles menos conocidos o inesperados. ¿Cómo? Venid y lo descubriréis vosotros mismos. Me encontrareis fácilmente: buscad el color naranja... ',
    guide_description_2: 'Me llamo Carmen Higuera, soy española, de Zaragoza y vivo en Florencia desde que tenía 23 años. Vine a estudiar Arte y Restauración. He trabajado como restauradora, participando en mercados de antigüedades y ferias de artesanía. En el año 2009 obtuve el titulo de guía turística de Florencia. Desde entonces trabajo principalmente en español, mi idioma natal, y esto me hace sentirme una eterna turista. Soy una persona curiosa, me gustan los cambios adaptándome bien y con rapidez  a las novedades. Tengo mucha suerte de vivir en Florencia, una de las ciudades más bellas del mundo, con una historia fascinante  y un entorno natural maravilloso. Personalmente siento la necesidad de estar rodeada por la belleza y en Florencia esto es muy fácil. Trabajando como guía he descubierto el placer de compartir y trasmitir mis conocimientos y emociones con los demás. ¡Espero poderlo hacer pronto con vosotros!',
    guide_description_3: 'Tras dedicarse a la enseñanza del inglés y participar en cursos  universitarios de Filosofía en los Estados Unidos,. MT descubrió la profesión de guía turístico en Florencia, actividad que comparte  en la actualidad con la de impartir cursos de ética y lógica en distintos programas universitarios americanos tanto en Florencia como en Estados Unidos (vía telemática).  Posee un vivo interés por el arte y la cultura italiana, siendo un apasionado de la ciencia  que le encanta divulgar en el Museo Galileo',
    guide_description_4: 'Nací y crecí en Beirut, licenciandome en arquitectura en Estados Unidos. Tras un primer viaje a Florencia, me enamoré de ella y decidí quedarme, y aquí estoy,¡ vivo en esta ciudad desde hace  mas de 30 años! Después de unos años y realizar distintos trabajos, decidi realizar estudios de guía y acompañante turística. Hablo perfectamente italiano, árabe e inglés.¡ Italia es de verdad un hermoso país! Es la nación de la belleza y de la creatividad por excelencia, no tiene comparación alguna con ningún otro país del mundo, y Florencia representa una gran parte de ello. Italia, a pesar de los problemas y dificultades que ello conlleva, sigue conservando su rico patrimonio artístico y cultural. Encuentro este hecho particularmente admirable, ya que yo provengo del Medio Oriente,  en donde, por desgracia, la negligencia y el desinterés han hecho que milenios de historia y cultura hayan sido olvidados o  destruidos. Visitar Florencia  es como hacer un fascinante viaje en el tiempo, y sería un honor para mí acompañaros para contaros sus historias y compartir con vosotros mi pasión por sus maravillas.',
}

export const guidesDe = {
    title: 'Unsere Mitglieder',
    description: 'Nach jahrelanger Erfahrung und Freundschaft, haben sich vier Stadtführer zusammen getan und AGMF gegründet. Wir sind patentierte Stadtführer, die sich darauf spezialisiert haben, Florenz und seine Kunst- und Kulturgüter in der eigenen Muttersprache vorzustellen.<br/><br/> Alle unsere Mitarbeiter/innen leben seit Jahre in Florenz, sind Adoptiv-Florentiner, die die Stadt kennen und lieben wie ein Florentiner (vielleicht sogar noch mehr). ',
    guide_description_1: 'Mein Name ist Angela Oberer. Nach meinem Studium in Bonn habe ich Kunstgeschichte promoviert und bin für die Recherche nach Florenz gegangen. Erst hier vor Ort wurde mir bewusst, wie viele amerikanische Universitäten sich in der Stadt niedergelassen haben, und mein Traum war geboren: ich wollte in Florenz, inmitten der größten Konzentration von Kunstwerken der Welt Kunstgeschichte unterrichten. So bewarb ich mich nach Abschluss der Promotion und bin nun seit über 20 Jahren an diversen Instituten als Lektorin tätig. Während der gesamten Zeit meines Studiums bis heute habe ich immer auch als Stadtführerin gearbeitet. Meinen Schülern, Studenten und Gästen die einzigartigen Plätze, Kirchen und Museen näher zu bringen und Sie dabei immer auch mit unerwarteten Details zu überraschen ist mir nach wie vor ein großes Vergnügen. Wie? Kommen Sie und sehen Sie selbst. Sie finden mich auch ganz leicht: halten Sie nach Orange Ausschau, es ist ganz einfach!',
    guide_description_2: 'Mein Name ist Carmen Higuera, ich bin Spanierin aus Zaragoza und lebe seit meinem 23. Lebensjahr in Florenz. Ich kam hierher, um Kunst und Restaurierung zu studieren. Ich habe als Restauratorin gearbeitet, an Antikmärkten und Kunsthandwerkmessen teilgenommen. Seit 2009 bin ich anerkannte Stadtführerin von Florenz. Seitdem arbeite ich vorwiegend auf Spanisch, meiner Muttersprache, was mir das Gefühl gibt, eine ewige Touristin zu sein. Ich bin von Natur aus neugierig, mir gefallen Veränderungen und ich passe mich gut und schnell jeglichen Neuerungen an. Ich halte mich für privilegiert, in Florenz zu leben, einer der schönsten Städte der Welt, mit einer faszinierenden Geschichte und eine wunderschönen Umgebung. Es ist mir ein Bedürfnis, mich mit schöenen Dingen zu umgeben, und das ist in Florenz sehr einfach. Dank meiner Arbeit als Stadtführerin habe ich das Vergnügen entdeckt, mein Wissen und meine Emotionen mit anderen zu teilen. Ich hoffe, das bald auch mit Ihnen zu können!',
    guide_description_3: 'Nach jahrelanger Erfahung als Englischlehrer und Dozent für Philsophie an amerikanischen Universitäten ist Martino Traxler spät zu dem Beruf des Stadtführers von Florenz gekommen. Heute arbeitet er fast ausschließlich auf Englisch und unterrichtet Ethik und Logik an amerikanischen Universitäten in Florenz. Neben seinem Interesse an der italienischen Kultur des Mittelalters hat Martino Traxler ein besonderes Faible für mittelalterliche und modernen Wissenschaften, das er im Museo Galileo besonders gern vermittelt.',
    guide_description_4: 'Ich bin in Beirut geboren und dort aufgewachsen. Nach meinem Architekturstudium in den USA, habe ich eine Reise nach Florenz unternommen und mich in die Stadt verliebt. Also beschloss ich, hier zu bleiben. Und so lebe ich nun in Florenz seit über 30 Jahren! Und schließlich habe ich beschlossen, Stadtführerin zu werden. Ich spreche italienisch, arabisch und englisch. Italien ist wirklich das “bella Italia”, das Land der Schönheit und der Kreativität par excellence. Man kann es mit keinem anderen Land der Erde vergleichen. Trotz seiner Probleme und Schwierigkeiten gelingt es Italien, sein reiches kulturelles und künstlerisches Erbe zu erhalten. Aus dem mittleren Orient kommend, hat mich das immer ganz besonders beeindruckt. In meiner Heimat fehlt leider das diesbezügliche Interesse und die Fürsorge; dort sind Großteile seiner tausendjähringen Geschichte und Kultur vollkommen zerstört oder vergessen.',
}

export const guidesFr = {
    title: 'NOS GUIDES',
    description: 'Notre association AGMF est née après plusieurs années de connaissance et d’amitié entre quatre guides de langue maternelle différente.  Nous sommes tous des guides et accompagnateurs touristiques autorisés, spécialisés dans la présentation de sites d’intérêt culturel et artistique à Florence et dans ses alentours, chacun dans sa propre langue maternelle.  <br/><br/> Chacun de nous habite Florence depuis longtemps.  Chacun est florentin d’adoption et connaît et aime cette ville au moins autant qu’un Florentin de naissance (parfois même plus). ',
    guide_description_1: 'Je m’appelle Angela Oberer.  Après des études à Bonn, la précédente capitale de l’Allemagne, je suis venue à Florence pour  mon doctorat en histoire de l’art.  Quand je me suis rendu compte de la quantité d’universités américaines présentes à Florence, mon rêve s’est révélé:  enseigner entourée de la plus haute concentration d’œuvres artistiques au monde, comme c’est le casici à Florence.  J’ai donc postulé auprès de plusieurs universités où j’enseigne depuis maintenant vingt ans.  J’ai commencé à faire le guide à l’époque où j’étudiais.  Et encore aujourd’hui présenter Florence, ses places, ses églises et musées me remplit d’enthousiasme.  J’aime énormément raconter des histoires et des détails moins connus ou inattendus.   Comment ?  Venez voir pour vous-mêmes.  Vous me trouverez facilement, il suffit de chercher la couleur orange…',
    guide_description_2: 'Je m’appelle Carmen Higuera, je suis espagnole de Saragosse et j’habite Florence depuis l’âge de 23 ans.  J’y suis venue pour étudier l’art et la restauration.  J’ai travaillé come restauratrice, participé au marché d’antiquités et aux foires de l’artisanat.  En 2009 j’ai obtenu mon habilitation comme guide touristique à Florence.  Depuis lors, je travaille principalement en espagnol, ma langue natale, et cela me fait sentir come une éternelle touriste.  Je suis une personne curieuse, j’aime les changements, je m’adapte bien et vite aux nouveautés.  J’estime avoir de la chance d’habiter Florence, une des villes plus belles du monde avec une histoire fascinante, et des environs avec une campagne merveilleuse.  Personnellement j’ai besoin de me sentir entourée de beauté, et à Florence cela n’est pas difficile. Grâce à mon travail de guide, j’ai découvert le plaisir de transmettre et partager mes connaissances et mes émotions aux autres.  J’espère bientôt pouvoir le faire avec vous aussi!',
    guide_description_3: 'Après avoir enseigné l’anglais comme deuxième langue, et par la suite, la philosophie pour des programmes universitaires américains, Martino a opté pour la profession de guide touristique à Florence sur le tard. Une profession qu’il exerce presque exclusivement en anglais, de même que pour les cours d’histoire et de culture italienne, d’éthique et de logique qu’il offre dans différents programmes universitaires américains à Florence et (récemment) par internet aux États Unis.  Outre le vif intérêt qu’il nourrit pour l’art et  la culture italienne du Moyen Âge à nos jours, Martino se passionne aussi pour l’histoire de la science, depuis la Grèce ancienne et le moyen âge jusques à l’époque moderne, qu’il aime divulguer au musée Galilée.',
    guide_description_4: 'Je suis née à Beyrouth où j’ai grandi.  J’ai obtenu mon diplôme en architecture aux États-Unis.  Après une première visite à Florence j’en suis tombé amoureuse et j’ai décider d’y rester, et me voilà encore à Florence trente ans plus tard!  Après un parcours professionnel varié, j’ai décidé de devenir guide et accompagnatrice touristique.  Je parle couramment l’italien, l’arabe et l’anglais.  L’Italie est vraiment il Bel Paese (le beau pays)!  C’est le pays de la beauté et de la créativité par excellence, sans comparaison avec d’autres pays du monde, et Florence en possède une grosse tranche.  L’Italie, en dépit de ses problèmes et de ses difficultés, réussit néanmoins à conserver son riche patrimoine artistique et culturel.  Je trouve ce fait particulièrement admirable, provenant moi-même du Moyen Orient où, malheureusement, la négligence et le manque d’intérêt ont amené au résultat que des milliers d’années d’histoire et de culture sont en grande partie détruits ou oubliés.  Visiter Florence est une façon de voyager dans le temps, et ce serait pour moi un grand plaisir que de vous y accompagner pour vous raconter son histoire et partager avec vous mon amour pour ses beautés.',
}