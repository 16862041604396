import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import { navbarEn, navbarIt, navbarEs, navbarDe, navbarFr } from '../content/navbar/navbar'
import { buttonsEn, buttonsIt, buttonsEs, buttonsDe, buttonsFr } from '../content/various/buttons'

import logo from '../assets/carmen_01.jpg'

import arrow from '../assets/arrow.svg'
import hamburger from '../assets/hamburger.png'
import x from '../assets/x.png'

const StyledNavBar = styled.header`
    height: 70px;
    background-color: #fffdf7;
    border-bottom: 1px solid #846A6A70;
    .container{
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1400px;
        width: 90%;
        height: 70px;
        .logo{
            display: flex;
            align-items: end;
            img{
                height: 68px;
                margin-right: 3px
            }
            div{
                font-size: 1.4rem;
                p{
                    color: red;
                }
            }
        }
        .right{
            position: relative;
            display: flex;
            justify-content: end;
            align-items: center;
            width:80%;
            .languages{
                position: relative;
                .select{
                    border: 1px solid;
                    border-radius: 10px;
                    background-color: #ececec;
                    padding: 2px 8px;
                    cursor: pointer;
                    p{
                        display: inline-block;
                        font-size: 1.4rem;
                        font-weight: bold;
                    }
                    img{
                        height: 25px;
                        padding-top:2px
                    }
                    .arrow{
                        height: 13px;
                        padding-left: 5px;
                    }
                }
                .options{
                    position: absolute;
                    top: 23px;
                    left: -25px;
                    overflow: hidden;
                    visibility: ${props => props.langShown ? 'visible' : 'hidden'};
                    opacity: ${props => props.langShown ? '1' : '0'};
                    transition: padding-top .2s, visibility .2s, opacity .2s;
                    width: 130px;
                    border: 1px solid;
                    background-color: #ececec;
                    border-radius: 10px;
                    a{
                        display: block;
                        font-size: 1.3rem;
                        padding: 5px 5px;
                        border-bottom: 1px solid #846A6A70;
                    }
                }
            }
            .hamburger{
                display: none;
                height: 30px;
                margin-left: 30px;
                @media screen and (max-width: 900px) {
                    display: ${props => props.isShown ? 'none' : 'block'};
                }
            }
            .x{
                display: none;
                height: 30px;
                margin-left: 30px;
                @media screen and (max-width: 900px) {
                    display: ${props => props.isShown ? 'block' : 'none'};
                }
            }
            .links{
                position: relative;
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 70%;
                font-size: 1.4rem;
                @media screen and (max-width: 900px) {
                    display: none;
                }
                a{
                    img{
                        height: 10px;
                    }
                }
                .contacts{
                    font-size:1.8rem;
                    color: #FF9505;
                }
                .services{
                    position: absolute;
                    top: 20px;
                    left: 14%;
                    overflow: hidden;
                    visibility: ${props => props.servShown ? 'visible' : 'hidden'};
                    opacity: ${props => props.servShown ? '1' : '0'};
                    transition: padding-top .2s, visibility .2s, opacity .2s;
                    background-color: #fffdf7;
                    border: 1px solid #846A6A70;
                    border-radius: 4px;
                    a{
                        display: block;
                        padding: 10px;
                    }
                }
            }
        }
    }
    .mobile-menu{
        display: none;
        position: absolute;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        top: 70px;
        height: 220px;
        background-color: #fffdf7;
        padding-top: 10px;
        border-bottom: 1px solid;
        @media screen and (max-width: 900px) {
            display: flex;
            overflow: hidden;
            visibility: ${props => props.isShown ? 'visible' : 'hidden'};
            opacity: ${props => props.isShown ? '1' : '0'};
            transition: padding-top .2s, visibility .2s, opacity .2s;
        }
        a{
            padding-left: 5%;
            padding-bottom: 10px;
            font-size: 1.6rem;
            border-bottom: 1px solid #846A6A70;
        }
        .contacts{
            font-size: 1.8rem;
            color: #FF9505;
            border-bottom: none;
        }
    }
`

const NavBar = props => {
    const { language, setLanguage } = props;
    const [isShown, setIsShown] = useState(false)
    const [langShown, setLangShown] = useState(false)
    const [servShown, setServShown] = useState(false)
    const [navbar, setNavBar] = useState({})
    const [buttons, setButtons] = useState({})

    useEffect(() => {
        switch (language) {
            case 'it':
                setButtons(buttonsIt)
                setNavBar(navbarIt)
                break
            case 'es':
                setButtons(buttonsEs)
                setNavBar(navbarEs)
                break
            case 'de':
                setButtons(buttonsDe)
                setNavBar(navbarDe)
                break
            case 'fr':
                setButtons(buttonsFr)
                setNavBar(navbarFr)
                break
            default:
                setButtons(buttonsEn)
                setNavBar(navbarEn)
                break
        }
    }, [language])
    
    const changeLang = lang => {
        setLangShown(false)
        setLanguage(lang)
    }

    return (
        <StyledNavBar isShown={isShown} langShown={langShown} servShown={servShown}>
            <div className='container'>
                <Link className='logo' to={`/${language}`}>
                    <img src={logo} alt="" />
                    <div>
                        <p><span>A</span>SSOCIAZIONE</p>
                        <p><span>G</span>UIDE</p>
                        <p><span>M</span>ADRELINGA</p>
                        <p><span>F</span>IRENZE</p>
                    </div>
                </Link>
                <div className='right'>
                    <div className='languages'>
                        <div onClick={() => setLangShown(!langShown)} onMouseEnter={() => setLangShown(true)} onMouseLeave={() => setLangShown(false)} className='select'>
                            <p>{language.toUpperCase()}</p>
                            <img className='arrow' src={arrow} alt="" />
                        </div>
                        <div onMouseEnter={() => setLangShown(true)} onMouseLeave={() => setLangShown(false)} className='options'>
                            <Link onClick={() => changeLang('en')} to={`/en${window.location.pathname.slice(3)}`} ><span>EN</span> | English</Link>
                            <Link onClick={() => changeLang('it')} to={`/it${window.location.pathname.slice(3)}`} ><span>IT</span> | Italiano</Link>
                            <Link onClick={() => changeLang('fr')} to={`/fr${window.location.pathname.slice(3)}`} ><span>FR</span> | Français</Link>
                            <Link onClick={() => changeLang('de')} to={`/de${window.location.pathname.slice(3)}`} ><span>DE</span> | Deutsch</Link>
                            <Link onClick={() => changeLang('es')} to={`/es${window.location.pathname.slice(3)}`} ><span>ES</span> | Español</Link>
                            <Link onClick={() => changeLang('ar')} to={`/ar${window.location.pathname.slice(3)}`} ><span>AR</span> | اللغة العربية</Link>
                        </div>
                    </div>
                    <img className='hamburger' onClick={() => setIsShown(true)} src={hamburger} alt="" />
                    <img className='x' onClick={() => setIsShown(false)} src={x} alt="" />
                    <div className='links'>
                        <Link to={`/${language}/`}>HOME</Link>
                        <Link to={`/${language}/services`} onMouseEnter={() => setServShown(true)} onMouseLeave={() => setServShown(false)}>{navbar.services} <img src={arrow} alt="" /></Link>
                        <Link to={`/${language}/tours`}>{navbar.tours}</Link>
                        <Link to={`/${language}/guides`}>{navbar.guides}</Link>
                        <Link to={`/${language}/contacts`} className='contacts' >{buttons.book_now}</Link>
                        <div onMouseEnter={() => setServShown(true)} onMouseLeave={() => setServShown(false)} className='services'>
                            <Link to={`/${language}/services`}>{navbar.all_services}</Link>
                            <HashLink smooth to={`/${language}/services#schools`}>{navbar.for_schools}</HashLink>
                            <HashLink smooth to={`/${language}/services#privates`}>{navbar.for_privates}</HashLink>
                        </div>
                        <div className='tours'></div>
                    </div>
                </div>
            </div>
            <div className='mobile-menu'>
                <Link onClick={() => setIsShown(!isShown)} to={`/${language}/`}>HOME</Link>
                <Link onClick={() => setIsShown(!isShown)} to={`/${language}/services`}>{navbar.services}</Link>
                <Link onClick={() => setIsShown(!isShown)} to={`/${language}/tours`}>{navbar.tours}</Link>
                <Link onClick={() => setIsShown(!isShown)} to={`/${language}/guides`}>{navbar.guides}</Link>
                <Link onClick={() => setIsShown(!isShown)} to={`/${language}/contacts`} className='contacts'>{buttons.book_now}</Link>
            </div>
        </StyledNavBar>
    )
}

export default NavBar;